<template>
	<footer>
		<PFsFooter />
		<AusCare />
	</footer>
</template>

<script setup lang="ts">
import PFsFooter from "./components/PFsFooter.vue";
import AusCare from "./components/AusCare.vue";

const websiteStore = useWebsiteStore();
const deviceStore = useDeviceStore();

useTrustBadge(websiteStore.website, deviceStore.screenWidth < 1024);
</script>
