import type { CountryPaymentProps, CertificationProps } from "./types/interfaceCertifications";

export const certifications: CertificationProps = {
	US: [
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
		//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
		//     label: "Norton",
		// },
		{
			id: "DigiCertClickID_OsYoXDe1"
		},
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		},
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/BBBorg.png",
			toUrl: "https://www.bbb.org/us/de/new-castle/profile/fiber-optics/fscom-0251-92010981",
			label: "Better Business Bureau"
		},
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png",
			toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19",
			label: "Google Customer Reviews"
		}
	], // 美东仓
	DE: {
		uk: [
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
				toUrl:
					"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
				label: "TrustedSite"
			},
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/ssl.svg",
				toUrl: "javascript:;",
				label: "SSL"
			},
			// {
			//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
			//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
			// },
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google.png",
				toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=DE&v=19",
				label: "Google Customer Reviews"
			},
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/iaf.svg",
				toUrl: "javascript:;",
				label: "IAF"
			},
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/ukas.svg",
				toUrl: "javascript:;",
				label: "UKAS"
			}
		],
		"de-en": [
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
				toUrl:
					"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
				label: "TrustedSite"
			},
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/ssl.svg",
				toUrl: "javascript:;",
				label: "SSL"
			},
			// {
			//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
			//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
			// },
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google.png",
				toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=DE&v=19",
				label: "Google Customer Reviews"
			}
		],
		de: [
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
				toUrl:
					"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
				label: "TrustedSite"
			},
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/ssl.svg",
				toUrl: "javascript:;",
				label: "SSL"
			},
			// {
			//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
			//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
			// },
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google.png",
				toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=DE&v=19",
				label: "Google Customer Reviews"
			}
		],
		other: [
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
				toUrl:
					"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
				label: "TrustedSite"
			},
			{
				id: "DigiCertClickID_OsYoXDe1"
			},

			// {
			//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
			//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
			//     label: "Norton",
			// },
			// {
			//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
			//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
			// },
			{
				imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google.png",
				toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=DE&v=19",
				label: "Google Customer Reviews"
			}
		]
	}, // 德国仓
	AU: [
		{
			id: "DigiCertClickID_OsYoXDe1"
		},
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
		//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
		//     label: "Norton",
		// },
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		}
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
		//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
		// },
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png',
		//     toUrl: 'https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19'
		// },
	], // 澳洲仓
	SG: [
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		},
		{
			id: "DigiCertClickID_OsYoXDe1"
		}
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
		//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
		//     label: "Norton",
		// },
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
		//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
		// },
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png',
		//     toUrl: 'https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19'
		// },
	], // 新加坡仓
	RU: [
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		},
		{
			id: "DigiCertClickID_OsYoXDe1"
		},
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
		//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
		//     label: "Norton",
		// },
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
		//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
		// },
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png",
			toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19",
			label: "Google Customer Reviews"
		}
	], // 俄罗斯仓
	CN: [
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		},
		{
			id: "DigiCertClickID_OsYoXDe1"
		},
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/norton-poweredby.png",
		//     toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
		//     label: "Norton",
		// },
		// {
		//     imgUrl: 'https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Trustwave-pc.png',
		//     toUrl: 'https://sealserver.trustwave.com/cert.php?customerId=520012bf276f4118a53a48f3e6627842&size=105x54&style='
		// },
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png",
			toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19",
			label: "Google Customer Reviews"
		}
	], // 深圳仓
	UK: [
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/ssl.png",
			// toUrl: "https://seal.digicert.com/seals/popup/?tag=vyT8OdM_&url=www.fs.com&lang=en&cbr=1607596402526",
			label: "SSL"
		},
		{
			imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg",
			toUrl:
				"https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com",
			label: "TrustedSite"
		},
		// {
		//     imgUrl: "https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/Google-de-pc.png",
		//     toUrl: "https://customerreviews.google.com/v/merchant?q=fs.com&c=US&v=19",
		//     label: "Google Customer Reviews",
		// },
		{
			imgUrl: "https://resource.fs.com/mall/generalImg/20230830172053tddbrv.png",
			toUrl: "https://www.trustpilot.com/review/fs.com",
			label: "Trustpilot"
		}
	] // 英国仓
};

export const countryPayment: CountryPaymentProps = {
	uk: ["PayPal", "VISA", "American Express", "Maestro", "Mastercard", "Bank Transfer"],
	de: ["PayPal", "VISA", "American Express", "Vorkasse", "Mastercard", "SOFORT", "Giropay", "Kauf auf Rechnung"],
	"de-en": ["PayPal", "VISA", "American Express", "Mastercard", "Maestro", "Bank Transfer", "SOFORT", "iDEAL"],
	it: ["PayPal", "VISA", "American Express", "Mastercard", "Maestro", "Bank Transfer", "SOFORT"],
	es: ["PayPal", "VISA", "Mastercard", "Bank Transfer"],
	au: ["PayPal", "VISA", "Mastercard", "American Express", "Discover", "Direct Deposit", "Dinersclub"],
	mx: ["PayPal", "VISA", "Mastercard", "American Express", "Wire Transfer"],
	sg: ["VISA", "Mastercard", "American Express", "Bank Transfer", "JCB"],
	fr: ["VISA", "PayPal", "Mastercard", "American Express", "Discover", "Bank Transfer", "Mandat Administratif"]
};

export const paymentIcon = {
	"American Express": {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018s6g5iq.svg",
		href: "javascript:;"
	},
	PayPal: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018e5uijj.svg",
		href: "javascript:;"
	},
	VISA: {
		imgur: "https://resource.fs.com/mall/generalImg/202312111440186697dj.svg",
		href: "javascript:;"
	},
	Mastercard: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018v0ttyh.svg",
		href: "javascript:;"
	},
	Maestro: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018d6petg.svg",
		href: "javascript:;"
	},
	JCB: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018fs2xr6.svg",
		href: "javascript:;"
	},
	iDEAL: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018x84sh5.svg",
		href: "javascript:;"
	},
	Giropay: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018fjlwpv.svg",
		href: "javascript:;"
	},
	SOFORT: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018z5zo61.svg",
		href: "javascript:;"
	},
	Discover: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018oyng3a.svg",
		href: "javascript:;"
	},
	Dinersclub: {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018ogu64m.svg",
		href: "javascript:;"
	},
	Vorkasse: {
		imgur: "https://resource.fs.com/mall/generalImg/2023121114401828x2tk.svg",
		href: "javascript:;"
	},
	"Kauf auf Rechnung": {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018k9llut.svg",
		href: "javascript:;"
	},
	"Bank Transfer": {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018nxok9s.svg",
		href: "javascript:;"
	},
	"Direct Deposit": {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018dqtro5.svg",
		href: "javascript:;"
	},
	"Wire Transfer": {
		imgur: "https://resource.fs.com/mall/generalImg/20231211144018hc61og.svg",
		href: "javascript:;"
	},
	"Mandat Administratif": {
		imgur: "https://resource.fs.com/mall/generalImg/202312111440186fxyrk.svg",
		href: "javascript:;"
	}
};
