<template>
	<FsTooltip
		ref="cartTipRef"
		placement="bottom-end"
		trigger="hover"
		:offsetY="14"
		:popperContentStyle="{
			width: '300px',
			padding: '0',
			borderRadius: '4px'
		}"
		@visible-change="visibleChange">
		<a
			class="cart_box"
			:class="{ cart_box_hover: cartTipRef?.visible }"
			:href="localeLink('/shopping_cart.html')"
			tabindex="0">
			<div class="icon_box">
				<span class="iconfont iconfont_cart">&#xe693;</span>
				<span v-if="count" class="cart-num" :class="{ more: count > 99 }">{{ count > 99 ? "99+" : count }}</span>
			</div>

			<!-- <p v-if="website !== 'cn'" class="icon_info">
				{{ localeLang("header.cart.Cart") }}
			</p> -->
		</a>
		<template #content>
			<CartList />
		</template>
	</FsTooltip>
</template>
<script setup lang="ts">
import { FsTooltip } from "fs-design";

import CartList from "./CartList.vue";

const websiteStore = useWebsiteStore();
const cartStore = useCartStore();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const cartTipRef = ref(null);
const { website } = storeToRefs(websiteStore);
const { count, cartRequest, cartFetching } = storeToRefs(cartStore);
const headerStore = useHeaderStore();
const { pc_header_height, header_search_show, m_header_height, hotSearchPage, hotChangePending, hotSearchList } =
	storeToRefs(headerStore);

const visibleChange = (flag: boolean) => {
	if (flag) {
		console.log("11_11");
		console.log("visible:" + flag);
		console.log(cartFetching.value);
		console.log(cartRequest.value);

		console.log("count:" + count.value);

		console.log(cartFetching.value || cartRequest.value || !count.value);
		if (cartFetching.value || cartRequest.value || !count.value) {
			return;
		}
		console.log("count");
		cartStore.getCart();
	}
};
onMounted(() => {
	// setTimeout(()=>{
	// 	useRequest.post("/api/cart/index",{
	// 		data:{
	// 			isOnlyFetchCount:0
	// 		}
	// 	})
	// 	useRequest.post("/api/cart/index",{data:{
	// 		isOnlyFetchCount:1
	// 	}})
	// },0)
});
</script>
<style lang="scss" scoped>
.cart_box {
	// min-width: 53px;
	// cursor: pointer;
	// padding: 0 8px;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	// height: 84px;
	position: relative;
	color: $textColor1;

	&:hover {
		background: $bgColor1;
		text-decoration: none;
	}

	&.cart_box_hover {
		background: $bgColor2;
	}

	.icon_box {
		position: relative;
	}

	.cart-num {
		line-height: 16px;
		color: #fff;
		text-align: center;
		font-size: 12px;
		border-radius: 18px;
		position: absolute;
		top: -9px;
		right: -8px;
		padding: 1px 2px;
		background: $bgColor4;
		min-width: 18px;
		font-weight: 600;

		&.more {
			right: -15px;
		}
	}

	.iconfont_cart {
		font-size: 20px;
		display: block;
		width: 20px;
		height: 20px;
		line-height: 20px;
		color: $textColor1;
	}

	.icon_info {
		white-space: nowrap;
		font-size: 12px;
		padding-top: 2px;
	}
}
</style>
