<template>
	<div
		class="footer_country_box"
		:class="{
			new_country_box: website !== 'uk' && (iso_code === 'FR' || countryPayment[website]),
			myCustomTrustbadge_other: ['de', 'de-en', 'fr', 'es', 'mx', 'it'].includes(website)
		}">
		<div v-if="!(iso_code === 'FR' || countryPayment[website] || ['cn'].includes(website))" class="certification-box">
			<!-- <a
				v-for="img in certifications[warehouse]"
				:key="img.imgUrl"
				tabindex="0"
				:href="localeLink(img.toUrl)"
				:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
				:aria-label="img.label"
				:style="img.toUrl == 'javascript:;' ? 'cursor:auto;' : 'cursor:pointer'">
				<img :src="img.imgUrl" :alt="img.label" :aria-label="img.label" />
			</a> -->

			<CertificationCtn :data="certifications[warehouse]" />
		</div>
		<div v-if="website === 'uk'" class="certification-box">
			<!-- <a
				v-for="img in certifications[warehouse]"
				:key="img.imgUrl"
				tabindex="0"
				:href="localeLink(img.toUrl)"
				:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
				:aria-label="img.label"
				:style="img.toUrl == 'javascript:;' ? 'cursor:auto;' : 'cursor:pointer'">
				<img :src="img.imgUrl" :alt="img.label" :aria-label="img.label" />
			</a> -->

			<CertificationCtn :data="certifications[warehouse]" />
		</div>
		<div v-if="['cn'].includes(website)" class="certification-cn-box">
			<a
				href="https://ss.knet.cn/verifyseal.dll?sn=e15101544030060902l1bf000000&ct=df&a=1&pa=0.05508846207521856"
				target="_blank"></a>
			<a href="https://credit.szfw.org/CX20150924011838250320.html" target="_blank"></a>
			<!-- 去掉实名认证 -->
			<!-- <a href="https://v.yunaq.com/certificate?domain=www.fs.com&from=label&code=90020" target="_blank"></a> -->
			<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502007285" target="_blank">
				<img src="https://img-en.fs.com/zh/includes/templates/fiberstore/images/beian_icon.png" alt="" />
				<p>粤公网安备 44030502007285号</p>
			</a>
		</div>
		<div ref="rightCountryBox" class="country_box_right">
			<FsTooltip ref="changeWebsiteTipRef" placement="top" trigger="hover">
				<div class="right_ctn">
					<!-- <span class="iconfont iconfont_global">&#xe674;</span> -->
					<span class="current_country_info">{{
						`${country_name} / ${symbol}${["USD"].includes(currency) ? "" : " "}${currency}`
					}}</span>
					<span class="iconfont iconfont_down" :class="{ iconfont_down_up: changeWebsiteTipRef?.visible }"
						>&#xf049;</span
					>
				</div>
				<template #content>
					<ChangeWebsite
						:search="search"
						@saveSearchValue="saveSearchValue"
						@hideChangeWebsiteTip="hideChangeWebsiteTip" />
				</template>
			</FsTooltip>
			<!-- <span class="line"></span>
			<div v-if="!isCn" class="right_ctn">
				<span class="iconfont iconfont_mobile">&#xe62b;</span>

				<a class="current_country_info" tabindex="0" :href="localeLink('/appdownload.html')">{{
					localeLang("footer.app")
				}}</a>
			</div>

			<FsTooltip v-else ref="appTipRef" placement="top" trigger="hover">
				<div class="right_ctn">
					<span class="iconfont iconfont-mobile">&#xe62b;</span>
					<span class="current_country_info">{{ localeLang("footer.app") }}</span>
				</div>
				<template #content>
					<img
						class="app_img"
						:style="{ display: 'block', width: '100px' }"
						src="https://resource.fs.com/mall/generalImg/20231010113642tc4vcy.png"
						alt="" />
				</template>
			</FsTooltip> -->
		</div>
	</div>
</template>

<script setup lang="ts">
import { FsTooltip } from "fs-design";

import CertificationCtn from "./CertificationCtn.vue";
import { certifications, countryPayment } from "@/constants/certifications";
import ChangeWebsite from "@/component/FsHeader/components/ChangeWebsite.vue";
const websiteStore = useWebsiteStore();
const changeWebsiteTipRef = ref();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();

const { website, country_name, iso_code, language, currency, symbol, warehouse, isCn } = storeToRefs(websiteStore);

const search = ref("");
function saveSearchValue(val: string) {
	console.log("val", val);
	search.value = val;
}

function hideChangeWebsiteTip() {
	nextTick(() => {
		console.log("hidedddddd");
		console.log(changeWebsiteTipRef);
		changeWebsiteTipRef.value.visible = false;
	});
}
</script>

<style lang="scss" scoped>
.footer_country_box {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $borderColor1;
	padding-bottom: 20px;
	margin-top: 64px;
	&.myCustomTrustbadge_other {
		margin-top: -18px;
	}
	align-items: center;

	&.new_country_box {
		justify-content: flex-end;
	}

	.certification-box {
		height: 28px;
		display: flex;
		&.certification-box-uk {
			height: auto;
		}
		.uk_local_txt {
			@include font12;
			color: $textColor3;
		}
		a {
			margin-right: 16px;

			&:last-of-type {
				margin-right: 0;
			}

			img {
				width: auto;
				max-height: 28px;
			}
		}
	}

	.certification-cn-box {
		display: flex;
		a {
			margin-right: 20px;
			width: 80px;
			height: 40px;
			background: url("https://img-en.fs.com/zh/includes/templates/fiberstore/images/footer/customer_service_icon.png")
				no-repeat;
			text-decoration: none;
			&:nth-of-type(1) {
				background-position: -61px -28px;
			}
			&:nth-of-type(2) {
				background-position: -61px -126px;
			}
			&:nth-of-type(3) {
				background-position: -61px -222px;
			}
			&:last-child {
				width: auto;
				display: flex;
				align-items: center;
				background: none;
				img {
					display: block;
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
				p {
					@include font12;
					color: $textColor3;
				}
			}
		}
	}

	.country_box_right {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		position: relative;
		.right_ctn {
			cursor: pointer;
			display: flex;
			align-items: center;
			color: $textColor2;

			.iconfont_global,
			.iconfont_mobile,
			.iconfont_service {
				font-size: 18px;
			}

			.current_country_info {
				font-size: 12px;
				margin: 0 4px;
				white-space: nowrap;
				color: $textColor2;
			}

			.iconfont_down {
				font-size: 12px;
				&.iconfont_down_up {
					transform: rotateX(-180deg);
				}
			}

			&:hover {
				color: $textColor1;
				transition: all 0.3s;
				.current_country_info {
					color: $textColor1;
				}
				.iconfont_global,
				.iconfont_mobile,
				.iconfont_service {
					color: $textColor2;
				}

				.iconfont_down {
					transform: rotateX(-180deg);
				}
			}
		}
	}

	.line {
		display: inline-block;
		width: 1px;
		height: 8px;
		background: $bgColor7;
		margin: 0 20px;
	}
}
</style>
