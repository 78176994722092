<template>
	<ClientOnly>
		<div
			class="m_side_bar_wrap"
			:class="{
				m_side_bar_wrap_show: m_menu_expand,
				no_login: !isLogin
			}">
			<div class="top">
				<a class="logo_area" :href="localeLink('/')" aria-label="FS">
					<div :class="logoClassName"></div>
				</a>
				<div class="close" @click="maskClick">
					<i class="iconfont icona-delete-Mdaohang1" />
				</div>
			</div>
			<div class="list">
				<section v-if="category.data && category.data.length" class="section_box">
					<template v-for="(item, index) in category.data" :key="item.name">
						<a
							href="javascript:;"
							class="section_item"
							:class="{
								section_item_active: menuActive.categoryFirst === index + 1
							}"
							@click.stop="categoryFirstClick(index)">
							<p class="section_name" v-html="item.name || ''"></p>
							<span class="iconfont iconfont_down">&#xf049;</span>
						</a>
						<div
							v-if="item.children && item.children.length"
							class="section_ctn"
							:class="{
								section_ctn_active: menuActive.categoryFirst === index + 1
							}">
							<!-- <a class="view_all_btn" :href="localeLink(item.url)">{{ localeLang("header.View_all") }}</a> -->
							<template v-for="(sitem, sindex) in item.children" :key="sitem.name">
								<a
									href="javascript:;"
									class="section_item"
									:class="{
										section_item_active: menuActive.categorySecond === sindex + 1
									}"
									@click.stop="categorySecondClick(sindex)">
									<p class="section_name" v-html="sitem.name || ''"></p>
									<span class="iconfont iconfont_down">&#xf049;</span>
								</a>
								<div
									v-if="sitem.children && sitem.children.length"
									class="section_ctn"
									:class="{
										section_ctn_active: menuActive.categorySecond === sindex + 1
									}">
									<!-- <a class="view_all_btn" :href="localeLink(sitem.url)">{{ localeLang("header.View_all") }}</a> -->
									<template v-for="titem in sitem.children" :key="titem.name">
										<a :href="titem.url" class="section_item">
											<p class="section_name" v-html="titem.name || ''"></p>
										</a>
									</template>
								</div>
							</template>
						</div>
					</template>
				</section>
				<div class="section_line"></div>
				<section v-if="combiClassify && combiClassify.length" class="section_box">
					<template v-for="(item, index) in combiClassify" :key="index">
						<template v-if="index === 0">
							<a
								href="javascript:;"
								class="section_item"
								:class="{
									section_item_active: menuActive.classifyFirst === index + 1
								}"
								@click.stop="classifyFirstClick(index)">
								<p class="section_name" v-html="item.title || ''"></p>
								<span class="iconfont iconfont_down">&#xf049;</span>
							</a>
							<div
								v-if="item[index ? 'children' : 'data'] && item[index ? 'children' : 'data'].length"
								class="section_ctn"
								:class="{
									section_ctn_active: menuActive.classifyFirst === index + 1
								}">
								<template v-for="(sitem, sindex) in item[index ? 'children' : 'data']" :key="sindex">
									<a
										href="javascript:;"
										class="section_item"
										:class="{
											section_item_active: menuActive.classifySecond === sindex + 1
										}"
										@click.stop="classifySecondClick(sindex)">
										<p class="section_name" v-html="sitem.title || ''"></p>
										<span class="iconfont iconfont_down">&#xf049;</span>
									</a>
									<div
										v-if="sitem[index ? 'children' : 'list'] && sitem[index ? 'children' : 'list'].length"
										class="section_ctn"
										:class="{
											section_ctn_active: menuActive.classifySecond === sindex + 1
										}">
										<template v-for="(titem, tindex) in sitem[index ? 'children' : 'list']" :key="tindex">
											<a
												:href="titem.url"
												class="section_item"
												:class="{
													section_item_active: menuActive.classifyThird === tindex + 1
												}"
												@click.stop="classifyThirdClick(index, titem, tindex)">
												<p class="section_name" v-html="titem.title || ''"></p>
											</a>
										</template>
									</div>
								</template>
							</div>
						</template>
						<template v-else>
							<a
								href="javascript:;"
								class="section_item"
								:class="{
									section_item_active: menuActive.classifyFirst === index + 1
								}"
								@click.stop="classifyFirstClick(index)">
								<p class="section_name" v-html="item?.nav || '22222'"></p>
								<span class="iconfont iconfont_down">&#xf049;</span>
							</a>
							<div
								v-if="item?.data?.length"
								class="section_ctn"
								:class="{
									section_ctn_active: menuActive.classifyFirst === index + 1
								}">
								<template v-for="(sitem, sindex) in item?.data" :key="sindex">
									<a
										href="javascript:;"
										class="section_item"
										:class="{
											section_item_active: menuActive.classifySecond === sindex + 1,
											cn_hide: index === 3 && sindex === 2 && isCn
										}"
										@click.stop="classifySecondClick(sindex)">
										<p class="section_name" v-html="sitem.title || ''"></p>
										<span class="iconfont iconfont_down">&#xf049;</span>
									</a>
									<div
										v-if="sitem?.list?.length"
										class="section_ctn"
										:class="{
											section_ctn_active: menuActive.classifySecond === sindex + 1
										}">
										<template v-for="(titem, tindex) in sitem.list" :key="tindex">
											<template v-if="index === 3 && sindex === 1">
												<a
													v-if="tindex === 0"
													:href="'mailto:' + titem.title"
													class="section_item xcon"
													:class="{
														section_item_active: menuActive.classifyThird === tindex + 1
													}"
													@click.stop="classifyThirdClick(index, titem, tindex)">
													<p class="section_name" v-html="titem.title || ''"></p>
												</a>
												<a
													v-else-if="tindex === 1"
													:href="'tel:' + '' + titem.title"
													class="section_item xcon"
													:class="{
														section_item_active: menuActive.classifyThird === tindex + 1
													}"
													@click.stop="classifyThirdClick(index, titem, tindex)">
													<p class="section_name" v-html="titem.title || ''"></p>
												</a>
												<a
													v-else
													:href="titem.url"
													class="section_item xcon"
													:class="{
														section_item_active: menuActive.classifyThird === tindex + 1
													}"
													@click.stop="classifyThirdClick(index, titem, tindex)">
													<p class="section_name" v-html="titem.title || ''"></p>
												</a>
											</template>
											<template v-else>
												<a
													:href="titem.url"
													class="section_item xcon"
													:class="{
														section_item_active: menuActive.classifyThird === tindex + 1
													}"
													@click.stop="classifyThirdClick(index, titem, tindex)">
													<p class="section_name" v-html="titem.title || ''"></p>
												</a>
											</template>
										</template>
									</div>
									<div
										v-else-if="index === 3 && sindex === 2"
										class="section_ctn"
										:class="{
											section_ctn_active: menuActive.classifySecond === sindex + 1,
											cn_hide: isCn
										}">
										<div
											class="section_item"
											:class="{
												section_item_active: menuActive.classifyThird === 1
											}">
											<div class="share_box">
												<a
													v-for="i in socialLinkNav"
													:key="i.title"
													class="iconfont iconfont-share x3"
													target="_blank"
													tabindex="0"
													:href="localeLink(i.link[website] || i.link.en)"
													:title="i.title"
													:aria-label="i.title"
													v-html="i.icon"></a>
											</div>
										</div>
									</div>
								</template>
							</div>
						</template>
					</template>
				</section>
			</div>
			<div v-if="!isLogin" class="bottom">
				<FsButton type="black" tabindex="0" :round="true" @click.stop="btnClick('login')">
					{{ localeLang("header.account.sign") }}
				</FsButton>
				<FsButton type="gray" tabindex="0" :round="true" @click.stop="btnClick('register')">{{
					localeLang("header.account.createAccount")
				}}</FsButton>
			</div>
			<!-- <div class="section_line"></div> -->
			<!-- <a :href="localeLink('/contact_us.html')" class="section_item">
				<span class="iconfont iconfont_contact">&#xe67e;</span>
				<p class="section_name">{{ localeLang("header.Contact_Us") }}</p>
			</a> -->
		</div>
	</ClientOnly>
</template>
<script setup lang="ts">
import { FsTooltip, FsButton, FsSelect, FsDialog, FsMessage } from "fs-design";
import { socialLinkNav } from "@/constants/socialLink";
import fixScroll from "@/utils/fixScroll";

const websiteStore = useWebsiteStore();
const { website, isCn } = storeToRefs(websiteStore);
const headerStore = useHeaderStore();
const userStore = useUserStore();
const route = useRoute();
const localeLang = useLocaleLang();

const { isLogin } = storeToRefs(userStore);

const { category, classify, m_menu_expand, m_header_height, classifyNew } = storeToRefs(headerStore);
const combiClassify = ref<any>(classify.value.slice(0, 1).concat(classifyNew.value as any));
const menuActive = ref({
	lastCategoryFirst: 0,
	categoryFirst: 0,
	categorySecond: 0,
	lastClassifyFirst: 0,
	classifyFirst: 0,
	classifySecond: 0,
	classifyThird: 0
});

const localeLink = useLocaleLink();

function categoryFirstClick(index) {
	if (menuActive.value.categoryFirst === index + 1) {
		menuActive.value.categoryFirst = 0;
	} else {
		if (menuActive.value.categoryFirst && menuActive.value.lastCategoryFirst !== index + 1) {
			menuActive.value.categorySecond = 0;
		}
		menuActive.value.categoryFirst = index + 1;
	}
	menuActive.value.lastCategoryFirst = index + 1;
}

function categorySecondClick(index) {
	if (menuActive.value.categorySecond === index + 1) {
		menuActive.value.categorySecond = 0;
	} else {
		menuActive.value.categorySecond = index + 1;
	}
}

function classifyFirstClick(index) {
	if (menuActive.value.classifyFirst === index + 1) {
		menuActive.value.classifyFirst = 0;
	} else {
		if (menuActive.value.classifyFirst && menuActive.value.lastClassifyFirst !== index + 1) {
			menuActive.value.classifySecond = 0;
			menuActive.value.classifyThird = 0;
		}
		menuActive.value.classifyFirst = index + 1;
	}
	menuActive.value.lastClassifyFirst = index + 1;
}

function classifySecondClick(index) {
	if (menuActive.value.classifySecond === index + 1) {
		menuActive.value.classifySecond = 0;
	} else {
		menuActive.value.classifySecond = index + 1;
	}
}

function classifyThirdClick(t, item, index) {
	if (t) {
		location.href = localeLink(item.url);
	} else {
		if (menuActive.value.classifyThird === index + 1) {
			menuActive.value.classifyThird = 0;
		} else {
			menuActive.value.classifyThird = index + 1;
		}
	}
}
function btnClick(str: string) {
	let s = "";
	if (str === "login") {
		s = `/login.html?resirect=${route.fullPath}`;
	} else if (str === "register") {
		s = `/regist.html`;
	}
	location.href = localeLink(s);
}
const logoClassName = computed(() => {
	let s = "logo";
	if (isCn.value) {
		s += " logo_cn";
	}
	return s;
});

const maskClick = () => {
	m_menu_expand.value = false;
	fixScroll.unfixed();
};
watch(
	m_menu_expand,
	newVal => {
		if (!newVal) {
			menuActive.value.lastCategoryFirst = 0;
			menuActive.value.categoryFirst = 0;
			menuActive.value.categorySecond = 0;
			menuActive.value.lastClassifyFirst = 0;
			menuActive.value.classifyFirst = 0;
			menuActive.value.classifySecond = 0;
			menuActive.value.classifyThird = 0;
		}
	},
	{
		immediate: true
	}
);

onMounted(() => {});
</script>
<style lang="scss" scoped>
.cn_hide {
	display: none !important;
}
.m_side_bar_wrap {
	position: fixed;
	left: 0;
	top: 0;
	width: calc(100vw - 40px);
	bottom: 0;
	background: #fff;
	z-index: 101;
	overflow-y: auto;
	transform: translate3d(-100%, 0, 0);
	transition: all 0.3s;
	padding-bottom: 12px;
	@include font14;
	box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.03);
	&.m_side_bar_wrap_show {
		transform: translate3d(0, 0, 0);
	}
}

.section_box {
}

.section_ctn {
	padding-left: 20px;
	max-height: 0;
	transition: all 0.3s;
	overflow: hidden;

	&.section_ctn_active {
		max-height: 1500px;
	}

	// .view_all_btn {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	color: $textColor1;
	// 	padding: 12px 16px;
	// 	// display: block;
	// 	color: $textColor1;
	// 	cursor: pointer;
	// }
}

.section_item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $textColor1;
	padding: 12px 20px;
	// display: block;
	color: $textColor1;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
	&.section_item_active {
		.section_name {
			// font-weight: 600;
		}
		.iconfont_down {
			transform: rotateX(-180deg);
		}
	}

	.section_name {
		flex: 1;
		word-break: break-all;
		@include font14;
	}

	.iconfont_down {
		line-height: 1;
		padding: 4px;
		font-size: 12px;
		transition: all 0.3s;
	}

	// .iconfont_contact {
	// 	font-size: 16px;
	// 	display: block;
	// 	width: 16px;
	// 	height: 16px;
	// 	line-height: 16px;
	// 	text-align: center;
	// 	margin-right: 8px;
	// }
	.share_box {
		display: flex;
		align-items: center;
		.iconfont-share {
			display: flex;
			font-size: 16px;
			line-height: 1;
			color: $textColor2;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			margin-right: 4px;
			padding: 8px;
			transition: all 0.3s;
			text-decoration: none;
			&:hover {
				color: $textColor3;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.section_line {
	margin: 12px 16px;
	height: 1px;
	border-bottom: 1px solid $borderColor1;
}
.m_side_bar_wrap {
	display: flex;
	flex-direction: column;
	&.no_login {
		padding-bottom: 0;
	}
}
.top {
	// height: 68px;
	display: flex;
	align-items: center;
	padding: 20px;
	border-bottom: 1px solid $borderColor1;
	position: relative;
	.close {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		.iconfont {
			font-size: 20px;
			color: $textColor1;
		}
	}
	.logo_area {
		display: flex;
		align-items: center;
	}
	.logo {
		display: inline-block;
		width: 58px;
		height: 28px;
		@include bgContain("https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo.svg");
		transition: opacity 0.3s;
		text-decoration: none;
		&.logo_cn {
			width: 89px;
			@include bgContain("https://resource.fs.com/mall/generalImg/20230703110143tw3bnh.png");
		}
	}
}
.list {
	overflow: auto;
	flex: 1;
}
.bottom {
	border-top: 1px solid $borderColor1;
	padding: 8px 12px;
	display: flex;
	justify-content: space-between;
	button {
		width: calc(50% - 6px);
	}
	:deep(.fs-button--black) {
		background-color: #707070;
	}
}
.section_box {
	> .section_ctn {
		> .section_ctn {
			> a {
				padding-left: 16px;
			}
		}
	}
}
</style>
