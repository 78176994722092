<template>
	<div class="fs_footer_wrap">
		<div class="footer_ctn">
			<!-- <section class="section_main_m">
				<FooterSubscribe />
			</section> -->
			<section class="section_main">
				<FooterSocial />
				<FooterNav />
			</section>
			<section class="section_bottom">
				<FooterCountry />
				<FooterBottom />
				<FooterCertification />
			</section>
			<section class="section_bottom_m">
				<FooterMBottom />
				<FooterCertification />
			</section>
		</div>
	</div>
</template>
<script setup lang="ts">
import FooterSocial from "./FooterSocial.vue";
import FooterNav from "./FooterNav.vue";
import FooterCountry from "./FooterCountry.vue";
import FooterMBottom from "./FooterMBottom.vue";
import FooterBottom from "./FooterBottom.vue";
import FooterCertification from "./FooterCertification.vue";
// import FooterSubscribe from "./FooterSubscribe.vue";

const deviceStore = useDeviceStore();

const { device } = storeToRefs(deviceStore);
</script>

<style lang="scss" scoped>
.fs_footer_wrap {
	width: 100%;
	background: #fff;
	border-top: 1px solid $borderColor1;
	padding: 55px 0 36px 0;
	// @include pc() {
	// 	display: block;
	// }
	// @include pad() {
	// 	display: none;
	// }
	@include mobile() {
		padding: 0;
		background-color: $bgColor1;
	}
}
.footer_ctn {
	width: 1200px;
	margin: 0 auto;
	@media (max-width: 1200px) {
		width: 100%;
		padding: 0 32px;
	}
	@include pad {
		padding: 0 24px;
	}
	@include mobile {
		width: 100%;
		padding: 0;
	}
}
.section_main_m {
	display: none;
	@include mobile() {
		display: block;
	}
}
.section_main {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@include mobile {
		display: flex;
		flex-direction: column;
	}
}
.section_bottom {
	display: block;
	@include mobile {
		display: none;
	}
}
.section_bottom_m {
	display: none;
	@include mobile {
		display: block;
	}
	:deep(.footer_certification_box) {
		display: flex;
		flex-direction: column;
		margin-top: 5px;
		.certification-box {
			margin-bottom: 16px;
		}
		.pay_box {
			display: flex;

			justify-content: center;

			flex-wrap: wrap;
			// padding: 0 60px;
			img {
				margin-right: 12px;
				margin-bottom: 16px;
				height: 20px;
				margin-left: 0;
			}
		}
	}
}
</style>
