<template>
	<ClientOnly>
		<div
			class="m_notifications_wrap"
			:class="{
				m_notifications_wrap_show: m_header_notifications_show
			}"
			:style="{
				top: `${m_header_height}px`
			}">
			<NotificationsList />
		</div>
	</ClientOnly>
</template>
<script setup lang="ts">
import NotificationsList from "./NotificationsList.vue";
const headerStore = useHeaderStore();

const { m_header_height, m_header_notifications_show } = storeToRefs(headerStore);

onMounted(() => {});
</script>
<style lang="scss" scoped>
.m_notifications_wrap {
	position: fixed;
	right: 0;
	top: 0;
	width: 350px;
	bottom: 0;
	background: #fff;
	z-index: 100;
	overflow-y: auto;
	transform: translate3d(100%, 0, 0);
	transition: all 0.3s;

	box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.03);
	@include font14;

	&.m_notifications_wrap_show {
		transform: translate3d(0, 0, 0);
	}
}
</style>
