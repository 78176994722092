<template>
	<div>
		<Teleport to="body">
			<div class="fs_global_btn_box" :class="{ fs_global_btn_box_scroll: isScroll }">
				<div class="back_top_btn btn_box" :class="{ active: showBackTopBtn }" @click.stop="backTop">
					<span class="iconfont iconfont_top">&#xe630;</span>
				</div>
				<div class="service_btn_wrap">
					<div class="service_btn_box" :class="{ service_btn_hide: showFoldBtn }">
						<div id="bdtj_lxwm" class="livechat_btn btn_box btn_box_line" @click.stop="showLiveChat">
							<span class="iconfont iconfont_chat">&#xe648;</span>
						</div>
						<div class="consult_btn btn_box btn_box_line" @click.stop="showContact">
							<span class="iconfont iconfont_consult">&#xe64c;</span>
						</div>
					</div>
					<div class="fold_btn btn_box" :class="{ btn_folded: showFoldBtn }" tabindex="0" @click="toogleFold">
						<span class="iconfont iconfont_fold">&#xe672;</span>
					</div>
				</div>
			</div>
			<div class="fs_global_btn_box_padMobile">
				<div class="padMobile_btn consult_btn" @click.stop="showContact">
					<img src="https://resource.fs.com/mall/generalImg/20240703145117w1xkha.svg" />
				</div>
				<div id="bdtj_lxwm" class="padMobile_btn livechat_btn" @click.stop="showLiveChat">
					<img src="https://resource.fs.com/mall/generalImg/2024072318170630lxer.svg" />
				</div>
			</div>
		</Teleport>
		<ContactSales v-model="showContactSales" />
	</div>
</template>

<script setup lang="ts">
import ContactSales from "@/popup/ContactSales/index.vue";

const showBackTopBtn = ref(false);
const showContactSales = defineModel({ default: false });
const showFoldBtn = ref(false);

const isScroll = ref(false);
let timer: number | null = null;
function showContact() {
	// console.log("112233");
	// console.log(showContactSales.value);
	showContactSales.value = true;
}

const scrollToElement = useScrollToElement();

const { showLiveChat } = useLiveChat();
function backTop() {
	scrollToElement({ ele: document.body });
}

const onScroll = () => {
	console.log("112233");
	const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	const clientHeight = window.screen.availHeight;
	const page = /\/products\/\d+\.html/.test(location.pathname) ? 1 : 2;
	if (scrollTop >= clientHeight * page) {
		showBackTopBtn.value = true;
	} else {
		showBackTopBtn.value = false;
	}
	isScroll.value = true;
	if (timer) {
		clearTimeout(timer);
		timer = null;
	}
	timer = window.setTimeout(() => {
		isScroll.value = false;
	}, 200);
};

function toogleFold() {
	showFoldBtn.value = !showFoldBtn.value;
	localStorage.setItem("suspendFold", `${showFoldBtn.value}`);
}

useEventListener("window", "scroll", onScroll);

onMounted(() => {
	showFoldBtn.value = localStorage.getItem("suspendFold") === "true";
});
</script>

<style lang="scss" scoped>
.fs_global_btn_box {
	position: fixed;
	display: flex;
	flex-direction: column;
	right: 20px;
	bottom: 48px;
	z-index: 98;
	@include padMobile() {
		display: none;
		right: 16px;
	}
	&.fs_global_btn_box_scroll {
		@include padMobile() {
			opacity: 0.5;
		}
	}
}
.service_btn_wrap {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
.service_btn_box {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 96px;
	transition: all 0.3s;
	overflow: hidden;
	&.service_btn_hide {
		@include padMobile() {
			height: 0;
		}
	}
}
.btn_box {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	transition: all 0.3s;
	cursor: pointer;
	user-select: none;
	position: relative;
	&.back_top_btn {
		opacity: 0;
		margin-bottom: 20px;
		border-radius: 4px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
		transition: all 0.3s;
		cursor: pointer;
		&.active {
			opacity: 1;
		}
	}
	&.livechat_btn {
		border-radius: 4px 4px 0 0;
	}
	&.consult_btn {
		border-radius: 0 0 4px 4px;
		background-color: #707070;
		@include padMobile() {
			background-color: #fff;
			border-radius: 0;
		}
		.iconfont {
			color: #fff;
			&:hover {
				color: #fff;
			}
			@include padMobile() {
				color: $textColor2;
				&:hover {
					color: $textColor1;
				}
			}
		}
	}
	&.btn_box_line {
		@include padMobile() {
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 7px;
				right: 7px;
				background-color: #e5e5e5;
				height: 1px;
				z-index: 2;
			}
		}
	}
	&.fold_btn {
		display: none;
		@include padMobile() {
			display: flex;
			border-radius: 0 0 4px 4px;
			&.btn_folded {
				border-radius: 4px;
			}
		}
	}
	&.btn_folded {
		.iconfont_fold {
			transform: rotateZ(180deg);
		}
	}
	.iconfont {
		font-size: 24px;
		display: block;
		width: 24px;
		height: 24px;
		line-height: 24px;
		color: $textColor2;
		transition: all 0.3s;
		@include padMobile() {
			font-size: 21px;
			display: block;
			width: 21px;
			height: 21px;
			line-height: 21px;
			&:hover {
				color: $textColor2;
			}
		}
		&:hover {
			color: $textColor1;
		}
		&.iconfont_fold {
			font-size: 16px;
			display: block;
			width: 16px;
			height: 16px;
			line-height: 16px;
		}
	}
}

.fs_global_btn_box_padMobile {
	position: fixed;
	display: none;
	flex-direction: column;
	right: 10px;
	bottom: 16px;
	z-index: 98;
	display: none;
	@include padMobile() {
		display: flex;
		right: 16px;
	}
	.padMobile_btn {
		width: 44px;
		height: 44px;
		border-radius: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 24px;
		}
		&.consult_btn {
			background: #ffffff;
			box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
		}
		&.livechat_btn {
			background: #707070;
			margin-top: 16px;
		}
	}
}
</style>
