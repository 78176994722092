<template>
	<div v-if="NeedRecaptcha" class="g-recaptcha"></div>
</template>

<script lang="ts" setup>
import { FsMessage } from "fs-design";
import useGrecaptchaHook from "@/composables/useGrecaptcha";

const { NeedRecaptcha, sitekey } = useGrecaptchaHook();

const localeLang = useLocaleLang();

const emits = defineEmits(["update:grecaptchaInfo"]);

const clickRecaptcha = () => {
	return new Promise((resolve, reject) => {
		if (window?.grecaptcha && window.grecaptcha?.execute) {
			window.grecaptcha
				?.execute(sitekey, { action: "login" })
				.then((res: any) => {
					const recaptchaTp = res.length > 0;
					if (!recaptchaTp) {
						// 谷歌人机验证生成token为空的提示
						FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error1"), type: "danger" });
					}
					resolve({ recaptchaTp, headers: { "g-recaptcha-response": res.length > 0 ? res : "" } });
				})
				.catch((err: any) => {
					console.log(err);
					// 谷歌人机验证执行错误的提示
					FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error"), type: "danger" });
					resolve({ recaptchaTp: false, headers: { "g-recaptcha-response": "" } });
				});
		} else {
			// 客户端不支持谷歌环境导致谷歌人机验证不能执行的的提示
			FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error1"), type: "danger" });
			resolve({ recaptchaTp: false, headers: { "g-recaptcha-response": "" } });
		}
	});
};

defineExpose({
	clickRecaptcha
});
</script>

<style lang="scss" scoped>
.error_info {
	flex: 1;
	color: #c00000;
	font-weight: normal;
	@include font13;
}
</style>
