<template>
	<FsTooltip
		ref="accountTipRef"
		placement="bottom-end"
		trigger="hover"
		:offsetY="14"
		:offset-x="offsetX"
		:popperContentStyle="{
			width: '300px',
			padding: '20px',
			borderRadius: '4px'
		}">
		<a
			class="account_box"
			:class="{ account_box_hover: accountTipRef?.visible }"
			href="javascript:;"
			tabindex="0"
			@click.stop="topClick(isLogin ? 'Account-top' : 'Sign In Top')">
			<template v-if="isLogin">
				<img class="account_svg" src="https://resource.fs.com/mall/generalImg/20240521161918v8h8as.svg" />
			</template>
			<template v-else>
				<span class="iconfont iconfont_account">&#xe679;</span>
			</template>
			<!-- <p v-if="website !== 'cn'" class="icon_info">
				{{ isLogin ? localeLang("header.account.account") : localeLang("header.account.Sign_In") }}
			</p> -->
		</a>
		<template #content>
			<AccountList @showPopup="showPopup" />
		</template>

		<FsDialog v-model="isShowLink" :title="localeLang('header.linkuser.linkUser')">
			<div class="new_user_popup">
				<div class="popup_container">
					<p class="tips">
						<span class="iconfont">&#xf039;</span>
						<span>{{ localeLang("header.linkuser.tips.text2") }}</span>
					</p>
					<div class="creat_form">
						<h3>{{ localeLang("header.linkuser.popupForm.userInformation") }}</h3>
						<ul v-if="website === 'cn'">
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.userName") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('first_name')" />
								<validate-error :error="errors.first_name"></validate-error> />
							</li>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.phone_number") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('phone')" />
								<validate-error :error="errors.phone"></validate-error> />
							</li>
						</ul>
						<ul v-else>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.firstName") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('first_name')" />
								<validate-error :error="errors.first_name"></validate-error>
							</li>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.lastName") }}</span>
								<input v-model.trim="form.last_name" type="text" maxlength="33" @blur="inputCheck('last_name')" />
								<validate-error :error="errors.last_name"></validate-error>
							</li>
						</ul>
						<div class="form_item">
							<span>{{ localeLang("ContactSales.email_business") }}</span>
							<input v-model.trim="form.email" type="text" @blur="inputCheck('email')" />
							<validate-error :error="errors.email"></validate-error>
						</div>
						<div class="form_item_team">
							<span>{{ localeLang("header.linkuser.selectTeamLink") }}</span>
							<div class="form_item">
								<FsSelect
									v-model="productModelOptionData.test"
									placeholder="Please select..."
									:options="productModelOptionData.teamList"
									type="checkbox"
									sameWidth
									@change="handleTeamChange" />
							</div>
						</div>
						<div class="radio_group">
							<div class="assign_roles">
								<b>{{ localeLang("header.linkuser.assignRoles.assign") }}</b>
								<FsTooltip placement="right" trigger="hover"
									><span class="iconfont iconfont-tip">&#xe66a;</span>
									<template #content>
										<span>{{ localeLang("header.linkuser.assignRoles.tips") }}</span>
									</template></FsTooltip
								>
							</div>
							<div v-for="i in assignRoles" :key="i.value" class="radio_item">
								<span v-if="i.disabled" class="disable_icon"></span>
								<span v-else class="iconfont" :class="{ active: form.role === i.value }" @click="selectRole(i)">{{
									form.role === i.value ? "&#xf050;" : "&#xf051;"
								}}</span>
								<div>
									<span @click="selectRole(i)">{{ i.role }}</span>
									<p class="radio_tips">{{ i.tips }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="btn">
						<FsButton type="black" plain @click="closePopup">{{ localeLang("header.linkuser.btn.cancel") }}</FsButton>
						<FsButton type="red" :loading="loading" native-type="submit" @click="sureLink"
							>{{ localeLang("header.linkuser.btn.submit") }}
						</FsButton>
					</div>
				</div>
			</div>
		</FsDialog>
	</FsTooltip>
</template>
<script setup lang="ts">
import { FsTooltip, FsButton, FsSelect, FsDialog, FsMessage } from "fs-design";
import { onMounted, computed, ref, reactive } from "vue";
import AccountList from "./AccountList.vue";
import { email_valdate } from "~/constants/validate";

import ValidateError from "@/component/FsFooter/components/ValidateError.vue";
const websiteStore = useWebsiteStore();
const userStore = useUserStore();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const isShowLink = ref(false);
const isGetTeam = ref(false);
const loading = ref(false);

const route = useRoute();
const accountTipRef = ref(null);
const { website } = storeToRefs(websiteStore);

const { isLogin } = storeToRefs(userStore);
const offsetX = ref(0);
const productModelOptionData = ref({
	teamList: [],
	test: []
});
let sendTeamList: { team_id: any; type: any }[] = [];
function topClick(str: string): void {
	if (str === `Sign In`) {
		location.href = localeLink(`/login.html?redirect=${route.fullPath}`);
	} else if (str === `Account`) {
		location.href = localeLink(`/my-account`);
	}
}
const showPopup = data => {
	if (!isGetTeam.value) {
		getTeamData();
	}
	isShowLink.value = data;
};

interface FormType {
	first_name: string;
	phone: string;
	last_name: string;
	email: string;
	role: number;
	team_ids: [];
}

const form: FormType = reactive({
	first_name: "",
	phone: "",
	last_name: "",
	email: "",
	role: 0,
	team_ids: []
});

const errors: FormType = reactive({
	first_name: "",
	phone: "",
	last_name: "",
	email: "",
	role: 0,
	team_ids: []
});

const inputCheck = (attr: keyof FormType) => {
	let msg = "";
	const val = form[attr] && form[attr]?.replace(/\s+/g, "");
	if (attr === "first_name") {
		if (form.first_name.length < 1) {
			msg = localeLang("header.linkuser.popup.validateError.text1");
		} else if (form.first_name.length < 2) {
			msg = localeLang("header.linkuser.popup.validateError.text2");
		} else if (form.first_name.length > 32) {
			msg = localeLang("header.linkuser.popup.validateError.text3");
		} else {
			msg = "";
		}
	}
	if (attr === "phone") {
		if (!val) {
			msg = "请输入正确的电话号码";
		}
	}
	if (attr === "last_name") {
		if (form.last_name.length < 1) {
			msg = localeLang("header.linkuser.popup.validateError.text4");
		} else if (form.last_name.length < 2) {
			msg = localeLang("header.linkuser.popup.validateError.text5");
		} else if (form.last_name.length > 32) {
			msg = localeLang("header.linkuser.popup.validateError.text6");
		} else {
			msg = "";
		}
	}
	if (attr === "email") {
		if (form.email.length < 1) {
			msg = localeLang("ContactSales.service.ready.errors.email_address_error");
		} else if (!email_valdate.test(form.email)) {
			msg = localeLang("header.linkuser.popup.validateError.text8");
		} else {
			msg = "";
		}
	}
	if (attr === "role") {
		if (!val) {
			msg = localeLang("ContactSales.service.ready.errors.comments_error");
		}
	}

	errors[attr] = msg;
	return !!msg;
};

function setOffsetX() {
	const cartBox = document.querySelector(".fs_header_wrap .header_ctn_right .cart_box");
	if (cartBox) {
		offsetX.value = cartBox.clientWidth;
	}
}

const closePopup = () => {
	isShowLink.value = false;
};
const sureLink = async () => {
	const flag = validateAll();
	if (flag) {
		return;
	}
	loading.value = true;
	let params = {};
	if (website.value === "cn") {
		const { first_name, email, role, phone } = form;
		params = {
			first_name,
			email,
			phone,
			role,
			team_ids: sendTeamList
		};
	} else {
		const { first_name, last_name, role, email } = form;
		params = {
			first_name,
			last_name,
			email,
			role,
			team_ids: sendTeamList
		};
	}

	console.log(params, "数据");
	const { data, error } = await useRequest.post("/api/companyAccount/linkUserToOrganization", { data: params });
	const res = data.value;
	if (res) {
		loading.value = false;
		FsMessage({ message: localeLang("header.linkuser.successText.content"), type: "success" });
		isShowLink.value = false;
	}
	if (error.value && error.value.data.message) {
		loading.value = false;
		FsMessage({ message: error.value.data.message, type: "danger" });
	}
};

const validateAll = () => {
	if (website.value === "cn") {
		const arr: boolean[] = [];
		const attr = ["first_name", "email", "phone"];
		attr.forEach((item: any) => {
			const f = inputCheck(item);
			arr.push(f);
		});
		return arr.includes(true);
	} else {
		const arr: boolean[] = [];
		const attr = ["first_name", "last_name", "email"];
		attr.forEach((item: any) => {
			const f = inputCheck(item);
			arr.push(f);
		});
		return arr.includes(true);
	}
};

onMounted(() => {
	nextTick(() => {
		setOffsetX();
	});
});

const getTeamData = async () => {
	try {
		const { data, error } = await useRequest.get("/api/companyAccount/getARoleTeams");
		const res = data.value;
		if (res) {
			if (res.code === 200) {
				console.log("", "网络请求成功");
				isGetTeam.value = true;
				// 处理成功的逻辑
				const versionData = data.value?.data.map((item: any, index: number) => {
					if (index === 0) {
						productModelOptionData.value.test = [item.id];
						sendTeamList = [{ team_id: item.id, type: item.type }];
					}
					return {
						label: item.name,
						value: item.id,
						type: item.type
					};
				});
				productModelOptionData.value.teamList = versionData;
			}
		}
	} catch (err) {
		console.log(err, "网络请求失败");
	}
};
const handleTeamChange = (value: any) => {
	const versionData = value.map((item: any, index: number) => {
		return {
			team_id: item.value,
			type: item.type
		};
	});
	sendTeamList = versionData;
	console.log(sendTeamList, "多选选中");
};

const info = ref(Object);
const isDisAbledRole = ref();

const assignRoles = computed(() => {
	const { roleCode } = info.value || {};

	const arr = [
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.admin"),
			value: 1,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.admin"),
			disabled: false
		},
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.buyer"),
			value: 2,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.buyer"),
			disabled: false
		},
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.finance"),
			value: 3,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.finance"),
			disabled: false
		}
	];

	if (roleCode && roleCode === "AA" && isDisAbledRole) {
		return arr.map(i => {
			return { ...i, disabled: i.value !== 1 };
		});
	} else {
		return arr;
	}
});

const selectRole = (i: { value: any }) => {
	form.role = i.value;
};
</script>
<style lang="scss" scoped>
.account_box {
	// min-width: 53px;
	// cursor: pointer;
	// padding: 0 8px;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	// height: 84px;
	position: relative;
	color: $textColor1;
	&:hover {
		background: $bgColor2;
		text-decoration: none;
	}

	&.account_box_hover {
		background: $bgColor3;
	}
	&:hover {
		background: $bgColor2;
	}
	.iconfont_account {
		font-size: 20px;
		display: block;
		width: 20px;
		height: 20px;
		line-height: 20px;
		color: $textColor1;
	}
	.account_svg {
		width: 20px;
		height: 20px;
		display: block;
	}
	.icon_info {
		white-space: nowrap;
		font-size: 12px;
		padding-top: 2px;
	}
}

.new_user_popup {
	.tips {
		border-radius: 3px;
		padding: 10px 16px;
		background-color: rgba(0, 96, 191, 0.04);
		display: flex;
		column-gap: 8px;
		@include font12;
		color: $textColor2;
		margin-bottom: 20px;
		.iconfont {
			color: #0060bf;
			// font-weight: 600;
		}
	}
	.assign_roles {
		@include font16;
		margin-bottom: 8px;
		display: flex;
		align-items: center;
	}
	.iconfont-tip {
		font-size: 16px;
		margin-left: 4px;
		display: inline-block;
		font-weight: normal;
		width: 16px;
		height: 16px;
		line-height: 1;
		color: $textColor3;
		cursor: pointer;
		position: relative;
	}

	.creat_form {
		margin-bottom: 20px;
		> h3 {
			@include font16;
			margin-bottom: 8px;
		}

		> ul {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 20px;
			margin-bottom: 12px;
			align-items: flex-start;
		}
		.form_item {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			row-gap: 4px;
			> span {
				@include font12;
			}
		}
		.form_item_team {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			row-gap: 4px;
			margin-top: 12px;
			> span {
				@include font12;
			}
		}
		.radio_group {
			margin-top: 20px;
			.radio_item {
				display: flex;
				column-gap: 8px;
				@include font14;
				margin-bottom: 20px;
				.radio_tips {
					margin-top: 4px;
					color: $textColor3;
					@include font12;
				}
				.iconfont {
					cursor: pointer;
					font-size: 18px;
					position: relative;
					color: #ccc;
					&.disabled {
						cursor: not-allowed;
						background-color: #f7f7f7;
					}
					&.active {
						color: $textColor3;
						// @media (max-width: 768px) {
						//     color: $textColor3;
						// }
					}
				}
				.disable_icon {
					width: 18px;
					height: 18px;
					border: 1px solid #ccc;
					background-color: #f7f7f7;
					border-radius: 50%;
					cursor: not-allowed;
					margin-top: 3px;
				}
				> div {
					> span {
						cursor: pointer;
					}
				}
			}
		}
	}
	.btn {
		width: max-content;
		margin-left: auto;
		// display: flex;
		// justify-content: flex-end;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 12px;
	}
	.error_result {
		margin-bottom: 20px;
	}
}
@media (max-width: 768px) {
	.new_user_popup {
		width: 100%;
		padding: 20px 16px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.popup_container {
			flex: 1;
			overflow-y: scroll;
			.creat_form {
				> ul {
					grid-template-columns: repeat(1, 1fr);
					row-gap: 12px;
				}
			}
		}
		.btn {
			display: flex;
			flex-direction: column-reverse;
			row-gap: 12px;
			width: 100%;
		}
	}
}
</style>
