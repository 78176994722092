<template>
	<FsDialog
		v-model="dialogStatus"
		className="contact_sales_popup"
		:width="isMobile ? '100%' : '380px'"
		:fullScreen="isMobile"
		:modal="isMobile"
		transition="slide-right"
		:lockScroll="false"
		:headerStyle="headerStyle"
		:customDialogStyle="customDialogStyle"
		:contentStyle="contentStyle"
		:leaveFn="leaveFn">
		<template #title>
			<div class="contact_sales_popup_title">{{ localeLang("ContactSales.ContactSales") }}</div>
		</template>
		<template v-if="!success">
			<div v-if="website === 'sg'" class="whatsapp">
				<p>You can chat with sales via <a href="https://wa.me/6564437951" target="_blank">WhatsApp</a>.</p>
				<div class="whatsapp__text">
					<div class="whatsapp__line"></div>
					<div class="whatsapp__text__content">or fill out the form below</div>
					<div class="whatsapp__line"></div>
				</div>
			</div>
			<FsForm ref="formRef" :rules="rules" :model="model" layout="vertical">
				<FsFormItem
					prop="entry_firstname"
					:label="`${localeLang('ContactSales.first_name')} *`"
					:inline="isCn && !isMobile">
					<FsInput v-model="model.entry_firstname" />
				</FsFormItem>
				<FsFormItem
					prop="entry_lastname"
					:label="`${localeLang('ContactSales.last_name')} *`"
					:inline="isCn && !isMobile">
					<FsInput v-model="model.entry_lastname" />
				</FsFormItem>
				<template v-if="isCn">
					<FsFormItem prop="entry_telephone" :label="`${localeLang('ContactSales.phone_business')} *`">
						<FsInput v-model="model.entry_telephone" />
					</FsFormItem>
				</template>
				<FsFormItem prop="email_address" :label="`${emailLabel} *`">
					<FsInput v-model="model.email_address" />
				</FsFormItem>
				<FsFormItem prop="comments" :label="`${localeLang('ContactSales.comment')} *`">
					<FsInput v-model="model.comments" :placeholder="localeLang('ContactSales.helpPH')" type="textarea"> </FsInput>
				</FsFormItem>
			</FsForm>
			<div
				class="agreement_wrap"
				v-html="
					localeLang('ContactSales.submitTip')
						.replace('XXXX1', localeLink('privacy-policy'))
						.replace('XXXX2', localeLink('terms-of-use'))
				"></div>
		</template>
		<div v-else class="success-box">
			<div class="success-logo iconfont">&#xf281;</div>
			<div class="success-tit">{{ localeLang("ContactSales.SubmittedSuccessfully") }}</div>
			<div class="success-des" v-html="subStr(localeLang('ContactSales.success.txt'))"></div>
		</div>
		<template #footer>
			<!-- <FsButton type="black" plain @click.prevent="cancel">{{ localeLang("ContactSales.cancel") }}</FsButton> -->
			<FsButton id="contact_sales" type="red" :loading="submitLoading" @click.prevent="submit">
				{{ localeLang("ContactSales.submit") }}
			</FsButton>
		</template>
	</FsDialog>
</template>

<script setup lang="ts">
import { FsButton, FsDialog, FsForm, FsFormItem, FsMessage, FsInput } from "fs-design";
import type { FormInstance } from "fs-design";
import type { ContactSalesProps, ContactSalesEmits } from "./type";
import { email_valdate, cn_all_phone } from "~/constants/validate";
const localeLang = useLocaleLang();
const localeLink = useLocaleLink();
const route = useRoute();
const props = defineProps<ContactSalesProps>();
const emits = defineEmits<ContactSalesEmits>();
const { isLogin, userInfo } = useUserStore();
const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);
const websiteStroe = useWebsiteStore();
const { website, isCn } = toRefs(websiteStroe);
const formRef = ref<FormInstance>();
const success = ref(false);
const submitLoading = ref(false);
const dialogStatus = ref(false);
const model = ref({
	entry_firstname: "",
	entry_lastname: "",
	email_address: "",
	entry_telephone: "",
	comments: ""
});
const contentStyle = ref({});
const defaultRule = {
	required: true,
	trigger: "blur",
	transform(value: string) {
		return value.trim();
	},
	message: localeLang("ContactSales.fieldRequired")
};
const checkAccountExistence = async (value: string) => {
	if (!isLogin) {
		const { data } = await useRequest.post("/api/user/isHasRegister", {
			data: {
				customers_name: value
			}
		});
		if (data.value.code !== 200) return;
		if (data.value.data?.is_has) {
			const url = localeLink(`/login.html?redirect=${route.fullPath}`);
			const html = `账户已存在，单击此处<a href='${url}' title='登录'>登录</a>。`;
			return Promise.reject(html);
		}
	}
	return Promise.resolve("");
};
const rules: { [x: string]: any } = ref({
	entry_firstname: [{ ...defaultRule }],
	entry_lastname: [{ ...defaultRule }],
	comments: [
		{
			...defaultRule,
			message: isCn.value ? "请填写您的需求。" : localeLang("ContactSales.fieldRequired")
		}
	],
	entry_telephone: [
		{
			...defaultRule,
			message: () => localeLang("ContactSales.errors.entry_telephone_error"),
			validator: async (_: any, value: string) => {
				if (!cn_all_phone.test(value)) {
					return Promise.reject(localeLang("ContactSales.errors.entry_telephone_error01"));
				} else {
					return await checkAccountExistence(value);
				}
			}
		}
	],
	email_address: [
		{
			...defaultRule,
			message: localeLang("ContactSales.errors.email_address_error"),
			validator: async (_: any, value: string) => {
				if (!isCn.value) {
					return !email_valdate.test(value)
						? Promise.reject(localeLang("ContactSales.errors.email_address_error01"))
						: Promise.resolve("");
				} else {
					if (!email_valdate.test(value)) {
						return Promise.reject(localeLang("ContactSales.errors.email_address_error01"));
					} else {
						return await checkAccountExistence(value);
					}
				}
			}
		}
	]
});
const customDialogStyle = computed(() => {
	return isMobile.value
		? {}
		: {
				width: "380px",
				height: "auto",
				left: "auto",
				top: "auto",
				right: "84px",
				bottom: "48px"
			};
});

const headerStyle = ref({
	padding: `20px 16px`,
	lineHeight: `24px`,
	border: "none",
	"border-bottom": "1px solid #e5e5e5"
});
const emailLabel = computed(() => {
	return `${localeLang("ContactSales.email_business")}${isCn.value ? localeLang("ContactSales.optional") : ""}`;
});
const submit = async () => {
	try {
		submitLoading.value = true;
		!success.value && (await formRef?.value?.validate());
		const { data } = await useRequest.post("/api/contact_sales", {
			data: {
				...model.value
			}
		});
		submitLoading.value = false;
		if (data?.value?.code === 200) {
			if (data?.value?.status === "sensiWords" && website.value === "cn") {
				for (const key in data?.value?.errors) {
					Object.keys(rules.value).forEach(ele => {
						if (key === ele) {
							rules.value[key].push({
								validator: () => Promise.reject(localeLang("ContactSales.errors.sensiWords")),
								required: true
							});
						}
					});
					await formRef?.value?.validateField(key);
				}
			}
			success.value = true;
			contentStyle.value = {
				padding: "36px"
			};
		} else {
			FsMessage({ message: data?.value?.message });
		}
	} catch (e: any) {
		submitLoading.value = false;
		FsMessage({ message: e?.length > 0 ? e[0].message : " " });
	}
};
const cancel = () => {
	dialogStatus.value = false;
	!success.value && formRef?.value?.resetFields();
};
const subStr = (str: string) => {
	return str
		.replace("%XXXX%", `<a class="case_btn" href="${localeLink(`/support_ticket`)}">`)
		.replace("%ZZZZ%", "</a>");
};
const leaveFn = (el: any, done: any) => {
	el.style.opacity = "0.1";
	setTimeout(done, 300);
};
watch(
	() => props.modelValue,
	newVal => {
		dialogStatus.value = newVal;
		if (isLogin) {
			model.value.entry_firstname = userInfo?.customers_firstname;
			model.value.entry_lastname = userInfo?.customers_lastname;
			model.value.email_address = userInfo?.customers_email_address;
			model.value.entry_telephone = userInfo?.customers_telephone;
		}
	}
);

watch(dialogStatus, newVal => {
	if (!newVal) {
		emits("update:modelValue", newVal);
		success.value = false;
		formRef?.value?.resetFields();
	}
});
</script>
<style lang="scss">
@import "./index.scss";
</style>
