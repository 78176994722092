<template>
	<div class="foooterMBottom">
		<div class="website_wrap">
			<div class="website_box" @click.stop="toogleChangeWebsite">
				<!-- <span class="iconfont iconfont_global">&#xe674;</span> -->
				{{ $refs.tooltipRef }}
				<span class="current_country_info">{{ `${country_name}` }}</span>
				<span class="iconfont iconfont_down" :class="{ iconfont_down_up: changeWebsiteVisible }">&#xf049;</span>
			</div>
		</div>
		<FooterApp />
		<div v-if="!isCn && socialLink && socialLink.length" class="foooterMBottom__share">
			<a
				v-for="i in socialLink"
				:key="i.title"
				class="iconfont"
				target="_blank"
				tabindex="0"
				:href="localeLink((i.link as any)[website] || i.link.en)"
				:title="i.title"
				:aria-label="i.title"
				v-html="i.icon"></a>
		</div>

		<div
			v-if="!(iso_code === 'FR' || countryPayment[website] || ['cn'].includes(website)) || website === 'uk'"
			class="foooterMBottom__countryBox">
			<!-- <a
				v-for="img in (certifications as any)[warehouse]"
				:key="img.imgUrl"
				tabindex="0"
				:href="localeLink(img.toUrl)"
				:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
				:aria-label="img.label"
				:style="img.toUrl == 'javascript:;' ? 'cursor:auto;' : 'cursor:pointer'">
				<img :src="img.imgUrl" :alt="img.label" :aria-label="img.label" />
			</a> -->
			<CertificationCtn :data="certifications[warehouse]" />
		</div>
		<div v-if="['cn'].includes(website)" class="certification-cn-box">
			<a
				href="https://ss.knet.cn/verifyseal.dll?sn=e15101544030060902l1bf000000&ct=df&a=1&pa=0.05508846207521856"
				target="_blank"></a>
			<a href="https://credit.szfw.org/CX20150924011838250320.html" target="_blank"></a>
			<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502007285" target="_blank">
				<img src="https://img-en.fs.com/zh/includes/templates/fiberstore/images/beian_icon.png" alt="" />
				<p>粤公网安备 44030502007285号</p>
			</a>
		</div>
	</div>
	<div class="foooterMBottom__copy">
		<p class="foooterMBottom__copy__copyright" v-html="right"></p>
		<div v-if="policy_data && policy_data.length" class="foooterMBottom__copy__policy">
			<template v-for="(item, index) in policy_data" :key="item.text">
				<a tabindex="0" :href="item.url"> {{ item.text }} </a>
				<span v-if="index !== policy_data.length - 1" :key="'line' + item.text" class="line"></span>
			</template>
		</div>
	</div>
	<ClientOnly>
		<Teleport to="body">
			<ChangeWebsite
				v-if="changeWebsiteVisible"
				:search="search"
				@saveSearchValue="saveSearchValue"
				@hideChangeWebsiteTip="toogleChangeWebsite" />
		</Teleport>
	</ClientOnly>
</template>

<script setup lang="ts">
import FooterApp from "./FooterApp.vue";
import CertificationCtn from "./CertificationCtn.vue";
import { certifications, countryPayment } from "@/constants/certifications";
import ChangeWebsite from "@/component/FsHeader/components/ChangeWebsite.vue";
import socialLink from "@/constants/socialLink";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const { right } = storeToRefs(headerStore);
const changeWebsiteVisible = ref(false);

const { website, iso_code, country_name, warehouse, isCn } = storeToRefs(websiteStore);

const policy_data = ref([
	{
		url: localeLink("/policies/privacy_policy.html"),
		text: localeLang("footer.singleFooter.policy")
	},
	{
		url: localeLink("/policies/terms_of_use.html"),
		text: localeLang("footer.singleFooter.terms")
	}
]);

function toogleChangeWebsite() {
	nextTick(() => {
		changeWebsiteVisible.value = !changeWebsiteVisible.value;
		headerStore.$patch(state => {
			state.m_header_right_show = !changeWebsiteVisible.value;
		});
		if (changeWebsiteVisible.value) {
			headerStore.hideAllSideBar();
			fixScroll.fixed();
		} else {
			fixScroll.unfixed();
		}
	});
}

const search = ref("");
function saveSearchValue(val: string) {
	console.log("val", val);
	search.value = val;
}
</script>
<style lang="scss" scoped>
.foooterMBottom {
	.fs-tooltip {
		width: 100%;
	}
	&__changeWebsite {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px 0 18px 0;
		color: $textColor2;
		width: 100%;
		.iconfont {
			font-size: 12px;
		}
		.iconfont_global {
			font-size: 18px;
		}
		&__country {
			@include font12;
			margin: 0 4px;
		}
		.iconfont_down {
			font-size: 12px;
			&.iconfont_down_up {
				transform: rotateX(-180deg);
			}
		}
	}
	.foooterMBottom__countryBox {
		margin-bottom: 20px;
	}
	.foooterMBottom__share {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		a {
			display: flex;
			font-size: 26px;
			color: $textColor2;
			cursor: pointer;
			margin: 0 5px;
			width: 26px;
			height: 26px;
			align-items: center;
			justify-content: center;
			text-decoration: none;
		}
	}

	.line {
		display: inline-block;
		width: 1px;
		height: 10px;
		border-left: 1px solid #707070;
		font-size: 12px;
		margin: 0 6px;
	}
	.foooterMBottom__copy__countryBox {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 15px;
		a {
			margin-right: 12px;
			user-select: none;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
			&:last-of-type {
				margin-right: 0;
			}
			img {
				width: auto;
				max-height: 24px;
			}
		}
	}
}
.foooterMBottom__copy {
	padding: 0 0 24px 0;
	.foooterMBottom__copy__copyright {
		text-align: center;
		@include font12;
		color: $textColor2;
		padding: 0 16px;
	}
	.foooterMBottom__copy__policy {
		display: flex;
		align-items: center;
		justify-content: center;
		@include font12;
		padding-top: 4px;
	}
	a {
		display: flex;
		align-items: center;
		color: $textColor2;
	}
	.line {
		display: inline-block;
		width: 1px;
		height: 10px;
		border-left: 1px solid #707070;
		font-size: 12px;
		margin: 0 6px;
	}
}
.website_wrap {
	display: flex;
	justify-content: center;
	margin: 20px 0 20px 0;
}
.website_box {
	cursor: pointer;
	display: flex;
	align-items: center;
	color: $textColor2;
	.iconfont_global {
		font-size: 18px;
	}

	.current_country_info {
		@include font12;
		margin: 0 4px;
		white-space: nowrap;
	}

	.iconfont_down {
		@include font12;
		&.iconfont_down_up {
			transform: rotateX(-180deg);
		}
	}
}

.certification-cn-box {
	display: flex;
	justify-content: space-around;
	a {
		width: 80px;
		height: 40px;
		background: url("https://img-en.fs.com/zh/includes/templates/fiberstore/images/footer/customer_service_icon.png")
			no-repeat;
		text-decoration: none;
		transform: scale(0.75);
		display: flex;
		flex-shrink: 0;

		&:nth-of-type(1) {
			background-position: -61px -28px;
		}
		&:nth-of-type(2) {
			background-position: -61px -126px;
		}
		&:nth-of-type(3) {
			background-position: -61px -222px;
		}
		&:last-child {
			width: auto;
			display: flex;
			align-items: center;
			background: none;
			img {
				display: block;
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}
			p {
				@include font12;
				color: $textColor3;
			}
		}
	}
}
</style>
