<template>
	<div
		class="change_website_wrap"
		:class="{ change_website_wrap_m: device !== 'pc' }"
		:style="{
			top: device !== 'pc' ? `${m_header_height}px` : '0',
			height: `${device !== 'pc' ? `calc(100vh - ${m_header_height}px)` : `400px`}`
		}">
		<div class="change_website_header">
			<span v-if="device === 'pc'">{{ localeLang("header.Search_your_state") }}</span>
			<div v-else class="current_country">
				<span class="iconfont">&#xf060;</span>
				<span class="country_info" :aria-label="country_name">{{
					`${country_name} - ${language} / ${symbol}${["USD"].includes(currency) ? "" : " "}${currency}`
				}}</span>
			</div>
			<span class="iconfont iconfont_close" @click.stop="emit('hideChangeWebsiteTip')">&#xe6a0;</span>
		</div>
		<div class="current_country">
			<span class="iconfont">&#xf060;</span>
			<span class="country_info" :aria-label="country_name">{{
				`${country_name} - ${language} / ${symbol}${["USD"].includes(currency) ? "" : " "}${currency}`
			}}</span>
		</div>
		<div class="current_line"></div>
		<div class="search_box">
			<span v-if="!search && !focusValue" class="iconfont iconfont_search">&#xe694;</span>
			<input v-model.trim="search" type="text" tabindex="0" @focus="focusValue = true" @blur="focusValue = false" />
			<span v-if="search" class="iconfont iconfont_clear" @click.stop="search = ''">&#xe6a0;</span>
		</div>
		<div class="country_list_box">
			<ul v-if="computedAreaList && computedAreaList.length" class="country_list">
				<template v-for="(item, index) in computedAreaList" :key="index">
					<li class="item" tabindex="0">
						<div>
							<span @click.stop="changeWebsite(item)">
								<span class="country_name">{{
									(isCn ? item.countries_chinese_name : item.countries_name) + " - "
								}}</span>
								<span class="country_lang_symbol">{{
									`${item.language} / ${item.symbol}${item.currency === "USD" ? "" : " "}${item.currency}`
								}}</span>
							</span>
						</div>
					</li>
				</template>
			</ul>
			<p v-else>
				{{ localeLang("header.noCountryResult") }}
			</p>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FsDivider } from "fs-design";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const countryStore = useCountryStore();
const localeLang = useLocaleLang();
const deviceStore = useDeviceStore();
const { country_name, language, currency, symbol, isCn } = storeToRefs(websiteStore);
const { m_header_height } = storeToRefs(headerStore);
const { device } = storeToRefs(deviceStore);

const props = defineProps({
	search: {
		type: String,
		default() {
			return "";
		}
	}
});
const search = ref(props.search);
const focusValue = ref(false);
const emit = defineEmits();
const computedAreaList = computed(() => {
	if (!search.value) {
		return countryStore.areaList;
	}
	if (isCn.value) {
		return countryStore.areaList.filter((item: any) => item.countries_chinese_name.includes(search.value));
	} else {
		return countryStore.areaList.filter(
			(item: any) =>
				(item.countries_id === 44 && ["中国", "中", "国"].includes(search.value)) ||
				item.countries_name?.toLowerCase().includes(search.value?.toLowerCase())
		);
	}
});
watch(
	() => search.value,
	(n, o) => {
		console.log("search.value", search.value);
		emit("saveSearchValue", search.value);
	}
);

function changeWebsite(item: any) {
	emit("hideChangeWebsiteTip");
	websiteStore.updateWebsite(item);
}
</script>
<style lang="scss" scoped>
.change_website_wrap {
	width: 260px;
	height: 416px;
	display: flex;
	flex-direction: column;
	background-color: #fff;

	@include padMobile() {
		position: fixed;
		width: 100vw;
		padding: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}
}

.change_website_header {
	display: none;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $borderColor1;
	@include font16;
	font-weight: 400;
	justify-content: space-between;
	align-items: center;
	color: $textColor1;
	padding: 12px 16px;
	position: relative;

	@include padMobile() {
		display: flex;
	}

	.iconfont_close {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0 16px;
		color: $textColor2;
		font-size: 16px;
		cursor: pointer;
	}
}

.current_country {
	padding: 0;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	// margin-bottom: 12px;

	@include padMobile() {
		padding: 12px 16px;
	}

	.iconfont {
		font-size: 12px;
		line-height: 1;
		margin-right: 8px;
		color: #10a300;
	}

	.country_info {
		@include font14;
		font-weight: 600;
		color: $textColor1;
		@include padMobile() {
			@include font14;
		}
	}
}

.search_box {
	// padding-top: 12px;
	position: relative;
	flex-shrink: 0;
	// border-top: 1px solid #e5e5e5;

	@include padMobile() {
		padding: 0 16px;
		margin-top: 0;
		margin-bottom: 12px;
	}

	input {
		width: 100%;
		height: 34px;
		padding: 8px 36px 8px 12px;
		// background-color: $bgColor3;
		@include font12;
		&::placeholder {
			@include font12;
			color: #cccccc;
		}
		&:focus::placeholder {
			color: transparent;
		}
	}

	.iconfont_search {
		display: inline-block;
		width: 16px;
		height: 16px;
		font-size: 12px;
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		cursor: pointer;
		text-align: center;
		line-height: 16px;
		color: $textColor2;

		&:hover {
			color: $textColor1;
			transition: all 0.3s;
		}
		@include mobile {
			left: 28px;
		}
	}

	.iconfont_clear {
		display: inline-block;
		width: 16px;
		height: 16px;
		font-size: 12px;
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		cursor: pointer;
		text-align: center;
		line-height: 16px;
		color: $textColor2;

		&:hover {
			color: $textColor1;
			transition: all 0.3s;
		}
		@include mobile {
			right: 24px;
		}
	}
}

.country_list_box {
	flex: 1 1 auto;
	overflow-y: auto;
	padding-right: 20px;
	margin-right: -20px;
	> p {
		color: $textColor2;
		@include font12;
		padding: 8px 12px;
	}

	@include padMobile() {
		padding: 0 16px 0 16px;
		margin-right: 0;
	}
}

.country_list {
	li {
		padding: 0;
		@include font12;

		@include padMobile() {
			@include font14;
		}

		> div {
			padding: 8px 12px;

			@include padMobile() {
				padding: 12px;
			}

			> span {
				padding-bottom: 4px;
				cursor: pointer;

				@include padMobile() {
					color: $textColor5;
				}

				&:hover {
					text-decoration: underline;
					color: $textColor5;

					.country_name {
						color: $textColor5;
					}
				}
			}
		}

		.country_lang_symbol {
			color: $textColor5;
			cursor: pointer;
			text-decoration-color: $textColor5;
			&:hover {
				text-decoration: underline;
			}
			@include padMobile() {
				text-decoration: none;
			}
		}

		&.item_line {
			border-bottom: 1px solid $borderColor2;
		}

		&:focus-visible {
			outline-offset: -2px;
			border-radius: 3px;
		}
	}
}
.current_line {
	margin: 12px 0;
	border-bottom: 1px solid #e5e5e5;
}
.change_website_wrap_m {
	> .current_country {
		display: none;
	}
	.current_line {
		width: calc(100% - 32px);
		margin: 0 auto 12px;
	}
	.change_website_header {
		border-bottom: none;
		padding: 16px;
	}
	.current_country {
		padding: 0;
	}
}
</style>
