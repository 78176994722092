<template>
	<p class="app_download_title">{{ localeLang("footer.footerSubscribe.Download_FS_APP") }}</p>
	<div class="app_box">
		<template v-if="!isCn">
			<a
				class="app_item"
				:href="localeLang('footer.footerSubscribe.android_app_link')"
				target="_blank"
				@click="localeLang('footer.footerSubscribe.android_app_link')">
				<img src="https://resource.fs.com/mall/generalImg/202403271936115liwdb.png" alt="" />
			</a>
			<a
				class="app_item"
				:href="localeLang('footer.footerSubscribe.ios_app_link')"
				target="_blank"
				@click="gaPoint(localeLang('footer.footerSubscribe.ios_app_link'))">
				<img src="https://resource.fs.com/mall/generalImg/20240327193611q03isg.png" alt="" />
			</a>
		</template>
		<template v-else>
			<a
				class="app_item"
				href="https://cn-airplan.fs.com/YX_0evWtMz4373v/app_version_management_download.php?action=downloadFile&file=674"
				target="_blank"
				@click="
					gaPoint(
						'https://cn-airplan.fs.com/YX_0evWtMz4373v/app_version_management_download.php?action=downloadFile&file=674'
					)
				">
				<img src="https://resource.fs.com/mall/generalImg/2024053112070640o3cs.png" alt="" />
			</a>
			<a
				class="app_item"
				href="https://apps.apple.com/cn/app/%E9%A3%9E%E9%80%9F-fs-%E9%AB%98%E9%80%9F%E9%80%9A%E4%BF%A1%E4%B8%93%E5%AE%B6/id1551439855"
				target="_blank"
				@click="
					gaPoint(
						'https://apps.apple.com/cn/app/%E9%A3%9E%E9%80%9F-fs-%E9%AB%98%E9%80%9F%E9%80%9A%E4%BF%A1%E4%B8%93%E5%AE%B6/id1551439855'
					)
				">
				<img src="https://resource.fs.com/mall/generalImg/20240327193611q03isg.png" alt="" />
			</a>
		</template>
	</div>
</template>

<script setup lang="ts">
const websiteStore = useWebsiteStore();
const { isCn } = storeToRefs(websiteStore);
const localeLang = useLocaleLang();
const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const gaPoint = function (url: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "bottom_navigation",
			eventLabel: url,
			nonInteraction: false
		});
	}
};
</script>

<style lang="scss" scoped>
.app_download_title {
	font-weight: 600;
	@include font14;
	margin-bottom: 10px;
	margin-top: 20px;
}
.app_box {
	display: flex;
	margin-bottom: 40px;
	@include mobile() {
		justify-content: center;
	}
	.app_item {
		display: inline-block;
		margin-right: 12px;
		height: 30px;
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
@include mobile() {
	.app_download_title {
		display: none;
	}
	.app_box {
		margin: 20px 0;
	}
}
</style>
