import type { SocialLinkProps } from "@/types/interfaceSocialLink";
const socialLink: SocialLinkProps[] = [
	{
		title: "FS Linkedin",
		href: "https://www.linkedin.com/company/fscomglobal/",
		icon: "&#xf150;",
		hover: "#4b4b4d",
		link: {
			au: "https://www.linkedin.com/company/fs-com-au",
			en: "https://www.linkedin.com/company/fscomglobal/",
			de: "https://www.linkedin.com/company/fs-com-deutschland/",
			sg: "https://www.linkedin.com/company/fs-com-sg/",
			fr: "https://www.linkedin.com/company/fscom-france/",
			es: "https://www.linkedin.com/company/fscom-spain/",
			it: "https://www.linkedin.com/company/fscom-italy/",
			uk: "https://www.linkedin.com/company/fs-com-uk/"
		}
	},
	{
		title: "FS Youtube",
		href: "https://www.youtube.com/c/FSCOM_official",
		icon: "&#xf146;",
		hover: "#4b4b4d",
		link: {
			en: "https://www.youtube.com/c/FSCOM_official",
			jp: "https://www.youtube.com/@FS.com.japan."
		}
	},
	{
		title: "FS Facebook",
		href: "https://www.facebook.com/FS.com.global",
		icon: "&#xf148;",
		hover: "#4b4b4d",
		link: {
			en: "https://www.facebook.com/FS.com.global",
			uk: "https://www.facebook.com/FS.com.UK",
			au: "https://www.facebook.com/FS.com.AU/",
			de: "https://www.facebook.com/FS.com.GmbH/",
			jp: "https://www.facebook.com/FS.com.JP/",
			fr: "https://www.facebook.com/FS.com.FR/",
			ca: "https://www.facebook.com/FS.com.global",
			it: "https://www.facebook.com/FS.com.Italy/",
			es: "https://www.facebook.com/FS.comSpain",
			sg: "https://www.facebook.com/FS.com.SG",
			"de-en": "https://www.facebook.com/FS.com.global",
			ru: "https://www.facebook.com/FS.com.global",
			mx: "https://www.facebook.com/FS.com.global"
		}
	},
	{
		title: "FS Twitter",
		href: "https://twitter.com/FScom_inc",
		icon: "&#xe626;",
		hover: "#4b4b4d",
		link: {
			en: "https://twitter.com/FScom_inc",
			uk: "https://twitter.com/FScom_UK",
			au: "https://twitter.com/FScom_Australia",
			de: "https://twitter.com/FScom_Germany",
			jp: "https://twitter.com/FScom_Japan",
			fr: "https://twitter.com/FScom_France",
			ca: "https://twitter.com/FScom_inc",
			it: "https://twitter.com/FScom_Italy",
			es: "https://twitter.com/FScom_Spain",
			sg: "https://twitter.com/FScom_Singapore",
			"de-en": "https://twitter.com/FScom_inc",
			ru: "https://twitter.com/FScom_inc",
			mx: "https://twitter.com/FScom_inc"
		}
	},
	{
		title: "FS Instagram",
		href: "https://www.instagram.com/fs.com.global/",
		icon: "&#xf149;",
		hover: "#4b4b4d",
		link: {
			en: "https://www.instagram.com/fs.com.global/"
		}
	},
	{
		title: "FS Reddit",
		href: "https://www.reddit.com/r/FS_COM/",
		icon: "&#xf196;",
		hover: "#4b4b4d",
		link: {
			en: "https://www.reddit.com/r/FS_COM/"
		}
	}
];

export default socialLink;

export const socialLinkNav: SocialLinkProps[] = [
	{
		title: "FS Linkedin",
		href: "https://www.linkedin.com/company/fscomglobal/",
		icon: "&#xe62f;",
		hover: "#707070",
		link: {
			au: "https://www.linkedin.com/company/fs-com-au",
			en: "https://www.linkedin.com/company/fscomglobal/",
			sg: "https://www.linkedin.com/company/fs-com-sg/",
			uk: "https://www.linkedin.com/company/fs-com-uk/"
		}
	},
	{
		title: "FS Youtube",
		href: "https://www.youtube.com/c/FSCOM_official",
		icon: "&#xe62c;",
		hover: "#707070",
		link: {
			en: "https://www.youtube.com/c/FSCOM_official",
			jp: "https://www.youtube.com/@FS.com.japan."
		}
	},
	{
		title: "FS Facebook",
		href: "https://www.facebook.com/FS.com.global",
		icon: "&#xe62d;",
		hover: "#707070",
		link: {
			en: "https://www.facebook.com/FS.com.global",
			uk: "https://www.facebook.com/FS.com.UK",
			au: "https://www.facebook.com/FS.com.AU/",
			de: "https://www.facebook.com/FS.com.GmbH/",
			jp: "https://www.facebook.com/FS.com.JP/",
			fr: "https://www.facebook.com/FS.com.FR/",
			ca: "https://www.facebook.com/FS.com.global",
			it: "https://www.facebook.com/FS.com.Italy/",
			es: "https://www.facebook.com/FS.comSpain",
			sg: "https://www.facebook.com/FS.com.SG",
			"de-en": "https://www.facebook.com/FS.com.global",
			ru: "https://www.facebook.com/FS.com.global",
			mx: "https://www.facebook.com/FS.com.global"
		}
	},
	{
		title: "FS Twitter",
		href: "https://twitter.com/FScom_inc",
		icon: "&#xe633;",
		hover: "#707070",
		link: {
			en: "https://twitter.com/FScom_inc",
			uk: "https://twitter.com/FScom_UK",
			au: "https://twitter.com/FScom_Australia",
			de: "https://twitter.com/FScom_Germany",
			jp: "https://twitter.com/FScom_Japan",
			fr: "https://twitter.com/FScom_France",
			ca: "https://twitter.com/FScom_inc",
			it: "https://twitter.com/FScom_Italy",
			es: "https://twitter.com/FScom_Spain",
			sg: "https://twitter.com/FScom_Singapore",
			"de-en": "https://twitter.com/FScom_inc",
			ru: "https://twitter.com/FScom_inc",
			mx: "https://twitter.com/FScom_inc"
		}
	},
	{
		title: "FS Instagram",
		href: "https://www.instagram.com/fs.com.global/",
		icon: "&#xe62a;",
		hover: "#707070",
		link: {
			en: "https://www.instagram.com/fs.com.global/"
		}
	},
	{
		title: "FS Reddit",
		href: "https://www.reddit.com/r/FS_COM/",
		icon: "&#xe621;",
		hover: "#707070",
		link: {
			en: "https://www.reddit.com/r/FS_COM/"
		}
	}
];
