<template>
	<section class="subscribe_box">
		<div class="logo_box">
			<a tabindex="0" :class="['logo', { logo_cn: isCn }]" :href="localeLink('/')" aria-label="FS">
				<img
					:src="
						isCn
							? 'https://resource.fs.com/mall/generalImg/20230703110143tw3bnh.png'
							: 'https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo_footer.svg'
					"
					alt="FS LOGO" />
			</a>
		</div>
		<p class="subscribe_info">{{ localeLang("footer.footerSubscribe.Follow_us_and_the_future_of_network") }}</p>
		<form class="form" @submit.prevent="submit">
			<div class="form_item">
				<FsInput
					v-model="email"
					:placeholder="localeLang('footer.footerSubscribe.Enter_your_email')"
					@input="input"
					@blur="gaPoint" />
				<!-- <input v-model="email" :placeholder="localeLang('footer.footerSubscribe.Enter_your_email')" @input="input" /> -->
				<FsButton type="red" native-type="submit" :loading="loading">{{
					localeLang("footer.footerSubscribe.Subscribe")
				}}</FsButton>
			</div>

			<ValidateError :error="error_info" />
			<div v-if="success" class="error_info" :class="{ error_info_success: success }">{{ success_info }}</div>
		</form>
		<!-- <div class="agree_box">
			<FsCheckbox v-model="check"></FsCheckbox>
			<p>
				I agree to FS's <a href="https://www.fs.com/policies/privacy_policy.html">Privacy Policy</a> and
				<a href="https://www.fs.com/policies/terms_of_use.html">Term of Use</a>.
			</p>
		</div>

		<ValidateError v-if="!check" error="Please make sure you agree to our Privacy Policy and Terms of Use." /> -->
	</section>
</template>

<script setup lang="ts">
import { FsButton, FsInput, FsCheckbox } from "fs-design";
import ValidateError from "./ValidateError.vue";

const useDevice = useDeviceStore();
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const localeLang = useLocaleLang();
const localeLink = useLocaleLink();
const { website, iso_code, isCn, isJp, isJpEn, isRussia } = storeToRefs(websiteStore);

const { screenWidth } = storeToRefs(useDevice);

const { ausAcknowledges } = storeToRefs(headerStore);

const email = ref("");
const loading = ref(false);
const check = ref(true);
const success = ref(false);
const error_info = ref("");
const success_info = ref("");

const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const gaPoint = function () {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "bottom_navigation",
			eventLabel: "Subscribe Input",
			nonInteraction: false
		});
	}
};
const gaPointSubmit = function (status: boolean) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "bottom_navigation",
			eventLabel: status,
			nonInteraction: false
		});
	}
};

const input = () => {
	success.value = false;
	// if (!email.value.replace(/\s+/g, "")) {
	// 	error_info.value = "Please enter your email address.";
	// 	return;
	// }
	if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value.replace(/\s+/g, ""))) {
		error_info.value = "";
		// return;
	}
	// error_info.value = "";
};
const { getRecaptchaToken } = useGrecaptcha();

const submit = async () => {
	if (loading.value) {
		return;
	}
	if (!email.value.replace(/\s+/g, "")) {
		success.value = false;
		error_info.value = localeLang("footer.footerSubscribe.Please_enter_your_email_address");
		return;
	}
	if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value.replace(/\s+/g, ""))) {
		success.value = false;
		error_info.value = localeLang("footer.footerSubscribe.Please_enter_a_valid_email_address");
		return;
	}
	if (!check.value) {
		return;
	}
	loading.value = true;
	const { recaptchaTp = false, headers = {} } = await getRecaptchaToken();
	if (!recaptchaTp) {
		loading.value = false;
		return;
	}
	const { data, error } = await useRequest.post("/api/subscribe", { data: { email: email.value }, headers });
	loading.value = false;
	if (data.value && data.value.data) {
		success.value = true;
		success_info.value = localeLang("footer.footerSubscribe.Welcome_to_FS_You_be_sent");
		gaPointSubmit(true);
	}
	// console.log(error.value.data);
	if (error.value && error.value.data && error.value.data.errors && error.value.data.errors.email) {
		error_info.value = error.value.data.errors.email.join(" ");
		gaPointSubmit(false);
	}
};
</script>

<style lang="scss" scoped>
.subscribe_box {
	width: 240px;
	@include mobile() {
		padding: 32px 16px 0;
		margin: 0;
		width: 100%;
	}
}

.logo_box {
	margin-bottom: 40px;
	@include mobile() {
		margin-bottom: 24px;
		display: none;
	}

	.logo {
		display: block;
		width: 85px;
		@include mobile() {
			width: 76px;
		}
		&.logo_cn {
			width: 135px;
		}
		> img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
	}
}
.form {
	margin-bottom: 12px;
	@include mobile() {
		margin-bottom: 8px;
	}
}
.form_item {
	display: flex;
	input {
		border-radius: 4px 0 0 4px;
	}
	:deep(.fs-input) {
		&.is_focus {
			.fs-input__wrapper {
				border-radius: 4px 0 0 4px;
			}
		}
		.fs-input__inner {
			&:hover {
				cursor: text;
			}
		}
		.fs-input__wrapper {
			border-radius: 4px 0 0 4px;
			padding: 5px 12px;
			font-size: 12px;
			line-height: 20px;
			@include mobile() {
				height: 42px;
				@include font13;
			}
		}
	}
	:deep(.fs-button) {
		margin-left: -1px;
		border-radius: 0 4px 4px 0;
		font-size: 12px;
		line-height: 20px;
		height: 32px;
		padding: 0 12px;

		@include mobile() {
			height: 42px;
			padding: 0 16px;
			@include font14;
		}
	}
}
.subscribe_info {
	@include font14;
	color: $textColor1;
	margin-bottom: 12px;
	max-width: 276px;
	@include mobile() {
		max-width: 100%;
	}
}

.agree_box {
	display: flex;
	align-items: flex-start;

	:deep(.fs-checkbox) {
		margin-right: 0;
		height: auto;
	}

	p {
		padding-top: 1px;
		@include font12;
		color: $textColor2;

		a {
			color: $textColor1;
			text-decoration: underline;

			&:hover {
				color: $textColor2;
			}
		}
	}
}

.error_info {
	@include font13;
	color: #c00000;

	&.error_info_success {
		color: #10a300;
		padding-top: 4px;
	}
}
</style>
