<template>
	<div class="marquee" functional>
		<div class="marquee-wrap">
			<div class="marquee-content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.marquee {
	width: 100%;
	overflow: hidden;
	.marquee-wrap {
		width: 100%;
		animation: marquee-wrap 20s infinite linear;
	}
	.marquee-content {
		float: left;
		@include font12();
		white-space: nowrap;
		min-width: 100%;
		animation: marquee-content 20s infinite linear;
	}
	@keyframes marquee-wrap {
		0%,
		30% {
			transform: translateX(0);
		}
		70%,
		100% {
			transform: translateX(100%);
		}
	}
	@keyframes marquee-content {
		0%,
		30% {
			transform: translateX(0);
		}
		70%,
		100% {
			transform: translateX(-100%);
		}
	}
}
</style>
