<template>
	<div v-if="iso_code === 'FR' || countryPayment[website]" class="footer_certification_box">
		<div class="certification-box">
			<template v-if="iso_code === 'FR'">
				<!-- <a
					v-for="img in certifications[warehouse]['other']"
					:key="img.imgUrl"
					tabindex="0"
					:href="localeLink(img.toUrl)"
					:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
					:aria-label="img.label">
					<img :src="img.imgUrl" :alt="img.label" />
				</a> -->
				<CertificationCtn :data="certifications[warehouse]['other']" />
			</template>
			<template v-else-if="website === 'uk'">
				<div class="uk_local_txt">
					<p>Company Registration Number: 10876330</p>
					<p>VAT Registration Number: GB277384560</p>
				</div>
			</template>
			<template v-else>
				<template v-if="warehouse === 'DE' && ['uk', 'de-en', 'de'].includes(website)">
					<!-- <a
						v-for="img in certifications[warehouse][website]"
						:key="img.imgUrl"
						tabindex="0"
						:href="localeLink(img.toUrl)"
						:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
						:aria-label="img.label">
						<img :src="img.imgUrl" :alt="img.label" />
					</a> -->
					<CertificationCtn :data="certifications[warehouse][website]" />
				</template>
				<template v-else-if="warehouse === 'DE' && !['uk', 'de-en', 'de'].includes(website)">
					<!-- <a
						v-for="img in certifications[warehouse]['other']"
						:key="img.imgUrl"
						tabindex="0"
						:href="localeLink(img.toUrl)"
						:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
						:aria-label="img.label">
						<img :src="img.imgUrl" :alt="img.label" />
					</a> -->
					<CertificationCtn :data="certifications[warehouse]['other']" />
				</template>
				<template v-else>
					<!-- <a
						v-for="img in certifications[warehouse]"
						:key="img.imgUrl"
						tabindex="0"
						:href="localeLink(img.toUrl)"
						:target="img.toUrl === 'javascript:;' ? '_self' : '_blank'"
						:aria-label="img.label">
						<img :src="img.imgUrl" :alt="img.label" />
					</a> -->
					<CertificationCtn :data="certifications[warehouse]" />
				</template>
			</template>
		</div>
		<div v-if="countryPayment.fr && countryPayment.fr.length && iso_code === 'FR'" class="pay_box">
			<img v-for="item in countryPayment.fr" :key="item" :src="paymentIcon[item].imgur" :alt="item" />
		</div>
		<div v-if="iso_code !== 'FR' && countryPayment[website]" class="pay_box pay_icon">
			<a
				v-for="(item, i) in countryPayment[website]"
				:key="i"
				tabindex="0"
				:href="paymentIcon[item].href"
				:target="paymentIcon[item].href === 'javascript:;' ? '_self' : '_blank'"
				:class="{ uk_a: website == 'uk' }">
				<img :src="paymentIcon[item].imgur" alt="" />
			</a>
		</div>
	</div>
</template>

<script setup lang="ts">
import CertificationCtn from "./CertificationCtn.vue";
import { certifications, countryPayment, paymentIcon } from "@/constants/certifications";
const websiteStore = useWebsiteStore();
const localeLink = useLocaleLink();

const { website, iso_code, warehouse } = storeToRefs(websiteStore);
</script>

<style lang="scss" scoped>
.footer_certification_box {
	margin-top: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.certification-box {
		display: flex;
		.uk_local_txt {
			@include font12;
			color: $textColor3;
		}

		a {
			margin-right: 16px;

			&:last-of-type {
				margin-right: 0;
			}

			img {
				width: auto;
				max-height: 28px;
			}
		}
	}

	.pay_box {
		display: flex;
		align-items: flex-end;

		img {
			margin-right: 16px;

			&:last-of-type {
				margin-right: 0;
			}
		}

		&.pay_icon {
			align-items: center;

			> a {
				margin-left: 20px;
				&:first-child {
					margin-left: 0;
				}
				&:last-of-type {
					img {
						margin-right: 0;
					}
				}

				&.uk_a {
					padding-top: 4px;
					display: block;

					img {
						display: block;
					}
				}
			}
		}
	}
}
</style>
