<!-- eslint-disable vue/no-template-shadow -->
<template>
	<!-- <div class="header_ctn_left"> -->
	<a :href="localeLink('/')" class="logo" tabindex="0" aria-label="FS logo" :class="{ logo_cn: website === 'cn' }"></a>
	<div class="menu_list" @keyup.esc="menuListMouseleave" @mouseleave="menuListMouseleave">
		<template v-for="(item, index) in newMenuData" :key="item.title">
			<div class="menu_item_blank"></div>
			<div class="menu_item" @mouseenter="menuMouseenter(index + 1)">
				<div
					class="menu_label"
					:class="{ isActive: menuActive.menuItemActive === index + 1 }"
					tabindex="0"
					@keyup.enter="menuMouseenter(index + 1)">
					{{ index > 1 ? item.nav : item.title }}
				</div>

				<ClientOnly>
					<!--All products-->
					<div
						v-show="menuActive.menuItemActive === index + 1"
						class="menu_more_wrap"
						:class="{
							menu_more_category: index === 0,
							menu_more_solution: index === 1,
							menu_more_service: index === 2,
							menu_more_resources: index === 3,
							menu_more_about: index === 4
						}">
						<template v-if="index === 0">
							<div class="menu_more_left">
								<section class="left_list">
									<div
										v-for="(p, i) in item.data"
										:key="p.id"
										class="left_list_item"
										:class="{ left_list_item_active: i === menuActive.categoryActive }"
										@mouseenter="menuleftMouseenter('categoryActive', i)">
										<a tabindex="0" :href="localeLink(p.url)" @keyup.enter="menuleftMouseenter('categoryActive', i)">
											<span class="category_name">
												{{ p.name ? p.name : "" }}
											</span>
										</a>
									</div>
									<div
										class="list_active_block"
										:style="{
											top: `${offsetTop}px`,
											height: `${scrollHeight}px`
										}"></div>
								</section>
							</div>

							<div class="menu_more_right">
								<div
									v-show="
										item.data &&
										item.data.length &&
										item.data[menuActive.categoryActive] &&
										item.data[menuActive.categoryActive].children &&
										item.data[menuActive.categoryActive].children.length
									"
									class="category_list">
									<div
										v-for="c in item.data[menuActive.categoryActive].children"
										:key="c.name"
										class="category_item_box">
										<div class="category_item">
											<span
												class="category_img"
												:alt="c.name"
												:style="{ backgroundImage: 'url(' + c.image + ')' }"></span>
											<div class="category_list">
												<a :href="localeLink(c.url)" tabindex="0" class="category_list_title">
													<span>{{ c.name }}</span>
												</a>
												<div
													v-show="c.children && c.children.length"
													class="category_list_children category_list_children_nav">
													<p v-for="d in c.children" :key="d.name" class="category_list_children_item">
														<a
															tabindex="0"
															:class="{
																item_info_hot: d.is_has_hot_products,
																item_info_new: d.is_has_new_products
															}"
															:title="d.name"
															:href="localeLink(d.url)"
															v-html="d.name">
														</a>
														<span
															v-if="d.is_has_new_products || d.is_has_hot_products"
															class="category_tag"
															:class="{
																category_tag_hot: d.is_has_hot_products,
																category_tag_new: d.is_has_new_products
															}">
															<span v-if="d.is_has_new_products">{{ localeLang("header.textNew") }}</span>

															<span v-if="d.is_has_hot_products">
																{{ localeLang("header.textHot") }}
															</span>
														</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<!-- Solutions -->
						<template v-else-if="index === 1">
							<div class="menu_more_left">
								<section class="left_list">
									<div
										v-for="(p, i) in item?.solution_data_new?.list"
										:key="p.title"
										class="left_list_item left_list_item_solutions"
										:class="{ left_list_item_active: i === menuActive.solutionActive }"
										@mouseenter="menuleftMouseenter('solutionActive', i)">
										<a tabindex="0" href="javascript:;">
											<span class="category_name" @keyup.enter="menuleftMouseenter('solutionActive', i)">
												{{ p.title }}
											</span>
										</a>
									</div>
									<div
										class="list_active_block"
										:style="{
											top: `${offsetTop}px`,
											height: `${scrollHeight}px`
										}"></div>
								</section>
							</div>

							<div class="menu_more_right">
								<div
									v-show="
										item.solution_data_new &&
										item.solution_data_new.list &&
										item.solution_data_new.list.length &&
										item.solution_data_new.list[menuActive.solutionActive]
									"
									class="solution_list">
									<h3 class="solution_top_title">
										<a
											:href="localeLink(item.solution_data_new.list[menuActive.solutionActive].url)"
											@click="gaPoint(localeLink(item.solution_data_new.list[menuActive.solutionActive].url), 0)">
											{{ item.solution_data_new.list[menuActive.solutionActive].title }}
										</a>
									</h3>
									<p class="solution_top_title_desc">
										{{ item.solution_data_new.list[menuActive.solutionActive].desc }}
										<a
											:href="localeLink(item.solution_data_new.list[menuActive.solutionActive].url)"
											@click="gaPoint(localeLink(item.solution_data_new.list[menuActive.solutionActive].url), 0)"
											>{{ localeLang("header.See_more") }}</a
										>
									</p>
									<div class="solution_list_content">
										<div
											v-for="(t, i) in item.solution_data_new.list[menuActive.solutionActive].list"
											:key="i"
											class="solution_list_item">
											<a
												class="solution_list_item_img"
												:href="localeLink(item.solution_data_new.list[menuActive.solutionActive].url)"
												@click="
													gaPoint(
														`${localeLink(item.solution_data_new.list[menuActive.solutionActive].url)}_${item.solution_data_new.list[menuActive.solutionActive].title}`,
														0
													)
												">
												<img :src="t.img" alt="" />
											</a>
											<div class="solution_list_item_txt">
												<a :href="localeLink(t.url)">{{ t.title }}</a>
												<p>{{ t.desc }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-else-if="index > 1">
							<div class="service_box">
								<div class="service_text">
									<div class="service_title">{{ item?.title }}</div>
									<div class="service_desc">
										<div class="service_msg">
											{{ item?.desc }}
										</div>
										<div class="service_list">
											<div
												v-for="(classitem, classindex) in item?.data"
												:key="classitem.title"
												class="service_list_item">
												<div class="name">{{ classitem.title }}</div>
												<div class="link_list">
													<template v-if="index === 4 && classindex === 2">
														<div class="share_box">
															<template v-if="isCn">
																<img
																	src="https://resource.fs.com/mall/generalImg/20240704151602um8uhe.jpeg"
																	width="80"
																	height="80"
																	alt="" />
																<!-- <FsTooltip placement="right" trigger="hover" :close-delay="0" :teleport="false">
																	<img
																		src="https://resource.fs.com/mall/generalImg/202407021752256ol7g6.jpeg"
																		width="60"
																		height="60"
																		alt="" />
																	<template #content>
																		<img
																			src="https://img-en.fs.com/zh/includes/templates/fiberstore/images/feisu-footer-wx.png"
																			width="170"
																			height="170"
																			alt="" />
																	</template> 
																</FsTooltip> -->
															</template>
															<template v-else>
																<a
																	v-for="i in socialLinkNav"
																	:key="i.title"
																	class="iconfont iconfont-share"
																	target="_blank"
																	tabindex="0"
																	:href="localeLink(i.link[website] || i.link.en)"
																	:title="i.title"
																	:aria-label="i.title"
																	v-html="i.icon"></a>
															</template>
														</div>
													</template>
													<template v-else>
														<div v-for="(t, i) in classitem.list" :key="i" class="link_item">
															<template v-if="index === 4 && classindex === 1">
																<a v-if="i === 0" id="service_chat" :href="'mailto:' + t.title">{{ t.title }}</a>
																<a v-else-if="i === 1" id="bdtj_lxdh" :href="'tel:' + '' + t.title">{{ t.title }}</a>
																<a
																	v-else
																	:href="t.url"
																	:target="t.url.includes('community.fs.com') ? '_blank' : '_self'"
																	@click="gaPoint(t.url, index)"
																	>{{ t.title }}</a
																>
															</template>
															<template v-else>
																<a
																	:href="t.url"
																	:target="t.url.includes('community.fs.com') ? '_blank' : '_self'"
																	@click="gaPoint(t.url, index)"
																	>{{ t.title }}</a
																>
															</template>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="item?.image" class="line"></div>
								<div class="service_img">
									<div class="img_box">
										<a class="img_link" :href="item.image_data?.title ? `javascript:;` : item.image_data?.url">
											<img :src="item?.image" />
										</a>
										<div v-if="item.image_data?.title" class="text_box">
											<div class="top" :style="{ maxWidth: index === 3 ? '240px' : '240px' }">
												<div v-if="item.image_data?.title" class="text_title" v-html="item.image_data.title"></div>
												<div v-if="item.image_data?.desc" class="text_desc" v-html="item.image_data.desc"></div>
											</div>
											<FsButton type="red" class="btn" @click="toClickBtn(item.image_data.url, index)">{{
												item.image_data.button
											}}</FsButton>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</ClientOnly>
			</div>
		</template>
		<!-- <div class="menu_list_blank"></div> -->
	</div>
	<clientOnly>
		<Teleport to="body">
			<transition name="fade">
				<div v-if="menuActive.menuItemActive" class="header_mask" :style="{ top: `${pc_header_height}px` }"></div>
			</transition>
		</Teleport>
	</clientOnly>
	<!-- </div> -->
</template>
<script setup lang="ts">
import { FsButton, FsTooltip } from "fs-design";

import type { MenuActive } from "@/component/FsHeader/header.types";
import { socialLinkNav } from "@/constants/socialLink";
import { preloadImages } from "@/utils/utils";
const websiteStore = useWebsiteStore();
const { website, isCn } = storeToRefs(websiteStore);

const headerStore = useHeaderStore();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();

const { menuData, pc_header_height, classifyNew } = storeToRefs(headerStore);
const newMenuData = ref(menuData.value.slice(0, 2).concat(classifyNew.value));

const menuActive = ref<MenuActive>({
	menuItemActive: 0,
	categoryActive: 0,
	solutionActive: 0
});

function menuListMouseleave() {
	menuActive.value.menuItemActive = 0;
}
// 动态设置导航栏分类选项高度
const offsetTop = ref(0);
const scrollHeight = ref(0);

function menuleftMouseenter(attr: "categoryActive" | "solutionActive", index: number): void {
	menuActive.value[attr] = index;
	if (attr === "categoryActive") {
		if (
			document &&
			document.querySelectorAll(".left_list_item") &&
			document.querySelectorAll(".left_list_item").length
		) {
			offsetTop.value = document.querySelectorAll(".left_list_item")[index].offsetTop;
			scrollHeight.value = document.querySelectorAll(".left_list_item")[index].scrollHeight;
		}
	} else {
		if (
			document &&
			document.querySelectorAll(".left_list_item_solutions") &&
			document.querySelectorAll(".left_list_item_solutions").length
		) {
			offsetTop.value = document.querySelectorAll(".left_list_item_solutions")[index].offsetTop;
			scrollHeight.value = document.querySelectorAll(".left_list_item_solutions")[index].scrollHeight;
		}
	}
}

function menuMouseenter(i: number): void {
	headerStore.hideSearchBox();
	menuActive.value.menuItemActive = i;
	offsetTop.value = 0;
	scrollHeight.value = 0;
	menuActive.value.categoryActive = 0;
	menuActive.value.solutionActive = 0;
}
// 点击头部图片按钮跳转
const toClickBtn = (url: string, index: number) => {
	const type = url.includes("community.fs.com") ? "_blank" : "_self";
	gaPoint(url, index);
	if (type === "_blank") {
		window.open(url, "_blank");
	} else if (type === "_self") {
		location.href = localeLink(url);
	}
};

const loadSolutionsImage = () => {
	if (menuData.value && menuData.value[1] && menuData.value[1] && menuData.value[1].solution_data_new?.list?.length) {
		const images: string[] = [];
		menuData.value[1].solution_data_new.list.forEach((item: any) => {
			if (item.list && item.list.length) {
				item.list.forEach((citem: any) => {
					images.push(citem.img);
				});
			}
		});
		console.log("11111111111111111111++");
		console.log(images);
		preloadImages(images);
	}
};

const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const gaPoint = function (url: string, index: number) {
	console.log("urlurlurl", url);
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: index === 4 ? "Navigation Module" : pageGroup.value,
			eventAction: "top_navigation",
			eventLabel: url,
			nonInteraction: false
		});
	}
};

onMounted(async () => {
	await nextTick();
	loadSolutionsImage();
});
</script>
<style lang="scss" scoped>
// .header_ctn_left {
// 	display: flex;
// 	align-items: center;
// 	flex: 7;
.logo {
	display: inline-block;
	display: inline-block;
	width: 76px;
	height: 36px;
	margin-right: 0;
	@include bgContain("https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo.svg");
	flex-shrink: 0;
	&.logo_cn {
		background-image: url("https://resource.fs.com/mall/generalImg/20230703110143tw3bnh.png");
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		width: 135px;
	}
}

.menu_list {
	flex: 1;
	display: flex;
	align-items: center;
	.menu_item_blank {
		// flex: 1;
		// min-width: 18px;
		width: 48px;
		&:first-child {
			// display: none;
			width: 40px;
		}
	}
	.menu_list_blank {
		// flex: 2;
		// min-width: 18px;
		// @media (max-width: 1200px) {
		// 	flex: 1;
		// }
	}
	.menu_item {
		flex-shrink: 0;
		.menu_label {
			display: flex;
			align-items: center;
			height: 69px;
			cursor: pointer;
			@include font14;
			position: relative;
			&::after {
				@include redUnderline;
				bottom: 0px;
				z-index: 1;
			}
			position: relative;
			&.isActive {
				// font-weight: 600;
			}
			&.isActive::after {
				opacity: 1;
			}
			&:focus-visible {
				@include focusVisibleIn;
			}
		}
	}
}

.menu_more_wrap {
	width: calc(100% - 64px);
	position: absolute;
	top: 69px;
	left: 50%;
	transform: translateX(-50%);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	overflow: hidden;
	background: transparent;
	transition: all 130ms ease-in-out;
	z-index: 110;

	&.menu_more_category {
		display: flex;
		align-items: stretch;

		.menu_more_right {
			padding: 32px 40px 40px;

			@media (max-width: 1420px) {
				padding: 32px 40px 40px;
			}
			.menu_more_left {
				width: 260px;
				padding: 32px 0 30px 20px;
				.left_list_item {
					> span {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						> .iconfont {
							height: 14px;
							font-size: 14px;
							line-height: 14px;
							color: $textColor1;
							opacity: 0;
						}
					}
					&.left_list_item_active {
						> span {
							> .iconfont {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	&.menu_more_solution {
		display: flex;
		align-items: stretch;
	}
	&.menu_more_classify {
		width: calc(100% - 64px);
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 16px;
		background: #fff;
		padding: 32px 220px 32px 116px;
		@media (max-width: 1420px) {
			padding: 32px 123px 32px 116px;
		}

		@media (max-width: 1220px) {
			padding: 32px 123px 32px 92px;
		}
	}
	&.menu_more_resources,
	&.menu_more_service,
	&.menu_more_about {
		padding: 32px 40px 40px;
		background-color: $bgColor6;
		.service_box {
			display: flex;
			padding: 0;
			.service_text {
				flex: 1;
				.service_title {
					@include font16;
					color: $textColor1;
					font-weight: 600;
				}
				.service_desc {
					.service_msg {
						@include font12;
						color: $textColor2;
						margin-top: 8px;
					}
					.service_list {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						grid-gap: 20px 32px;
						margin-top: 24px;
						.service_list_item {
							.name {
								@include font14;
								color: $textColor1;
								font-weight: 600;
							}
							.link_list {
								margin-top: 8px;
								.link_item {
									> a {
										@include font12;
										color: $textColor2;
										&:hover {
											color: $textColor1;
										}
									}
									display: flex;
									margin-top: 4px;
									&:first-child {
										margin-top: 0;
									}
								}
								.share_box {
									display: flex;
									align-items: center;
									.iconfont-share {
										display: flex;
										font-size: 16px;
										line-height: 1;
										color: $textColor2;
										cursor: pointer;
										align-items: center;
										justify-content: center;
										margin-right: 4px;
										border-radius: 4px;
										padding: 8px;
										transition: all 0.3s;
										text-decoration: none;
										&:hover {
											color: $textColor3;
											background-color: $bgColor2;
										}
										&:last-child {
											margin-right: 0;
										}
									}
								}
							}
						}
					}
				}
			}
			.line {
				width: 1px;
				height: initial;
				margin: 32px 32px 0;
				background-color: $bgColor7;
			}
			.service_img {
				overflow: hidden;
				.img_box {
					position: relative;
					max-width: 360px;
					margin-top: 32px;
					.img_link {
						> img {
							width: 100%;
							border-radius: 8px;
						}
					}

					.text_box {
						position: absolute;
						padding: 24px 28px;
						width: 100%;
						height: 100%;
						top: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;
						.top {
							color: $textColor6;
							.text_title {
								@include font20;
								line-height: 28px;
								font-weight: 600;
							}
							.text_desc {
								@include font14;
								margin-top: 4px;
							}
						}
						.btn {
							margin-top: 24px;
							width: max-content;
							border-radius: 9999px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							line-height: 20px;
							height: 32px;
							padding: 0 12px;
							&::before {
								border-radius: 9999px;
							}
						}
					}
				}
			}
		}
	}
	.menu_more_left {
		width: 20%;
		flex-shrink: 0;
		padding: 32px 0 40px 20px;
		background: $bgColor1;
		.left_list {
			position: relative;

			.left_list_item {
				position: relative;
				z-index: 1;
				@include font14;
				background: transparent;
				transition: all 0.3s;
				align-items: center;
				display: flex;
				cursor: pointer;
				> a {
					flex: 1;
					display: block;
					padding: 13px 20px;
					text-decoration: none;
				}
				.category_name {
					line-height: 22px;
					padding-bottom: 0;
					position: relative;
					cursor: pointer;
				}

				.category_name,
				.solution_name {
					// flex: 1 1 auto;
					color: $textColor1;
					text-decoration: none;
					display: inline-block;
				}
				&:hover {
					.category_name {
						&::after {
							@include redUnderline;
							opacity: 1;
							bottom: -2px;
						}
					}
				}
				&.left_list_item_active {
					.category_name {
						position: relative;
						// font-weight: 600;
						&::after {
							@include redUnderline;
							bottom: -2px;
							opacity: 1;
						}
					}
				}
				&.left_list_item_solutions {
					cursor: pointer;
					> a {
						cursor: pointer;
						> .category_name {
							cursor: pointer;
						}
					}
				}
			}

			.list_active_block {
				width: 100%;
				min-height: 48px;
				position: absolute;
				left: 0;
				border-radius: 3px 0 0 3px;
				background: #fff;
			}
		}
	}
	.menu_more_right {
		position: relative;
		padding: 32px 40px 40px;
		flex: 1 1 auto;
		background: #fff;

		@media (max-width: 1420px) {
			padding: 32px 24px;
		}

		@media (max-width: 1220px) {
			padding: 32px 24px;
		}

		.category_list {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 20px;
			grid-row-gap: 20px;

			.category_item_box {
				min-width: 0;

				.category_item {
					display: flex;
					flex-direction: column;
					.category_img {
						display: inline-block;
						width: 60px;
						height: 60px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100% 100%;
						flex-shrink: 0;
						margin-bottom: 4px;

						@media (max-width: 1220px) {
							display: none;
						}
					}

					.category_list {
						flex: 1 1 auto;
						display: block;

						color: $textColor1;

						.category_list_title {
							display: inline-block;
							margin-bottom: 8px;
							@include font14;
							&:hover {
								text-decoration: none;
							}
							span {
								font-size: 14px;
								color: $textColor1;
								font-weight: 600;
								line-height: 22px;
								&:hover {
									@include fsUnderline;
								}
							}
						}

						.category_list_children_other {
							width: 100%;

							.category_list_children_item {
								margin-bottom: 6px;
								@include font12;
								width: 100%;
								// overflow: hidden;
								display: flex;
								align-items: center;
								padding-bottom: 3px;

								&:last-child {
									margin-bottom: 0;
									padding-bottom: 0;
								}
							}
						}

						.category_list_children_nav {
							width: 100%;
							.category_list_children_item {
								width: 100%;
								margin-bottom: 4px;
								display: flex;
								@include font12;

								&:last-child {
									margin-bottom: 0;
								}
								a {
									@include font12;
									color: $textColor2;
									cursor: pointer;
									white-space: nowrap;
									overflow: hidden;

									text-overflow: ellipsis;
									margin-right: 8px;

									position: relative;

									&:hover {
										color: $textColor1;
										text-decoration: underline;
									}
									&.item_info_hot {
										max-width: calc(100% - 21px);
									}

									&.item_info_new {
										max-width: calc(100% - 26px);
									}
								}
								.category_tag {
									font-size: 12px;
									margin: 0;
									&.category_tag_new {
										color: #10a300;
									}
									&.category_tag_hot {
										color: #f56300;
									}
								}
							}
						}
					}
				}
			}
		}
		.solution_list {
			.solution_top_title {
				margin-bottom: 8px;
				display: flex;

				font-weight: 600;

				align-items: center;
				a {
					position: relative;
					@include font16;
					color: $textColor1;
					text-decoration: none;
					&:hover {
						&::after {
							@include redUnderline;
							opacity: 1;
							bottom: -2px;
						}
					}
				}
			}
			.solution_top_title_desc {
				margin-bottom: 24px;
				color: $textColor2;
				@include font12;
			}
			.solution_list_content {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 20px;
				grid-row-gap: 16px;
				.solution_list_item {
					display: flex;
					flex-direction: column;
					.solution_list_item_img {
						img {
							display: block;
							max-width: 100%;
							border-radius: 8px;
							overflow: hidden;
						}
					}
					.solution_list_item_txt {
						a {
							display: block;
							margin-top: 16px;
							margin-bottom: 8px;
							@include font14;
							font-weight: 600;
							color: $textColor1;
							cursor: pointer;
						}
						p {
							margin-top: 8px;
							@include font12;

							color: $textColor2;
						}
					}
				}
			}
		}
	}
	.classify_box {
		.classify_title {
			@include font14;
			color: $textColor1;
			font-weight: 600;
			margin-bottom: 8px;
		}
		.classify_list {
		}
		.classify_item {
			margin-bottom: 4px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				@include font12;
				color: $textColor2;
				cursor: pointer;
				display: inline;
				transition: all 0.3s;
				&:hover {
					color: $textColor1;
				}
			}
		}
	}

	.iconfont_close {
		position: absolute;
		width: 32px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		right: 16px;
		top: 16px;
		font-size: 14px;
		color: $textColor2;
		z-index: 2;

		&:hover {
			color: $textColor1;
			background: rgba($color: #19191a, $alpha: 0.04);
			transition: all 0.3s;
		}
	}
}
// }
.header_ctn_right {
	display: flex;
	align-items: center;
	height: 68px;
	position: relative;
	flex: 6;
	justify-content: flex-end;
}

.header_mask {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	bottom: 0;
	z-index: 99;
	left: 0;
	right: 0;
	background: rgba(51, 51, 51, 0.3);
}
</style>
