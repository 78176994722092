<template>
	<div
		v-if="iso_code === 'AU' && ausAcknowledges.banner"
		class="aus_care_box"
		:style="{
			backgroundImage: `url(${screenWidth <= 768 ? `${ausAcknowledges.banner.pc}` : `${ausAcknowledges.banner.m}`})`
		}">
		<div class="auo_center">
			<img class="icon_left" :src="ausAcknowledges.icon" />
			<p class="txt_right">{{ ausAcknowledges.txt }}</p>
		</div>
	</div>
</template>

<script setup lang="ts">
const useDevice = useDeviceStore();
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();

const { iso_code } = storeToRefs(websiteStore);

const { screenWidth } = storeToRefs(useDevice);

const { ausAcknowledges } = storeToRefs(headerStore);
</script>

<style lang="scss" scoped>
.aus_care_box {
	padding: 20px 0;
	.auo_center {
		display: flex;
		align-items: center;
		@include contentWidth;
		.icon_left {
			width: 40px;
			height: 40px;
			margin-right: 36px;
		}
		.txt_right {
			color: #fff;
			@include font12;
		}
	}
}
</style>
