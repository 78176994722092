<template>
	<!-- <div> -->
	<FsHeader />
	<slot />
	<FsFooter />
	<FsGlobalBtn />
	<CookieTip />
	<GRecaptcha />
	<!-- </div> -->
</template>

<script setup lang="ts">
import FsHeader from "@/component/FsHeader/FsHeader.vue";
import FsFooter from "@/component/FsFooter/FsFooter.vue";
import FsGlobalBtn from "@/component/FsGlobalBtn/FsGlobalBtn.vue";
import CookieTip from "@/component/CookieTip/CookieTip.vue";
import GRecaptcha from "@/component/GRecaptcha/GRecaptcha.vue";

useHead({
	script: [
		{
			type: "text/javascript",
			children: `var __dcid = __dcid || [];
			__dcid.push({"cid":"DigiCertClickID_OsYoXDe1","tag":"OsYoXDe1"});
			(function(){var cid=document.createElement("script");cid.defer=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());`
		}
	]
});
</script>
