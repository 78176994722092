<template>
	<div class="footer_bottom">
		<client-only>
			<div class="left_cr_box">
				<span class="copyright" v-html="right"></span>
				<template v-if="isCn">
					<a :href="localeLink('/policies/report.html')" target="_blank">廉洁举报</a>
					<a :href="localeLink('https://beian.miit.gov.cn')" target="_blank">粤ICP备12032516号</a>
					<a id="service_chat" :href="localeLink('https://mall.jd.com/index-11817598.html?from=pc')" target="_blank"
						>飞速京东旗舰店</a
					>
				</template>
			</div>
		</client-only>
		<div v-if="footerLinks && footerLinks.length" class="policy-box">
			<template v-for="(item, index) in footerLinks" :key="item.text">
				<a tabindex="0" :href="localeLink(item.link)" @click="pointLink(item.text)"> {{ item.text }} </a>
				<span v-if="index !== footerLinks.length - 1" :key="'line' + item.text" class="line"></span>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const localeLink = useLocaleLink();

const { isCn } = storeToRefs(websiteStore);
const { footerLinks, right } = storeToRefs(headerStore);

const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const pointLink = function (txt: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "bottom_navigation",
			eventLabel: txt,
			nonInteraction: false
		});
	}
};
</script>

<style lang="scss" scoped>
.footer_bottom {
	padding: 20px 0 0 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.left_cr_box {
		display: flex;
		align-items: center;
		padding-right: 30px;
		a {
			@include font12;
			color: $textColor2;
			text-decoration: none;
			margin-left: 8px;
		}
	}

	.copyright {
		font-size: 12px;
		color: $textColor2;
	}

	.policy-box {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		.line {
			display: inline-block;
			width: 1px;
			height: 8px;
			background: #ccc;
			margin: 0 20px;
		}
		> a {
			display: flex;
			color: $textColor2;
			align-items: center;
			position: relative;
			@include font12;

			&:last-child {
				.line {
					display: none;
				}
			}

			&:hover {
				color: $textColor1;
			}
		}
	}
}
</style>
