<template>
	<div class="notifications_list">
		<div class="list_top">
			<p class="title">
				<span class="iconfont iconfont_back" @click.stop="hideNotificitons">&#xf048;</span>
				{{ localeLang("header.notifications.title") }}
			</p>
			<div v-if="isLogin" class="handle">
				<span class="label">{{ localeLang("header.notifications.subTitle") }}</span>
				<span class="link" tabindex="0" @click="handleAllAsReadClick">{{
					localeLang("header.notifications.markAllAsRead")
				}}</span>
			</div>
		</div>

		<div v-if="isLogin" class="list_content">
			<div
				v-for="(item, index) in systemMessages"
				:key="index"
				class="item"
				:class="{ unread: item.is_read === 0 }"
				tabindex="0"
				@keyup.enter="handleItemClick(item)"
				@click="handleItemClick(item)">
				<span class="dot"></span>
				<dl class="label">
					<dt>{{ localeLang("header.notifications.reminder") }} {{ item.content }}</dt>
					<dd>{{ item.show_time }}</dd>
				</dl>
				<FsTooltip
					ref="notificationsHandle"
					placement="left-start"
					manual
					:teleport="false"
					:popperContentStyle="{
						padding: '0'
					}">
					<span
						class="iconfont iconfont_handle"
						tabindex="0"
						@keyup.enter.stop="handleClick(index)"
						@click.stop="handleClick(index)"
						>&#xf113;</span
					>
					<template #content>
						<div class="handle_box_popover">
							<p
								tabindex="0"
								@keyup.enter.stop="handlePopoverItemClick(item, index)"
								@click="handlePopoverItemClick(item, index)">
								{{
									item.is_read
										? localeLang("header.notifications.markAsUnRead")
										: localeLang("header.notifications.markAsRead")
								}}
							</p>
							<p
								tabindex="0"
								@keyup.enter.stop="handlePopoverItemDelete(item, index)"
								@click="handlePopoverItemDelete(item, index)">
								{{ localeLang("header.notifications.delete") }}
							</p>
						</div>
					</template>
				</FsTooltip>
			</div>
			<dl class="footer">
				<dt>{{ localeLang("header.notifications.endOfNotifications") }}</dt>
				<dd>{{ localeLang("header.notifications.describe") }}</dd>
			</dl>
		</div>
		<div v-else class="empty">
			<span class="iconfont">&#xf011;</span>
			<p v-html="localeLang('header.notifications.empty').replace('XXX', `/login.html?redirect=${route.fullPath}`)"></p>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FsTooltip } from "fs-design";
import type { Obj } from "@/component/FsHeader/header.types";

const userStore = useUserStore();
const headerStore = useHeaderStore();

const route = useRoute();

const notificationsHandle = ref([]);
const localeLang = useLocaleLang();

const { isLogin, systemMessages } = storeToRefs(userStore);

function notificationPonit(str: string): void {
	if ((window as any).dataLayer) {
		(window as any).dataLayer.push({
			event: "uaEvent",
			// eventCategory: this.pageGroup,
			eventAction: "top_function",
			eventLabel: str,
			nonInteraction: false
		});
	}
}

function hideNotificitons() {
	headerStore.hideMNotifications();
}
function handlePopoverItemClick(item: Obj, index: number) {
	const { system_message_user_id, type, is_read } = item;
	notificationsHandle.value[index].hide();
	fetchHandleRead(is_read, system_message_user_id);
}

async function handlePopoverItemDelete(item: Obj, index: number) {
	const { system_message_user_id, type } = item;
	notificationPonit(`Delete_${type}_${system_message_user_id}`);
	notificationsHandle.value[index].hide();
	const { data, error } = await useRequest.post("/api/message/systemMessageDel", {
		data: {
			system_message_user_id
		}
	});

	if (data && data.value) {
		userStore.getUserInfo();
	}
}

async function fetchHandleRead(is_read: number, system_message_user_id?: number): void {
	const obj = {
		is_read,
		system_message_user_id
	};
	const { data } = await useRequest.post("/api/message/systemMessageRead", { data: obj });

	console.log("4444444");

	console.log(data.value);
	if (data && data.value) {
		userStore.getUserInfo();
	}
}

function handleAllAsReadClick() {
	notificationPonit("Mark all as Read");
	fetchHandleRead(1);
}
function handleClick(index: number) {
	const handles = notificationsHandle.value;
	if (handles && handles.length) {
		handles.forEach(item => {
			item.hide();
		});
		handles[index].show();
	}
}
function handleItemClick(item: Obj): void {
	const { link, system_message_user_id, type, is_read } = item;
	if (is_read === 0) {
		fetchHandleRead(1, system_message_user_id);
	}
	window.open(link);
	notificationPonit(`View Detail_${type}_${system_message_user_id}`);
}

onMounted(() => {
	// 获取搜索历史
});
</script>
<style lang="scss" scoped>
.notifications_list {
	position: relative;
	z-index: 10;
}
.list_top {
	padding: 0 20px;
	@include padMobile() {
		padding: 0 16px;
	}

	.title {
		padding: 20px 0;
		font-weight: 600;
		@include font16();
		@include padMobile() {
			padding: 12px 0;
		}
		.iconfont_back {
			display: none;

			font-size: 12px;
			margin-right: 4px;
			@include padMobile() {
				display: inline-block;
			}
		}
	}
	.handle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.label {
			@include font14();
			font-weight: 600;
		}
		.link {
			@include font12();
			@include blackLink();
		}
	}
}

.list_content {
	overflow-y: auto;
	overflow-y: overlay;
	position: relative;
	max-height: 369px;
	padding: 0 20px 0 20px;
	@include padMobile() {
		max-height: max-content;
	}
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 4px;
	}
	.item {
		display: flex;
		align-items: flex-start;
		padding: 16px 0;
		border-top: 1px solid #e5e5e5;
		cursor: pointer;
		transition: all 0.2s;
		&:focus-visible {
			@include focusVisibleIn(2);
		}
		&:hover {
			background-color: #f7f7f7;
		}
		&:first-child {
			border-top: 0;
		}
		&.unread {
			.dot {
				opacity: 1;
			}
			.label dt {
				font-weight: 600;
			}
		}
		.dot {
			width: 5px;
			height: 5px;
			border-radius: 8px;
			background-color: $bgColor4;
			margin: 7px 4px 0 0;
			opacity: 0;
		}
		.label {
			flex: 1;
			margin-right: 8px;
			dt {
				@include textClampOverflow(3);
				@include font13();
			}
			dd {
				@include font12();
				color: #707070;
				margin-top: 8px;
			}
		}
		.iconfont_handle {
			margin-right: 2px;
			@include font16();
		}
		& + .footer {
			margin-top: 0;
		}
	}
	.footer {
		padding: 16px 0 40px;
		text-align: center;
		border-top: 1px solid #e5e5e5;
		margin-top: 12px;
		dt {
			font-weight: 600;
			@include font14();
		}
		dd {
			@include font12();
			margin-top: 4px;
		}
	}
}
.handle_box_popover {
	> div {
		width: max-content;
	}
	p {
		cursor: pointer;
		min-width: max-content;
		font-size: 13px;
		color: #19191a;
		line-height: 20px;
		padding: 8px 0;
		margin: 0 16px;
		text-align: center;
		// border-bottom: 1px solid #e5e5e5;
		&:focus-visible {
			@include focusVisibleIn;
		}
		&:last-child {
			border-bottom: none;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}

.empty {
	padding: 0 20px 20px;
	display: flex;
	@include font12();
	.iconfont {
		font-size: 14px;
		color: $textColor5;
		margin: 0 4px 0 13px;
	}
}
</style>
