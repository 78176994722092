<template>
	<div v-if="!isShowHoliday" class="m_header_top">
		<div class="nottice_box">
			<div class="notice" @click.stop="noticeClick(notice.url)">
				<span v-if="isCn" class="iconfont">&#xe66c;</span>
				<div class="notice-content" v-html="notice.title"></div>
			</div>
			<!-- <NoticeMarquee>
				
			</NoticeMarquee> -->
		</div>
		<div class="website_box" @click.stop="toogleChangeWebsite">
			<!-- <span class="iconfont iconfont_global">&#xe674;</span> -->
			{{ $refs.tooltipRef }}
			<span class="current_country_info">{{ `${country_name}` }}</span>
			<span class="iconfont iconfont_down" :class="{ iconfont_down_up: changeWebsiteVisible }">&#xf049;</span>
		</div>
		<ClientOnly>
			<Teleport to="body">
				<ChangeWebsite
					v-if="changeWebsiteVisible"
					:search="search"
					@saveSearchValue="saveSearchValue"
					@hideChangeWebsiteTip="toogleChangeWebsite" />
			</Teleport>
		</ClientOnly>
	</div>
</template>
<script setup lang="ts">
import NoticeMarquee from "./NoticeMarquee.vue";
import ChangeWebsite from "./ChangeWebsite.vue";
import fixScroll from "@/utils/fixScroll";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const { country_name, language, currency, symbol, isCn } = storeToRefs(websiteStore);

const { notice, isShowHoliday } = storeToRefs(headerStore);

const changeWebsiteVisible = ref(false);
const localeLink = useLocaleLink();

function toogleChangeWebsite() {
	nextTick(() => {
		changeWebsiteVisible.value = !changeWebsiteVisible.value;
		headerStore.$patch(state => {
			state.m_header_right_show = !changeWebsiteVisible.value;
		});
		if (changeWebsiteVisible.value) {
			headerStore.hideAllSideBar();
			fixScroll.fixed();
		} else {
			fixScroll.unfixed();
		}
	});
}

function noticeClick(url: string) {
	if (url) {
		location.href = localeLink(url);
	}
}
const search = ref("");
function saveSearchValue(val: string) {
	console.log("val", val);
	search.value = val;
}
</script>
<style lang="scss" scoped>
.m_header_top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	background: $bgColor5;
}

.website_box {
	cursor: pointer;
	display: flex;
	align-items: center;
	color: $textColor2;
	.iconfont_global {
		font-size: 18px;
		color: $textColor2;
	}

	.current_country_info {
		font-size: 12px;
		line-height: 20px;
		margin: 0 4px 0 0;
		white-space: nowrap;
	}

	.iconfont_down {
		font-size: 12px;
		color: $textColor2;
		&.iconfont_down_up {
			transform: rotateX(-180deg);
		}
	}
}

.nottice_box {
	overflow: hidden;
	flex: 1;
	margin-right: 10px;

	.notice {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: $textColor2;
		@include font12;
		cursor: pointer;

		.iconfont {
			font-size: 16px;
			margin-right: 8px;
		}
	}
}
</style>
