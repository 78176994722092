<template>
	<div class="account_list">
		<div v-if="isLogin" class="welcome_wrap">
			<dl class="welcome_box">
				<dt v-if="!['cn', 'hk', 'tw', 'mo'].includes(website)">
					{{ localeLang("header.account.welcome").replace("xxx", userInfo ? userInfo.customers_firstname : "") }}
				</dt>
				<dt v-else>
					{{
						localeLang("header.account.welcome").replace(
							"xxx",
							userInfo ? userInfo.customers_lastname + userInfo.customers_firstname : ""
						)
					}}
				</dt>
			</dl>
			<div v-if="isCompanyAccount" class="apply_busy">
				{{
					localeLang("header.account.businessAccountFont").replace(
						"XXX",
						companyInfo && companyInfo.companyName ? companyInfo.companyName : ""
					)
				}}
			</div>
			<div v-if="isCompanyAccount" class="apply_busy add_team_font" @click="showPopupLink">
				<p class="apply_busy add_team_font">{{ localeLang("header.account.addTeamMember") }}</p>
			</div>
			<div v-else class="apply_busy">
				<p>{{ localeLang("header.account.shoppBusiness") }}</p>
				<a
					href="javascript:;"
					tabindex="0"
					class="apply_busy_link"
					@click="gaEventAccount('Business Account Entrance', 1)">
					<span>{{ localeLang("header.account.certifyBusiness") }}</span>
					<i class="icon iconfont">&#xf274;</i>
				</a>
			</div>
		</div>
		<div class="account_service_box">
			<div v-for="(item, index) in accountMenu" :key="index" class="service_item">
				<a
					v-if="item.path"
					tabindex="0"
					:href="localeLink(item.path)"
					class="service_info_box"
					@click.stop="gaEventAccount(item.eventLabel, 0)">
					<!-- <template v-if="isLogin">
						<i v-if="item.name != 'notifications'" class="iconfont" v-html="item.icon"></i>
						<img v-else class="moti_svg" src="https://resource.fs.com/mall/generalImg/20240321145423o49bw3.svg" />
					</template> -->
					<div class="service_info">{{ item.label }}</div>
				</a>
				<div v-else class="service_info_box service_info_box_noti" @click.stop="showNotificitions">
					<span class="service_info">{{ item.label }}</span>
					<span v-if="isLogin && systemMessagesUnread" class="dot"></span>
				</div>
			</div>
		</div>
		<div v-if="isLogin" class="logout-box">
			<FsButton
				type="gray"
				tabindex="0"
				:loading="loading.logout_loading"
				:style="{ width: `100%` }"
				:round="true"
				@click="logout"
				>{{ localeLang("header.account.signOut") }}</FsButton
			>
		</div>
		<div v-else class="login-box">
			<FsButton type="red" tabindex="0" :round="true" @click.stop="btnClick('login')">
				{{ localeLang("header.account.sign") }}
			</FsButton>
			<FsButton type="gray" tabindex="0" :round="true" @click.stop="btnClick('register')">{{
				localeLang("header.account.createAccount")
			}}</FsButton>
		</div>

		<FsDialog v-model="isShowLink" width="100%" :title="localeLang('header.linkuser.linkUser')" :fullScreen="true">
			<div class="new_user_popup">
				<div class="popup_container">
					<p class="tips">
						<span class="iconfont">&#xf039;</span>
						<span>{{ localeLang("header.linkuser.tips.text2") }}</span>
					</p>
					<div class="creat_form">
						<h3>{{ localeLang("header.linkuser.popupForm.userInformation") }}</h3>
						<ul v-if="website === 'cn'">
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.userName") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('first_name')" />
								<validate-error :error="errors.first_name"></validate-error> />
							</li>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.phone_number") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('phone')" />
								<validate-error :error="errors.phone"></validate-error> />
							</li>
						</ul>
						<ul v-else>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.firstName") }}</span>
								<input v-model.trim="form.first_name" type="text" maxlength="33" @blur="inputCheck('first_name')" />
								<validate-error :error="errors.first_name"></validate-error>
							</li>
							<li class="form_item">
								<span>{{ localeLang("header.linkuser.popupForm.lastName") }}</span>
								<input v-model.trim="form.last_name" type="text" maxlength="33" @blur="inputCheck('last_name')" />
								<validate-error :error="errors.last_name"></validate-error>
							</li>
						</ul>
						<div class="form_item">
							<span>{{ localeLang("ContactSales.email_business") }}</span>
							<input v-model.trim="form.email" type="text" @blur="inputCheck('email')" />
							<validate-error :error="errors.email"></validate-error>
						</div>
						<div class="form_item_team">
							<span>{{ localeLang("header.linkuser.selectTeamLink") }}</span>
							<div class="form_item">
								<FsSelect
									v-model="productModelOptionData.test"
									placeholder="Please select..."
									:options="productModelOptionData.teamList"
									type="checkbox"
									sameWidth
									@change="handleTeamChange" />
							</div>
						</div>
						<div class="radio_group">
							<div class="assign_roles">
								<b>{{ localeLang("header.linkuser.assignRoles.assign") }}</b>
								<FsTooltip placement="right" trigger="hover"
									><span class="iconfont iconfont-tip">&#xe66a;</span>
									<template #content>
										<span>{{ localeLang("header.linkuser.assignRoles.tips") }}</span>
									</template></FsTooltip
								>
							</div>
							<div v-for="i in assignRoles" :key="i.value" class="radio_item">
								<span v-if="i.disabled" class="disable_icon"></span>
								<span v-else class="iconfont" :class="{ active: form.role === i.value }" @click="selectRole(i)">{{
									form.role === i.value ? "&#xf050;" : "&#xf051;"
								}}</span>
								<div>
									<span @click="selectRole(i)">{{ i.role }}</span>
									<p class="radio_tips">{{ i.tips }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn">
					<FsButton type="black" plain @click="closePopup">{{ localeLang("header.linkuser.btn.cancel") }}</FsButton>
					<FsButton type="red" :loading="userLinkloading" native-type="submit" @click="sureLink"
						>{{ localeLang("header.linkuser.btn.submit") }}
					</FsButton>
				</div>
			</div>
		</FsDialog>
	</div>
</template>
<script setup lang="ts">
import { FsTooltip, FsButton, FsSelect, FsDialog, FsMessage } from "fs-design";
import { onMounted, computed, ref, reactive } from "vue";
import { useAccountMenu } from "./useAccountMenu";
import ValidateError from "@/component/FsFooter/components/ValidateError.vue";
import { email_valdate } from "~/constants/validate";
const headerStore = useHeaderStore();
const websiteStore = useWebsiteStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const route = useRoute();

const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const token_new = useCookies("token_new");
const cartId = useCookies("cartId");
const isShowLink = ref(false);
const userLinkloading = ref(false);

const loading = ref({
	logout_loading: false
});

const { website } = storeToRefs(websiteStore);

const { isLogin, isCompanyAccount, companyInfo, userInfo, systemMessagesUnread } = storeToRefs(userStore);
const deviceStore = useDeviceStore();
const { device } = storeToRefs(deviceStore);
const selectId = ref(0);
const productModelOptionData = ref({
	teamList: [],
	test: []
});
let sendTeamList: { team_id: any; type: any }[] = [];
const isGetTeam = ref(false);

const accountMenu = useAccountMenu();
const emit = defineEmits(["showPopup"]);

async function logout() {
	if (loading.value.logout_loading) {
		return;
	}
	loading.value.logout_loading = true;

	const { data, error } = await useRequest.post("/api/user/logout");

	loading.value.logout_loading = false;
	if (data && data.value) {
		token_new.value = null;
		cartId.value = null;
		userStore.getUserInfo();
		cartStore.getCart();
		location.href = localeLink("/");
	}
}
function showNotificitions() {
	headerStore.showMNotifications();
}
const closePopup = () => {
	isShowLink.value = false;
};

const sureLink = async () => {
	const flag = validateAll();
	if (flag) {
		return;
	}
	userLinkloading.value = true;
	let params = {};
	if (website.value === "cn") {
		const { first_name, email, role, phone } = form;
		params = {
			first_name,
			email,
			phone,
			role,
			team_ids: sendTeamList
		};
	} else {
		const { first_name, last_name, role, email } = form;
		params = {
			first_name,
			last_name,
			email,
			role,
			team_ids: sendTeamList
		};
	}

	console.log(params, "数据");
	const { data, error } = await useRequest.post("/api/companyAccount/linkUserToOrganization", { data: params });
	const res = data.value;
	if (res) {
		FsMessage({ message: localeLang("header.linkuser.successText.content"), type: "success" });
		userLinkloading.value = false;
		isShowLink.value = false;
	}
	if (error.value && error.value.data.message) {
		userLinkloading.value = false;
		FsMessage({ message: error.value.data.message, type: "danger" });
	}
};

const validateAll = () => {
	if (website.value === "cn") {
		const arr: boolean[] = [];
		const attr = ["first_name", "email", "phone"];
		attr.forEach((item: any) => {
			const f = inputCheck(item);
			arr.push(f);
		});
		return arr.includes(true);
	} else {
		const arr: boolean[] = [];
		const attr = ["first_name", "last_name", "email"];
		attr.forEach((item: any) => {
			const f = inputCheck(item);
			arr.push(f);
		});
		return arr.includes(true);
	}
};

const showPopupLink = () => {
	const message = true;
	if (device.value === "mobile") {
		isShowLink.value = true;
		if (!isGetTeam.value) {
			getTeamData();
		}
	}
	emit("showPopup", message);
};
interface FormType {
	first_name: string;
	phone: string;
	last_name: string;
	email: string;
	role: 0;
}

const form: FormType = reactive({
	first_name: "",
	phone: "",
	last_name: "",
	email: "",
	role: 0
});

const errors: FormType = reactive({
	first_name: "",
	phone: "",
	last_name: "",
	email: "",
	role: 0
});

const inputCheck = (attr: keyof FormType) => {
	let msg = "";
	const val = form[attr] && form[attr]?.replace(/\s+/g, "");
	if (attr === "first_name") {
		if (form.first_name.length < 1) {
			msg = localeLang("header.linkuser.popup.validateError.text1");
		} else if (form.first_name.length < 2) {
			msg = localeLang("header.linkuser.popup.validateError.text2");
		} else if (form.first_name.length > 32) {
			msg = localeLang("header.linkuser.popup.validateError.text3");
		} else {
			msg = "";
		}
	}
	if (attr === "phone") {
		if (!val) {
			msg = "请输入正确的电话号码";
		}
	}
	if (attr === "last_name") {
		if (form.last_name.length < 1) {
			msg = localeLang("header.linkuser.popup.validateError.text4");
		} else if (form.last_name.length < 2) {
			msg = localeLang("header.linkuser.popup.validateError.text5");
		} else if (form.last_name.length > 32) {
			msg = localeLang("header.linkuser.popup.validateError.text6");
		} else {
			msg = "";
		}
	}
	if (attr === "email") {
		if (form.email.length < 1) {
			msg = localeLang("ContactSales.service.ready.errors.email_address_error");
		} else if (!email_valdate.test(form.email)) {
			msg = localeLang("header.linkuser.popup.validateError.text8");
		} else {
			msg = "";
		}
	}
	if (attr === "role") {
		if (!val) {
			msg = localeLang("ContactSales.service.ready.errors.comments_error");
		}
	}

	errors[attr] = msg;
	return !!msg;
};

const getTeamData = async () => {
	try {
		const { data, error } = await useRequest.get("/api/companyAccount/getARoleTeams");
		const res = data.value;
		if (res) {
			if (res.code === 200) {
				console.log("", "网络请求成功");
				isGetTeam.value = true;
				// 处理成功的逻辑
				const versionData = data.value?.data.map((item: any, index: number) => {
					if (index === 0) {
						productModelOptionData.value.test = [item.id];
						sendTeamList = [{ team_id: item.id, type: item.type }];
					}
					return {
						label: item.name,
						value: item.id
					};
				});
				productModelOptionData.value.teamList = versionData;
			}
		}
	} catch (err) {
		console.log(err, "网络请求失败");
	}
};
const handleTeamChange = (value: any) => {
	const versionData = value.map((item: any, index: number) => {
		return {
			team_id: item.value,
			type: item.type
		};
	});
	sendTeamList = versionData;
	console.log(sendTeamList, "多选选中");
};
const info = ref(Object);
const isDisAbledRole = ref();

const assignRoles = computed(() => {
	const { roleCode } = info.value || {};

	const arr = [
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.admin"),
			value: 1,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.admin"),
			disabled: false
		},
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.buyer"),
			value: 2,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.buyer"),
			disabled: false
		},
		{
			role: localeLang("header.linkuser.assignRoles.policy.userList.finance"),
			value: 3,
			checked: false,
			tips: localeLang("header.linkuser.assignRoles.policy.finance"),
			disabled: false
		}
	];

	if (roleCode && roleCode === "AA" && isDisAbledRole) {
		return arr.map(i => {
			return { ...i, disabled: i.value !== 1 };
		});
	} else {
		return arr;
	}
});

const selectRole = (i: { value: any }) => {
	form.role = i.value;
};

function gaEventAccount(str: string, flag?: number) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			// eventCategory: `${this.pageGroup}_Top Login`,
			eventAction: "login_function",
			eventLabel: str,
			nonInteraction: false
		});
	}
	if (flag) {
		location.href = localeLink("/business_account.html");
	}
}

function btnClick(str: string) {
	let s = "";
	if (str === "login") {
		s = `/login.html?resirect=${route.fullPath}`;
	} else if (str === "register") {
		s = `/regist.html`;
	}
	location.href = localeLink(s);
}
onMounted(() => {});
</script>
<style lang="scss" scoped>
.account_list {
	position: relative;
	z-index: 10;
	.fs-button {
		// border-radius: 4px;
		border-radius: 9999px;
	}
}

.welcome_wrap {
	padding-bottom: 20px;
	border-bottom: 1px solid $borderColor1;
	margin-bottom: 20px;
	@include padMobile() {
		padding-bottom: 0;
		border-bottom: none;
	}
}
.welcome_box {
	word-break: break-word;

	dt {
		@include font16;
		color: $textColor1;
		font-weight: 600;
	}

	dd {
		@include font12();
		color: $textColor1;
		margin-top: 8px;
	}
}

.apply_busy {
	@include font12;
	color: $textColor3;
	margin-top: 8px;
	font-weight: 600;

	p {
		margin-bottom: 4px;
	}

	.iconfont {
		@include font12;
		margin-left: 4px;
	}

	.apply_busy_link {
		> span {
			position: relative;
			&::after {
				content: "";
				display: none;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				background-color: #0060bf;
			}
		}
		.iconfont {
			color: $textColor5;
		}

		&:hover {
			text-decoration: none;
			cursor: pointer;

			> span {
				&::after {
					display: block;
				}
			}
		}
	}
	.add_team_font {
		color: $textColor5;
		&:hover {
			text-decoration: underline;
			cursor: pointer;

			> span {
				&::after {
					display: block;
				}
			}
		}
	}
}

.apply_busy_font {
	color: $textColor1;
}

.account_service_box {
	padding-bottom: 20px;
	@include padMobile() {
		margin-bottom: 28px;
		padding-bottom: 12px;
		border-bottom: 1px solid $borderColor1;
	}
	.service_item {
		display: flex;
		align-items: center;
		padding-bottom: 16px;
		@include padMobile() {
			padding: 12px 0;
		}

		&.hide {
			display: none;
		}

		.iconfont {
			// width: 15px;
			font-size: 16px;
			margin-right: 8px;
			color: $textColor1;
			display: block;
			width: 16px;
			height: 16px;
			line-height: 16px;
		}

		.service_info_box {
			display: flex;
			cursor: pointer;
			@include font14;
			align-items: center;
			font-weight: 400;
			text-decoration: none;
			color: $textColor1;
			.moti_svg {
				width: 16px;
				height: 16px;
				display: block;
				margin-right: 8px;
			}

			.service_info {
				@include font14;
				@include padMobile() {
					@include font14;
				}
				@include padMobile() {
					text-decoration: none;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			.service_info_sub {
				color: #999;
			}

			&:hover {
				text-decoration: none;

				.service_info_sub {
					color: $textColor1;
				}
			}
		}
		.service_info_box_noti {
			@include padMobile() {
				display: flex;
				position: relative;
				.dot {
					position: absolute;
					top: 3px;
					left: 10px;
					width: 4px;
					height: 4px;
					border-radius: 8px;
					background-color: #c00000;
				}
			}
		}

		&.service_item-recently {
			padding-top: 12px;
			border-top: 1px solid #e5e5e5;
			margin-top: 8px;
		}

		&.logout-box {
			border-top: 1px solid $borderColor2;
			padding-top: 12px;
			@include font14;
			color: $textColor1;
			font-weight: 400;
		}
		&:last-child {
			padding-bottom: 0;
			@include padMobile() {
				padding: 12px 0;
			}
		}
	}
}

.login-box {
	color: red;
	:deep(.fs-button) {
		display: block;
		margin-left: 0;
		width: 100%;
		&:first-child {
			margin-bottom: 12px;
		}
	}
}

.new_user_popup {
	.tips {
		border-radius: 3px;
		padding: 10px 16px;
		background-color: rgba(0, 96, 191, 0.04);
		display: flex;
		column-gap: 8px;
		@include font12;
		color: $textColor2;
		margin-bottom: 20px;
		.iconfont {
			color: #0060bf;
			// font-weight: 600;
		}
	}
	.assign_roles {
		@include font16;
		margin-bottom: 8px;
		display: flex;
		align-items: center;
	}
	.iconfont-tip {
		font-size: 16px;
		margin-left: 4px;
		display: inline-block;
		font-weight: normal;
		width: 16px;
		height: 16px;
		line-height: 1;
		color: $textColor3;
		cursor: pointer;
		position: relative;
	}
	.creat_form {
		margin-bottom: 20px;
		> h3 {
			@include font16;
			margin-bottom: 8px;
		}

		> ul {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 20px;
			margin-bottom: 12px;
			align-items: flex-start;
		}
		.form_item {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			row-gap: 4px;
			> span {
				@include font12;
			}
		}
		.form_item_team {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			row-gap: 4px;
			margin-top: 12px;
			> span {
				@include font12;
			}
		}
		.radio_group {
			margin-top: 20px;
			.radio_item {
				display: flex;
				column-gap: 8px;
				@include font14;
				margin-bottom: 20px;
				.radio_tips {
					margin-top: 4px;
					color: $textColor3;
					@include font12;
				}
				.iconfont {
					cursor: pointer;
					font-size: 18px;
					position: relative;
					color: #ccc;
					&.disabled {
						cursor: not-allowed;
						background-color: #f7f7f7;
					}
					&.active {
						color: $textColor3;
						// @media (max-width: 768px) {
						//     color: $textColor3;
						// }
					}
				}
				.disable_icon {
					width: 18px;
					height: 18px;
					border: 1px solid #ccc;
					background-color: #f7f7f7;
					border-radius: 50%;
					cursor: not-allowed;
					margin-top: 3px;
				}
				> div {
					> span {
						cursor: pointer;
					}
				}
			}
		}
	}
	.btn {
		width: max-content;
		margin-left: auto;
		// display: flex;
		// justify-content: flex-end;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 12px;
	}
	.error_result {
		margin-bottom: 20px;
	}
}
@media (max-width: 768px) {
	.new_user_popup {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		.popup_container {
			flex: 1;
			overflow-y: scroll;
			.creat_form {
				> ul {
					grid-template-columns: repeat(1, 1fr);
					row-gap: 12px;
				}
			}
		}
		.btn {
			display: flex;
			flex-direction: column-reverse;
			row-gap: 12px;
			width: 100%;
		}
	}
}

// .logout-box {
//  padding-top: 15px;
// }
</style>
