<template>
	<div class="header_top_wrap">
		<div class="header_top_ctn">
			<div class="left_box">
				<template v-if="website === 'cn'">
					<i class="iconfont">&#xe640;&nbsp;</i>
				</template>
				<a
					v-if="fsSite && fsSite.title"
					class="site_info"
					:href="localeLink(fsSite.url)"
					target="_blank"
					tabindex="0"
					>{{ fsSite && fsSite.title ? fsSite.title : "" }}</a
				>
				<a
					class="site_info"
					tabindex="0"
					:title="notice && notice.title ? notice.title.replace(/&nbsp;/g, ' ') : ''"
					:href="notice.url ? localeLink(notice.url) : `javascript:;`"
					v-html="notice && notice.title ? notice.title : ''"></a>
			</div>
			<div class="right_box">
				<template v-if="website === 'de'">
					<div ref="thrutedBox" class="thruted_box">
						<a
							class="thruted"
							tabindex="0"
							href="https://www.trustedshops.de/bewertung/info_X6F10DAB6FE00F8D75CD228AC106DD37B.html"
							target="_blank">
							<img src="https://resource.fs.com/mall/generalImg/20221118180655o1xr3i.png" alt />
							<span>Käuferschutz</span>
						</a>
					</div>
					<span class="right_line"></span>
				</template>

				<div class="right_ctn">
					<a :href="localeLink('/contact-us.html')" @click="gaPoint">
						<span class="current_country_info current_contact_us">{{ localeLang("header.Contact_Us") }}</span>
					</a>
				</div>
				<FsTooltip
					v-if="false"
					ref="serviceTipRef"
					placement="bottom-end"
					:offset-y="8"
					:arrow-back-color="'#fafafb'"
					:popperContentStyle="{ width: '260px', padding: '0', 'border-radius': '4px' }">
					<div class="right_ctn">
						<!-- <span class="iconfont iconfont_service">&#xe631;</span> -->
						<span class="current_country_info">{{ localeLang("header.Contact_Us") }}</span>
						<!-- <span class="iconfont iconfont_down" :class="{ iconfont_down_up: serviceTipRef?.visible }">&#xf049;</span> -->
					</div>
					<template #content>
						<div class="sales_support">
							<div class="title">{{ localeLang("header.Live_Chat") }}</div>
							<div class="desc">{{ localeLang("header.Customer_Services_Time") }}</div>
							<FsButton
								id="online_chat"
								class="chat_btn"
								tabindex="0"
								type="black"
								plain
								@click="serviceClick('livechat')">
								<img src="https://resource.fs.com/mall/generalImg/20231027095258o9ujus.png" alt="live chat icon" />
								{{ localeLang("header.Chat_with_Us") }}
							</FsButton>
						</div>

						<div class="list_wrap">
							<div class="list_item">
								<span class="iconfont iconfont_contact">&#xe64c;</span>
								<div class="txt_box">
									<div>
										<a
											class="tit"
											tabindex="0"
											:href="localeLink('/live_chat_service_mail.html')"
											@click.stop="hideServiceTip"
											>{{ localeLang("header.Contact_Sales") }}</a
										>
									</div>
								</div>
							</div>
							<div class="list_item">
								<span class="iconfont iconfont_technical">&#xe64d;</span>
								<div class="txt_box">
									<div>
										<a
											class="tit"
											tabindex="0"
											:href="localeLink('/solution_support.html')"
											@click.stop="hideServiceTip">
											{{ localeLang("header.Product_Technical_Support") }}
										</a>
									</div>
								</div>
							</div>

							<div class="list_item">
								<span class="iconfont iconfont_feedback">&#xe624;</span>
								<div class="txt_box">
									<div>
										<a href="javascript:;" class="tit" tabindex="0" @click.stop="serviceClick('feedback')">{{
											localeLang("header.Feedback")
										}}</a>
									</div>
								</div>
							</div>
							<div v-if="!isRussia" class="list_item">
								<span class="iconfont iconfont-tel">&#xe645;</span>
								<div class="txt_box">
									<div>
										<span class="tit" v-html="website === 'sg' ? 'Call' : localeLang('header.Call_us_at')"> </span>
										<a tabindex="0" class="phone" :href="`tel:${phone}`">{{ phone }}</a>
									</div>
									<span class="des">{{ localeLang("header.Phone_Support") }}</span>
								</div>
							</div>
							<div v-if="isJp || isJpEn" class="list_item">
								<span class="iconfont iconfont_fax">&#xe60f;</span>
								<div class="txt_box">
									<span class="tit">{{ localeLang("header.jpFax") }}</span>
								</div>
							</div>
						</div>
					</template>
				</FsTooltip>
				<span class="right_line"></span>
				<FsTooltip
					ref="changeWebsiteTipRef"
					placement="bottom"
					:offset-y="8"
					:teleport="false"
					:popperContentStyle="{ borderRadius: '4px' }">
					<div class="right_ctn">
						<!-- <span class="iconfont iconfont_global">&#xe674;</span> -->
						{{ $refs.tooltipRef }}
						<span class="current_country_info">{{
							`${country_name} / ${symbol} ${["USD"].includes(currency) ? "" : " "}${currency}`
						}}</span>
						<span class="iconfont iconfont_down" :class="{ iconfont_down_up: changeWebsiteTipRef?.visible }"
							>&#xf049;</span
						>
					</div>
					<template #content>
						<ChangeWebsite
							:search="search"
							@saveSearchValue="saveSearchValue"
							@hideChangeWebsiteTip="hideChangeWebsiteTip" />
					</template>
				</FsTooltip>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FsTooltip, FsButton } from "fs-design";
import ChangeWebsite from "./ChangeWebsite.vue";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const localeLang = useLocaleLang();
const { website, country_name, language, currency, symbol, isJp, isJpEn, isRussia } = storeToRefs(websiteStore);

const { phone, notice, fsSite } = storeToRefs(headerStore);

const changeWebsiteTipRef = ref(null);

const serviceTipRef = ref(null);

const localeLink = useLocaleLink();

const search = ref("");
function saveSearchValue(val: string) {
	console.log("val", val);
	search.value = val;
}

function hideChangeWebsiteTip() {
	nextTick(() => {
		console.log("hidedddddd");
		console.log(changeWebsiteTipRef);
		changeWebsiteTipRef.value.visible = false;
	});
}

function hideServiceTip() {
	nextTick(() => {
		console.log("hidedddddd");
		console.log(changeWebsiteTipRef);
		serviceTipRef.value.visible = false;
	});
}

function serviceClick(type?: string) {
	hideServiceTip();
}

// const sharedValue = ref(inject("sharedValue") as number);
// const visibleChange = () => {
// 	sharedValue.value = 2;
// };

const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const gaPoint = function () {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "top_function",
			eventLabel: "Contact Us",
			nonInteraction: false
		});
	}
};

onMounted(() => {});
</script>
<style lang="scss" scoped>
.header_top_wrap {
	width: 100%;
	background-color: $bgColor1;
}

.header_top_ctn {
	@include newPcHeaderWidth;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
}

.left_box {
	display: flex;
	align-items: center;
	.iconfont {
		color: $textColor2;
	}
}

.site_info {
	color: $textColor2;
	font-size: 12px;
	cursor: pointer;
	transition: all 0.3s;
	text-decoration: none;
	// margin-right: 30px;
	&.ru-email-link {
		cursor: text;
		color: $textColor2;
		> a {
			cursor: pointer;
			transition: all 0.3s;
			color: $textColor2;
			&:hover {
				color: $textColor1;
			}
		}

		&:hover {
			color: $textColor1;
		}
	}

	&:last-child {
		margin-right: 0;
		&::after {
			display: none;
		}
	}

	&:hover {
		color: $textColor1;
	}
	&::after {
		content: " ";
		width: 1px;
		height: 10px;
		background: $borderColor2;
		display: inline-block;
		margin: 0 12px;
	}
}

.right_box {
	display: flex;
	align-items: center;
}

.thruted_box {
	.thruted {
		display: flex;
		align-items: center;
		color: $textColor2;
		cursor: pointer;

		img {
			display: block;
			width: 16px;
			height: auto;
			margin-right: 4px;
		}

		span {
			@include font12;
		}
	}
}

.right_line {
	display: inline-block;
	width: 1px;
	height: 10px;
	background: $bgColor7;
	margin: 0 12px;
}

.right_ctn {
	cursor: pointer;
	display: flex;
	align-items: center;
	color: $textColor2;

	> a {
		color: $textColor2;
		text-decoration: none;
		display: flex;
		align-items: center;
		&:hover {
			color: $textColor1;
		}
	}

	.iconfont_global,
	.iconfont_service {
		font-size: 18px;
	}

	.current_country_info {
		font-size: 12px;
		margin-right: 4px;
		white-space: nowrap;
		&.current_contact_us {
			margin: 0;
		}
	}

	.iconfont_down {
		font-size: 12px;
		line-height: 1;
		&.iconfont_down_up {
			transform: rotateX(-180deg);
		}
	}

	&:hover {
		color: $textColor1;
		transition: all 0.3s;

		.iconfont_global,
		.iconfont_service {
			color: $textColor2;
		}

		.iconfont_down {
			transform: rotateX(-180deg);
		}
	}
}

.sales_support {
	padding: 20px;
	position: relative;
	background-color: $bgColor3;
	z-index: 100;
	.title {
		@include font14;
		font-weight: 600;
	}
	.desc {
		@include font12;
		color: $textColor2;
		margin: 4px 0 16px 0;
	}
	:deep(.fs-button) {
		width: 100%;
		span {
			display: flex;
			align-items: center;
			@include font13;
		}
		img {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: 4px;
		}
	}
}

.list_wrap {
	padding: 16px 20px 20px 20px;
	.list_item {
		margin-bottom: 12px;
		display: flex;
		align-items: flex-start;
		.iconfont {
			font-size: 16px;
			margin-right: 12px;
			line-height: 20px;
			float: left;
		}
		.txt_box {
			float: left;
			width: calc(100% - 30px);
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.tit {
				@include font12;
				color: $textColor1;
				cursor: pointer;
				display: inline;
			}
			.phone {
				@include font12;
				color: $textColor1;
				cursor: pointer;
				display: inline;
			}
			.des {
				display: block;
				@include font12;
				color: $textColor2;
			}
		}
	}
}
</style>
