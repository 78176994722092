<template>
	<div class="footer_social_box">
		<section
			class="footer_box"
			:class="{ deClassL: userInfo && userInfo.isCompanyOrganizationUser && website == 'de' }">
			<FooterSubscribe />
			<FooterApp />
			<div v-if="!isCn && socialLink && socialLink.length" class="share_box">
				<a
					v-for="i in socialLink"
					:key="i.title"
					class="iconfont iconfont-share"
					target="_blank"
					tabindex="0"
					:href="localeLink(i.link[website] || i.link.en)"
					:title="i.title"
					:aria-label="i.title"
					v-html="i.icon"></a>
			</div>
			<!-- <div v-if="iso_code === 'jp'" class="jp_locale">
				<template v-if="website === 'en'">
					<div>FS JAPAN CO., LTD.</div>
					<div>JS Progress Building 5F, 4-1-23, Heiwajima, Ota Ku, Tokyo, 143-0006, Japan</div>
				</template>
				<template v-if="website === 'jp'">
					<div>FS JAPAN株式会社</div>
					<div>〒143-0006 東京都大田区平和島4-1-23 JSプログレビル 5階</div>
				</template>
			</div> -->
			<!-- <div v-if="!isRussia" class="tel_box">
				<div>
					<template v-if="iso_code !== 'jp'">
						<span
							v-show="website !== 'sg'"
							v-html="website === 'de' ? '' : localeLang('footer.Call_us_at') + (website == 'jp' ? '' : ' ')"></span>
						<span v-show="website === 'sg'"><i class="iconfont">&#xf267;</i>Call</span>
					</template>
					<template v-else>
						<span
							v-html="
								website === 'en' ? 'TEL:' : website === 'jp' ? '電話番号:' : localeLang('footer.Call_us_at')
							"></span>
					</template>
					<span class="telcode">
						<a v-if="website === 'sg'" :href="`tel:${phone}`">{{ phone || "" }}</a>
						<span
							v-else-if="website === 'uk'"
							v-html="phone ? `${phone}(UK)<br>+44 1217 161755(International)` : ''"></span>
						<span v-else>{{ phone || "" }}</span>
					</span>
				</div>
				<div v-if="isJp || isJpEn">{{ localeLang("footer.jpFax") }}</div>
			</div> -->

			<!-- <div class="jp_txt">
				<span v-if="website !== 'sg'">{{
					isJpEn ? "Monday to Friday 10:00 – 17:00(GMT+9)" : localeLang("footer.Phone_Support")
				}}</span>
				<span v-else> <i class="iconfont">&#xf055;</i>{{ localeLang("footer.Phone_Support_footer") }} </span>
			</div> -->

			<div v-if="isCn" class="about_fs_cn">
				<span> 关于飞速: </span>
				<FsTooltip placement="bottom" trigger="hover">
					<img src="https://resource.fs.com/mall/generalImg/20230221160741dsqv2k.svg" width="26" height="26" alt="" />
					<template #content>
						<img
							src="https://resource.fs.com/mall/generalImg/20240704151602um8uhe.jpeg"
							width="170"
							height="170"
							alt="" />
					</template>
				</FsTooltip>
			</div>
			<div
				v-show="device !== 'mobile'"
				id="MyCustomTrustbadge"
				:class="[
					`MyCustomTrustbadge-${website}`,
					{ myCustomTrustbadge_other: ['de', 'de-en', 'fr', 'es', 'mx', 'it'].includes(website) }
				]"></div>
		</section>
	</div>
</template>

<script setup lang="ts">
import { FsTooltip } from "fs-design";
import FooterSubscribe from "./FooterSubscribe.vue";
import FooterApp from "./FooterApp.vue";

import socialLink from "@/constants/socialLink";
const useDevice = useDeviceStore();
const userStore = useUserStore();
const websiteStore = useWebsiteStore();
const localeLink = useLocaleLink();

const { website, isCn } = storeToRefs(websiteStore);
const { device } = storeToRefs(useDevice);
const { userInfo } = storeToRefs(userStore);
</script>

<style lang="scss">
#MyCustomTrustbadge {
	position: relative;
	margin-top: 18px;
	._1gwv20v {
		._lk7o4h {
			position: relative !important;
			border: 1px solid #ccc !important;
			border-radius: 3px !important;
			padding: 11px 16px !important;
			box-sizing: border-box !important;

			._thsmae {
				width: 100% !important;
				align-items: center !important;

				._1qiwh36 {
					width: 58px !important;
					height: 58px !important;
					padding: 0 !important;
					margin: 0 !important;
					margin-right: 12px !important;

					._upwhbk {
						display: block !important;
						margin: auto !important;
						width: 58px !important;
						height: 58px !important;
					}
				}

				._argvb9 {
					flex: 1 !important;
					display: flex !important;
					flex-direction: column !important;
					justify-content: center !important;
					padding: 12px 0 0 0 !important;
					align-items: center !important;
					height: 58px !important;

					._s7xc8z {
						color: #707070 !important;
						font-size: 12px !important;
						line-height: 18px !important;
						font-display: swap !important;
						padding: 0 !important;
						margin-top: 2px !important;
					}

					._8pqgf9 {
						color: #707070 !important;
						font-display: swap !important;
						font-size: 13px !important;
						margin-top: 4px !important;
						height: 13px !important;
						line-height: 13px !important;
						margin-bottom: -2px !important;

						> span {
							display: inline-block !important;
							height: 13px !important;
							color: #707070 !important;
							font-display: swap !important;
							line-height: 13px !important;
							font-size: 13px !important;
						}
					}
				}
			}

			._zbxp0s {
				display: none !important;
			}

			._1iu1jow {
				display: none !important;
			}

			._qcra45 {
				padding: 0 !important;
				top: 6px !important;
				font-size: 13px !important;
				color: #19191a !important;
				white-space: nowrap !important;
				margin: 0 !important;
				position: absolute !important;
				text-align: center !important;
				width: calc(100% - 32px) !important;
				padding-left: 70px !important;
				left: 16px !important;
				text-align: center !important;
				font-display: swap !important;
			}
		}
	}

	&.myCustomTrustbadge_other {
		margin-top: 62px;
		._t53mel {
			height: 80px;
			._6ql45s {
				width: 142px !important;
				padding: 8px !important;
				._1748qll {
					._ihmjp9 {
						width: 44px !important;
						padding-top: 2px !important;
						img {
							width: 40px !important;
							height: 40px !important;
						}
					}
					._1koxq8s {
						padding: 0 8px 0 0 !important;
						._trzuc6 {
							font-size: 14px !important;
						}
						._1o5ol2b {
							padding-top: 0px !important;
							font-size: 12px !important;
							color: #19191a !important;
						}
						._jikro2 {
							width: 80px !important;
							height: 14px !important;
							._9m9ntk {
								height: 100% !important;
							}
							._1h8iniw {
								width: 100% !important;
								height: 100% !important;
							}
							._1qrabcm {
								width: 13px !important;
								height: 13px !important;
							}
						}
					}
				}
			}
			._1qgn7s9 {
				margin-top: 4px !important;
				font-size: 12px !important;
				color: #19191a !important;
			}
		}
	}

	&.MyCustomTrustbadge-de {
		._1gwv20v {
			._lk7o4h {
				width: 182px !important;
			}
		}
	}

	&.MyCustomTrustbadge-de-en {
		._1gwv20v {
			._lk7o4h {
				width: 204px !important;
			}
		}
	}

	&.MyCustomTrustbadge-fr {
		._1gwv20v {
			._lk7o4h {
				width: 223px !important;
			}
		}
	}

	&.MyCustomTrustbadge-es {
		._1gwv20v {
			._lk7o4h {
				width: 254px !important;
			}
		}
	}

	&.MyCustomTrustbadge-mx {
		._1gwv20v {
			._lk7o4h {
				width: 254px !important;
			}
		}
	}

	&.MyCustomTrustbadge-it {
		._1gwv20v {
			._lk7o4h {
				width: 233px !important;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.footer_social_box {
	flex-shrink: 0;
	.footer_box {
		width: 280px;
	}
	@include mobile {
		display: none;
	}
}

.share_box {
	display: flex;
	align-items: center;
	// justify-content: space-between;
	.iconfont-share {
		display: flex;
		font-size: 26px;
		color: $textColor2;
		cursor: pointer;
		width: 26px;
		height: 26px;
		align-items: center;
		justify-content: center;
		margin-right: 13px;
		transition: all 0.3s;
		&:hover {
			color: $textColor3;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.jp_locale {
	div {
		color: $textColor2;
		&:first-of-type {
			@include font13;
			font-weight: 600;
		}
		&:last-of-type {
			@include font12;
		}
	}
	margin: 16px 0px -8px;
}
.tel_box {
	margin-top: 16px;
	margin-bottom: 4px;
	@include font12;
	font-weight: 400;
	color: $textColor2;
	> div {
		margin-right: 16px;
		&:last-child {
			margin-right: 0;
		}
		> span {
			white-space: nowrap;
			.iconfont {
				font-size: 12px;
				margin-right: 8px;
			}
		}
	}
	.telcode {
		a {
			color: $textColor2;
			// text-decoration-color: #c00000;
			&:hover {
				color: $textColor1;
			}
		}
	}
}

.jp_txt {
	@include font12;
	font-weight: 400;
	color: $textColor2;
	.iconfont {
		font-size: 12px;
		margin-right: 8px;
	}
}

.about_fs_cn {
	display: flex;
	align-items: center;
	@include font12;
	font-weight: 400;
	color: $textColor2;
	> span {
		margin-right: 8px;
	}
}
</style>
