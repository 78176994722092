<template>
	<div ref="mHeaderCtn" class="m_header_ctn_box">
		<div :class="{ m_header_ctn_fixed: m_header_ctn_fixed }">
			<div class="m_header_ctn">
				<div class="m_header_left">
					<div class="menu_box" @click.stop="menuClick">
						<div class="menu" :class="{ menu_expand: m_menu_expand }">
							<div class="line line1"></div>
							<div class="line line2"></div>
							<div class="line line3"></div>
						</div>
					</div>
					<a class="logo_area" :href="localeLink('/')" aria-label="FS">
						<div :class="logoClassName"></div>
					</a>
				</div>
				<Transition name="fade">
					<div v-if="m_header_right_show" class="m_header_right">
						<!-- <div class="search_box right_box" @click="searchClick">
							<span class="iconfont iconfont_search">&#xe638;</span>
						</div> -->
						<HeaderSearchIcon v-if="device !== 'pc'" class="right_box m_header_search_tooltip" />
						<a href="javascript:;" class="cart_box right_box" @click="cartClick">
							<div class="cart-box">
								<span class="iconfont iconfont_cart">&#xe693;</span>
								<div v-if="count" class="nums" :style="`right:${count > 99 ? '4px' : '10px'}`">
									<img v-if="count > 99" src="https://resource.fs.com/mall/generalImg/20240103191113bwtnzb.svg" />
									<img v-else src="https://resource.fs.com/mall/generalImg/20240103191147vuof4c.svg" />
									<span :style="`left:${count > 9 && count <= 99 ? '-1px' : '0'}`">{{
										count > 99 ? "99+" : count
									}}</span>
								</div>
							</div>
						</a>
						<div v-if="isLogin" class="login_box right_box" @click="accountClick">
							<!-- <span class="iconfont iconfont_user">&#xe637;</span>
							<img class="tag" src="https://resource.fs.com/mall/generalImg/20240103190242ofwgoa.svg" /> -->
							<img class="account_svg" src="https://resource.fs.com/mall/generalImg/20240521161918v8h8as.svg" />
						</div>
						<a v-else class="login_box right_box" :href="localeLink(`/login.html?redirect=${route.fullPath}`)">
							<span class="iconfont iconfont_box">&#xe679;</span>
						</a>
					</div>
				</Transition>
			</div>
		</div>

		<Teleport to="body">
			<div
				class="m_mask"
				:class="{
					m_mask_show: m_menu_expand || m_header_account_show || m_header_cart_show,
					left_shadow: m_menu_expand
				}"
				:style="{
					top: `${m_header_height}px`
				}"
				@click.stop="maskClick">
				<div v-if="false" class="close">
					<!-- <img src="https://resource.fs.com/mall/generalImg/20240628161804rwo0il.svg" alt="" /> -->
					<i class="iconfont icona-delete-Mdaohang1" />
				</div>
			</div>
			<MSideBar />
			<MAccount />
			<MNotifications />
			<MHeaderCart />
		</Teleport>
	</div>
</template>
<script setup lang="ts">
import MSideBar from "./MSideBar.vue";
import HeaderSearchIcon from "./HeaderSearchIcon.vue";
import MAccount from "./MAccount.vue";
import MNotifications from "./MNotifications.vue";
import MHeaderCart from "./MHeaderCart.vue";

import fixScroll from "@/utils/fixScroll";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const userStore = useUserStore();
const route = useRoute();
const deviceStore = useDeviceStore();

const cartStore = useCartStore();

const { isCn } = storeToRefs(websiteStore);

const {
	m_menu_expand,
	m_header_height,
	m_header_right_show,
	m_header_ctn_fixed,
	m_header_account_show,
	m_header_cart_show
} = storeToRefs(headerStore);

const { isLogin } = storeToRefs(userStore);
const { count } = storeToRefs(cartStore);
const { device } = storeToRefs(deviceStore);

const mHeaderCtn = ref(null);

const localeLink = useLocaleLink();

function menuClick() {
	m_menu_expand.value ? headerStore.hideSideBar() : headerStore.showSideBar();
}

function accountClick() {
	m_header_account_show.value ? headerStore.hideMAccount() : headerStore.showMAccount();
}

function cartClick() {
	m_header_cart_show.value ? headerStore.hideMHeaderCart() : headerStore.showMHeaderCart();
}
const logoClassName = computed(() => {
	let s = "logo";
	if (isCn.value) {
		s += " logo_cn";
	}
	return s;
});
const maskClick = () => {
	m_menu_expand.value = false;
	m_header_account_show.value = false;
	m_header_cart_show.value = false;
	fixScroll.unfixed();
};
const onScroll = () => {
	if (device.value !== "pc" && mHeaderCtn.value && mHeaderCtn.value) {
		headerStore.$patch(state => {
			state.m_header_ctn_fixed = mHeaderCtn.value.getBoundingClientRect().top < 0;
		});

		useHeaderHeight();
	}
};

useEventListener("window", "scroll", onScroll);

onMounted(() => {
	onScroll();
});
</script>
<style lang="scss" scoped>
.m_header_ctn_box {
	position: relative;
	height: 48px;
	background: #fff;
	box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 10%);

	.m_header_ctn_fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 50;

		.m_header_ctn {
			box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
		}
	}
}

.m_header_ctn {
	height: 48px;
	background: #fff;
	padding: 0 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.menu_box {
	width: 48px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -12px;
	cursor: pointer;
}

.logo_area {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 12px;

	.logo {
		display: inline-block;
		width: 58px;
		height: 28px;
		@include bgContain("https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo.svg");
		transition: opacity 0.3s;
		text-decoration: none;
		&.logo_cn {
			width: 89px;
			@include bgContain("https://resource.fs.com/mall/generalImg/20230703110143tw3bnh.png");
		}
	}
}

.m_header_left {
	height: 100%;
	display: flex;
	align-items: center;

	.logo_area {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 12px;
	}
}

.menu {
	width: 24px;
	height: 24px;
	position: relative;

	.line {
		width: 24px;
		height: 2px;
		background: $textColor1;
		display: block;
		margin: 3px auto 6px;
		transition: all 0.3s ease-in-out;
		border-radius: 1px;

		&:last-child {
			margin-bottom: 3px;
		}
	}

	&.menu_expand {
		.line {
			width: 24px;

			&.line1 {
				transform: translateY(8px) rotate(45deg);
			}

			&.line2 {
				opacity: 0;
			}

			&.line3 {
				transform: translateY(-8px) rotate(-45deg);
			}
		}
	}
}

.m_header_right {
	height: 100%;
	display: flex;
	align-items: center;
	.right_box {
		width: 48px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// cursor: pointer;
		text-decoration: none;
		&.login_box {
			margin-right: -12px;
		}
		@media (max-width: 768px) {
			/* width: 40px; */
			&.login_box {
				margin-right: -12px;
			}
		}
	}
	.iconfont {
		display: block;
		font-size: 20px;
		width: 24px;
		height: 24px;
		padding: 2px;
		line-height: 20px;
		color: $textColor1;
	}
	.login_box {
		position: relative;
		.tag {
			position: absolute;
			top: 10px;
			right: 10px;
		}
		.account_svg {
			width: 20px;
			height: 20px;
			display: block;
		}
	}
	.notice-wrap {
		position: relative;
		.dot {
			position: absolute;
			top: 14px;
			left: 22px;
			width: 5px;
			height: 5px;
			border-radius: 8px;
			background-color: #c00000;
		}
	}
	/* .icon-img {
                display: block;
                width: 24px;
                height: 24px;
                position: relative;
                .tag {
                    position: absolute;
                    top: -5px;
                    right: -1px;
                }
            } */
	.cart_box {
		position: relative;
		.iconfont_cart {
			font-size: 20px;
			color: $textColor1;
		}
		.cart-num {
			position: absolute;
			line-height: 16px;
			color: #fff;
			text-align: center;
			font-size: 12px;
			border-radius: 16px;
			position: absolute;
			top: -10px;
			right: -9px;
			padding: 1px 2px;
			background: $bgColor5;
			min-width: 18px;
		}
		.nums {
			position: absolute;
			top: 10px;
			img {
				display: block;
			}
			span {
				font-size: 10px;
				line-height: 11px;
				color: #fff;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				font-weight: 600;
				transform: translateX(2%) scale(0.7);
				text-align: center;
			}
		}
	}
}
.m_mask {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	background: #000;
	z-index: -1;
	transition: all 0.3s;
	&.m_mask_show {
		z-index: 99;
		opacity: 0.3;
		&.left_shadow {
			z-index: 101;
			opacity: 1;
			background: rgba(0, 0, 0, 0.8);
			transition: unset;
			top: 0 !important;
		}
	}
	.close {
		position: absolute;
		padding: 8px;
		right: -40px;
		top: 0;
		right: 60px;
		img {
			display: block;
			width: 24px;
		}
	}
}
</style>
