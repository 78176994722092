<!-- eslint-disable prettier/prettier -->
<template>
	<FsTooltip
		ref="searchTooltipRef"
		v-loading.fullscreen="loading"
		:visible="header_search_show"
		placement="bottom-end"
		transition=""
		trigger="hover"
		manual
		:offsetY="device === 'pc' ? 14 : 4"
		:show-arrow="false"
		:popperContentStyle="{
			width: '100%',
			maxWidth: '100%',
			padding: '0',
			height: `${device === 'pc' ? 'auto' : '100%'}`
		}">
		<div class="search_icon" @click="openSearchBox">
			<span class="iconfont iconfont_search">&#xe694;</span>
		</div>
		<template #content>
			<form class="search_box" @submit.prevent="searchSubmit(4, searchData.search)">
				<div class="search_content">
					<div ref="search_result" class="search_input_box">
						<div class="search_input">
							<div class="input_box">
								<button type="submit" class="iconfont iconfont_search">&#xe694;</button>
								<FsInput
									ref="searchInputRef"
									v-model="searchData.search"
									class="search_input_text"
									:placeholder="localeLang('header.search.pcPlaceholder')"
									@input="getSearchResult" />
								<div v-show="showSearchList" class="search_result">
									<ul v-if="searchData.search || (searchData.searchList && searchData.searchList.length)">
										<li
											v-for="(v, i) in searchData.searchList"
											:key="i"
											tabindex="0"
											@keyup.enter="searchSubmit(1, v.name)"
											@click.stop="searchSubmit(1, v.name)">
											<a v-html="highLight(v.name)"></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="submit_box">
								<div v-show="searchData.search" class="close_box" @click="clearKeyword">
									<span class="iconfont iconfont_close">&#xf041;</span>
								</div>
								<FsButton v-if="device === 'pc'" native-type="submit" class="submit" type="black">{{
									localeLang("header.search.pcPlaceholder")
								}}</FsButton>
							</div>
						</div>
						<div class="search_cancel" @click.prevent.stop="closeSearchBox">
							{{ localeLang("header.search.cancel") }}
						</div>
					</div>
					<div v-show="searchData.historyList && searchData.historyList.length && !showSearchList" class="recent">
						<!-- <div class="title">{{ localeLang("header.search.recentSearch") }}</div> -->
						<div class="title">
							<p>{{ localeLang("header.search.recentSearch") }}</p>
							<p
								v-if="searchData.historyList && searchData.historyList.length > 1"
								class="change_btn"
								tabindex="0"
								@keyup.enter="clearAll"
								@click.stop="clearAll">
								<i class="iconfont iconfont_change">&#xe65f;</i>
								<span>{{ localeLang("secondCategory.clean") }}</span>
							</p>
						</div>
						<div class="list">
							<div
								v-for="(v, i) in searchData.historyList"
								:key="i"
								tabindex="0"
								:title="v"
								class="label"
								@keyup.enter="searchSubmit(2, v)"
								@click.stop="searchSubmit(2, v)">
								<div>
									<span>{{ v }}</span>
									<i
										class="iconfont icon"
										tabindex="0"
										@keyup.enter.stop="deleteSearch(i)"
										@click.stop="deleteSearch(i)"
										>&#xf041;</i
									>
								</div>
							</div>
						</div>
					</div>
					<div v-show="hotSearchList && hotSearchList.length && !showSearchList" class="hot">
						<div class="title">
							<p>{{ localeLang("header.search.hotSearch") }}</p>
							<p class="change_btn" tabindex="0" @keyup.enter="getHotSearch" @click.stop="getHotSearch">
								<i class="iconfont iconfont_change" :class="{ iconfont_change_rotate: hotChangePending }">&#xf108;</i>
								<span>{{ localeLang("header.search.change") }}</span>
							</p>
						</div>
						<div class="list">
							<div
								v-for="(v, i) in hotSearchList"
								:key="i"
								:title="v.name"
								tabindex="0"
								class="label"
								@keyup.enter="searchSubmit(3, v.name)"
								@click.stop="searchSubmit(3, v.name)">
								<div>{{ v.name }}</div>
							</div>
						</div>
					</div>
				</div>
				<span v-if="device === 'pc'" class="iconfont search_box_close" @click="closeSearchBox">&#xf041;</span>
			</form>
			<Teleport to="body">
				<transition name="fade">
					<div
						v-if="device === 'pc'"
						class="header_mask"
						:style="{ top: `${pc_header_height}px` }"
						@click.stop="closeSearchBox"></div>
				</transition>
			</Teleport>
		</template>
	</FsTooltip>
</template>
<script setup lang="ts">
import xss from "xss";
import { ref } from "vue";
import { FsTooltip, FsInput, FsButton } from "fs-design";
import fixScroll from "@/utils/fixScroll";
import type { SearchData } from "@/component/FsHeader/header.types";
const headerStore = useHeaderStore();
const { pc_header_height, header_search_show, m_header_height, hotSearchPage, hotChangePending, hotSearchList } =
	storeToRefs(headerStore);

const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const router = useRouter();
const route = useRoute();
// loading
const loading = ref(false);

// 是否开启搜索模块
// const isSearch = ref(false);
// 定义搜索框
const searchTooltipRef = ref();
const searchInputRef = ref();
const search_result = ref();
// 打开搜索框
const openSearchBox = async () => {
	header_search_show.value ? headerStore.hideSearchBox() : headerStore.showSearchBox();
	await nextTick();
	if (header_search_show.value) {
		setTimeout(() => {
			if (searchInputRef.value) {
				searchInputRef.value.ref.focus();
			}
		}, 10);
	}
};
// 关闭搜索框
const closeSearchBox = () => {
	headerStore.hideSearchBox();
};
// 定义数据
const searchData = ref<SearchData>({
	search: "",
	searchList: [],
	historyList: []
	// hotList: [],
	// hotChangePending: false,
	// hotSearchPage: 0
});
// 清除关键字
const clearKeyword = () => {
	searchData.value.search = "";
};
// 获取设备信息
const deviceStore = useDeviceStore();
const { device } = storeToRefs(deviceStore);
// 高亮
function highLight(str: string): string {
	if (!str) {
		return "";
	}
	if (searchData.value.search && searchData.value.search.length > 0) {
		if (str.includes(searchData.value.search)) {
			const replaceReg = new RegExp(searchData.value.search, "g");
			// 高亮替换v-html值
			const replaceString = '<span style="background:#E5E5E5;">' + searchData.value.search + "</span>";
			// 开始替换
			str = str.replace(replaceReg, replaceString);
		}
	}
	return str;
}
// 获取数据
function getHotSearch() {
	headerStore.getSearchHot();
}
// 点击关键词搜索
function searchSubmit(type: number, str: string) {
	/**
	 * type   1 搜索结果点击   2 最近搜索点击   3 热门推荐   4 输入框点击
	 */

	if (!str) {
		loading.value = false;
		return;
	}
	closeSearchBox();
	loading.value = true;
	if (type == 1) {
		// searchBuried("recommender", this.pageGroup, n)
	} else if (type == 2) {
		// searchBuried("history", this.pageGroup, n)
	} else if (type == 3) {
		// searchBuried("hot", this.pageGroup, n)
	} else if (type == 4) {
		// searchBuried("hot", this.pageGroup, n)
	}
	if (str === route.query.keyword) {
		router.go(0);
	}

	searchData.value.search = str;
	setSearchHistory(str);
	hideSearchResult();
	location.href = localeLink(`/search_result?keyword=${encodeURIComponent(str)}`);
}

// 下拉列表
const showSearchList = ref(false);
// 监听列表关闭
useClickOutside(search_result, showSearchList);
// 隐藏搜索下拉框
function hideSearchResult(): void {
	showSearchList.value = false;
}
// 提示词
const getSearchResult = debounce(async () => {
	if (!searchData.value.search) {
		return;
	}
	const { data, error } = await useRequest.get("/api/search/searchWords", {
		data: { keyword: xss(searchData.value.search) }
	});

	if (data && data.value) {
		searchData.value.searchList = data.value.data.info;
		showSearchList.value = data.value.data.info.length > 0;
	}
}, 300);

// 设置搜索历史记录
function setSearchHistory(str: string): void {
	if (searchData.value.historyList.length && searchData.value.historyList.includes(str)) {
		searchData.value.historyList.splice(searchData.value.historyList.indexOf(str), 1);
	}
	searchData.value.historyList.unshift(str);

	if (searchData.value.historyList.length > 7) {
		searchData.value.historyList.splice(8);
	}
	localStorage.setItem("history", JSON.stringify(searchData.value.historyList));
}

// 删除历史搜索记录
function deleteSearch(i: number): void {
	searchData.value.historyList.splice(i, 1);
	localStorage.setItem("history", JSON.stringify(searchData.value.historyList));
}
const clearAll = function () {
	searchData.value.historyList = [];
	localStorage.setItem("history", "[]");
};

const m_header_height_fotmat = ref("");
watch(
	() => m_header_height.value,
	newVal => {
		m_header_height_fotmat.value = m_header_height.value + "px";
	}
);
// = ref(m_header_height.value + "px");
onMounted(() => {
	nextTick(() => {
		searchData.value.historyList = localStorage.getItem("history") ? JSON.parse(localStorage.getItem("history")) : [];
	});
});
</script>
<style lang="scss" scoped>
.search_icon {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.iconfont_search {
		font-size: 20px;
		line-height: 20px;
		width: 20px;
		height: 20px;
		color: $textColor1;
	}
	&:hover {
		background-color: $bgColor2;
		@include padMobile {
			background-color: transparent;
		}
	}
}
.search_box {
	position: relative;
	padding: 24px 32px;
	background-color: $bgColor1;
	.search_content {
		width: 84vw;
		max-width: 1200px;
		margin: 0 auto;
		.search_input_box {
			.search_input {
				position: relative;
				.input_box {
					position: relative;
					background-color: $bgColor6;
					.iconfont_search {
						display: none;
						position: absolute;
						padding: 6px;
						border-radius: 3px;
						font-size: 16px;
						line-height: 1;
						color: $textColor2;
						z-index: 1;
						top: 7px;
					}
					:deep(.fs-input__inner) {
						&:hover {
							cursor: text;
						}
					}
					.fs-input {
						@include font14;
						color: $textColor1;
					}
					.search_result {
						width: 100%;
						border-radius: 0 0 2px 2px;
						border: 1px solid $borderColor1;
						border-top: 0;
						background-color: $bgColor6;
						box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
						border-radius: 0px 0px 4px 4px;
						@include padMobile() {
							position: relative;
							left: auto;
							top: auto;
							box-shadow: none;
							border: none;
							border-radius: 0;
						}

						> ul {
							padding: 8px 0;

							> li {
								transition: all 0.3s;

								&:hover {
									background-color: $bgColor1;
								}
								&:focus-visible {
									@include focusVisibleIn;
								}

								> a {
									@include font14;
									color: $textColor1;
									display: block;
									padding: 4px 12px;
									text-decoration: none;
									cursor: pointer;
								}
							}
							@include padMobile {
								background-color: $bgColor1;
								> li {
									padding: 8px 0;
									> a {
										@include font14;
									}
								}
							}
						}
					}
				}
				.submit_box {
					position: absolute;
					z-index: 1;
					top: 0;
					right: 0;
					display: flex;
					align-items: center;
					height: 42px;
					:deep(.fs-button) {
						@include font14;
					}
					.close_box {
						padding: 0 12px;
						background-color: $bgColor6;
						margin-right: 1px;
						.iconfont_close {
							padding: 6px;
							border-radius: 3px;
							font-size: 16px;
							line-height: 1;
							color: $textColor2;
							cursor: pointer;
							&:hover {
								color: $textColor1;
								background-color: $bgColor1;
							}
						}
					}
				}
			}
			.search_cancel {
				display: none;
				@include font14;
				color: $textColor1;
				padding: 10px 0 10px 16px;
				cursor: pointer;
			}
		}
		.recent,
		.hot {
			margin-top: 20px;
			> .title {
				@include font16;
				color: $textColor1;
				font-weight: 600;
			}
			> .list {
				.label {
					display: inline-flex;
					@include font12;
					border: 1px solid #eeeeee;
					border-radius: 999px;
					padding: 4px 11px;
					background-color: $bgColor6;
					cursor: pointer;
					margin: 8px 8px 0 0;
					> div {
						color: $textColor1;
						display: flex;
						align-items: center;
						> i {
							color: $textColor2;
							font-size: 10px;
							line-height: 1;
							margin-left: 8px;
							// padding: 4px;
							border-radius: 3px;
							&:hover {
								color: $textColor1;
							}
						}
					}
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						> div {
							color: $textColor1;
						}
						background-color: $bgColor2;
					}
				}
			}
		}
		.hot,
		.recent {
			.title {
				display: flex;
				justify-content: space-between;
				.change_btn {
					cursor: pointer;
					color: $textColor5;
					display: flex;
					align-items: center;
					@include font14;
					font-weight: 400;
					&:hover {
						span {
							text-decoration: underline;
						}
					}
				}

				.iconfont_change {
					font-weight: 400;
					color: $textColor5;
					font-size: 16px;
					display: block;
					margin-right: 4px;
				}

				.iconfont_change_rotate {
					animation: searchLoading 1s linear infinite;
				}

				@keyframes searchLoading {
					0% {
						transform: rotate(0deg);
					}

					25% {
						transform: rotate(90deg);
					}

					50% {
						transform: rotate(180deg);
					}

					75% {
						transform: rotate(270deg);
					}

					100% {
						transform: rotate(360deg);
					}
				}
			}
		}
	}
	.search_box_close {
		position: absolute;
		top: 16px;
		right: 16px;
		padding: 6px;
		border-radius: 3px;
		font-size: 16px;
		line-height: 1;
		color: $textColor2;
		cursor: pointer;
		&:hover {
			color: $textColor1;
			background: rgba(25, 25, 26, 0.04);
		}
	}
	@include padMobile {
		height: 100%;
		padding: 24px 16px;
		.search_content {
			margin: 0;
			width: 100%;
			.search_input_box {
				display: flex;
				.search_input {
					flex: 1;
					.input_box {
						.fs-input {
							:deep(.fs-input__wrapper) {
								padding-left: 44px;
							}
							:deep(.is-focus) {
								border: 1px solid $borderColor4;
							}
						}
						.iconfont_search {
							left: 8px;
							display: block;
						}
					}
				}
				.search_cancel {
					display: block;
					height: fit-content;
				}
			}
		}
	}
}
.header_mask {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	left: 0;
	right: 0;
	background: rgba(51, 51, 51, 0.3);
}
// 修改组件样式
.fs-tooltip {
	:deep(.fs-tooltip__mobile--content) {
		padding: 0 !important;
		top: 85px;
		left: 0;
		height: 100%;
		width: 100% !important;
		background-color: $bgColor1;
		transform: none;
		// z-index: -1;
		> .iconfont {
			display: none;
		}
		.fs-tooltip__mobile--context {
			margin: 0;
		}
		@include padMobile {
			top: v-bind(m_header_height_fotmat);
		}
	}
	:deep(.fs-tooltip__mobile--mask) {
		display: none;
	}
}
@include padMobile {
	.search_icon {
		width: 48px;
		height: 48px;
		.iconfont_search {
			font-size: 20px;
			line-height: 20px;
			padding: 2px;
			width: 24px;
			height: 24px;
		}
	}
}
</style>
