<template>
	<div v-show="showCookieTip" class="cookie_box">
		<div class="main-EU main">
			<p
				@click="disAgreeGoogle($event)"
				v-html="localeLang('cookieTip.txt02').replace('XXXX', localeLink('/policies/privacy_policy.html'))"></p>
			<FsButton class="accept" tabindex="0" @click="agreeCookie">{{ localeLang("cookieTip.accept") }}</FsButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { FsButton } from "fs-design";

// import { loadGTM } from "@/utils/utils.js";

const headerStore = useHeaderStore();
const websiteStore = useWebsiteStore();
const userStore = useUserStore();
const route = useRoute();

const localeLink = useLocaleLink();
const localeLang = useLocaleLang();

const { website } = storeToRefs(websiteStore);
const { showCookieTip } = storeToRefs(headerStore);

// const loadG = () => {
// 	if (["uk", "de", "de-en", "fr", "it", "es"].includes(website.value)) {
// 		loadGTM();
// 	}
// };

const disAgreeGoogle = (e: MouseEvent) => {
	if ((e.target as HTMLElement).className === "disAgreeGoogle") {
		console.log("disagree__disagree__disagree__");
		const googleAnalytics = useCookies("_ga");
		if (googleAnalytics.value) {
			const _ga = useCookies("_ga");
			const _gid = useCookies("_gid");
			const AMP_TOKEN = useCookies("AMP_TOKEN");
			const _ym_isad = useCookies("_ym_isad");
			const _ym_uid = useCookies("_ym_uid");
			const _ym_visorc_48770636 = useCookies("_ym_visorc_48770636");
			_ga.value = null;
			_gid.value = null;
			AMP_TOKEN.value = null;
			_ym_isad.value = null;
			_ym_uid.value = null;
			_ym_visorc_48770636.value = null;
		}
	}
	// if (["uk", "de", "de-en", "fr", "it", "es"].includes(website.value)) {
	if (window?.gtag) {
		window.gtag("consent", "update", {
			region: [
				"AT",
				"BE",
				"BG",
				"CY",
				"CZ",
				"DE",
				"DK",
				"EE",
				"ES",
				"FI",
				"FR",
				"GR",
				"HR",
				"HU",
				"IE",
				"IS",
				"IT",
				"LI",
				"LT",
				"LU",
				"LV",
				"MT",
				"NL",
				"NO",
				"PL",
				"PT",
				"RO",
				"SE",
				"SI",
				"SK"
			],
			ad_storage: "denied",
			ad_user_data: "denied",
			ad_personalization: "denied",
			analytics_storage: "denied"
		});
	}
	headerStore.$patch(state => {
		state.showCookieTip = false;
	});
};

const agreeCookie = () => {
	const fs_google_analytics = useCookies("fs_google_analytics", {
		maxAge: 60 * 60 * 24 * 365
	});

	const cookieconsent_dismissed = useCookies("cookieconsent_dismissed", {
		maxAge: 60 * 60 * 24 * 365
	});

	fs_google_analytics.value = "yes";
	cookieconsent_dismissed.value = "yes";

	headerStore.$patch(state => {
		state.showCookieTip = false;
	});
	if (window.gtag) {
		window.gtag("consent", "update", {
			region: [
				"AT",
				"BE",
				"BG",
				"CY",
				"CZ",
				"DE",
				"DK",
				"EE",
				"ES",
				"FI",
				"FR",
				"GR",
				"HR",
				"HU",
				"IE",
				"IS",
				"IT",
				"LI",
				"LT",
				"LU",
				"LV",
				"MT",
				"NL",
				"NO",
				"PL",
				"PT",
				"RO",
				"SE",
				"SI",
				"SK"
			],
			ad_storage: "granted",
			ad_user_data: "granted",
			ad_personalization: "granted",
			analytics_storage: "granted"
		});
	}
};

onMounted(() => {
	const cookieconsent_dismissed = useCookies("cookieconsent_dismissed", {
		maxAge: 60 * 60 * 24 * 365
	});
	headerStore.$patch(state => {
		state.showCookieTip = !cookieconsent_dismissed.value;
	});
});
</script>

<style lang="scss" scoped>
.cookie_box {
	width: 100%;
	background-color: #444444;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 99; //现在先改成99，改之前是10，如果后面发现有问题，再还原，因为设计说要将右下角的FsGlobalBtn(z-index为98)放在Cookie的下面
	padding: 16px;
	.main {
		width: 84vw;
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media (max-width: 1200px) {
			width: 94vw;
		}
		@media (max-width: 1024px) {
			width: 100%;
		}
	}

	.main-EU {
		justify-content: space-between;
		:deep(.fs-button) {
			color: #fff;
			background: #444444;
			border-radius: 21px;
			margin-left: 100px;
			@media (max-width: 1024px) {
				margin-left: 60px;
			}
		}
		p {
			max-width: 1200px;
			@include font13;
			line-height: 20px;
			color: #ffffff;
			:deep(a) {
				text-decoration: underline;
				color: #ffffff;
			}
		}
		@media (max-width: 768px) {
			flex-direction: column;
			.accept {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}
}
</style>
