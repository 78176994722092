interface TsIds {
	[key: string]: string;
}
const useTrustBadge = (website: string, isMobile: boolean): void => {
	if (process.client) {
		const tsIds: TsIds = {
			de: "X6F10DAB6FE00F8D75CD228AC106DD37B",
			"de-en": "X8892E4885B70BC8A3AE413DED35E1C1D", // special char, so in apostrophe
			fr: "X8AC866E5C5367CC98DFD5304FE336E1C",
			es: "X418137588908DE8357DF3F7837245BC3",
			mx: "X418137588908DE8357DF3F7837245BC3",
			it: "X7E6D518F64F60D05948CC01F1607EE7F"
		};
		if (website && tsIds[website]) {
			// if w (locale) props exisits and has property (not empty)
			const _tsid = tsIds[website];
			const _tsConfig: TrustBadge = {
				yOffset: "0",
				variant: isMobile ? "reviews" : "custom_reviews", // setup variant based on isMobile boolean
				customElementId: isMobile ? "" : "MyCustomTrustbadge", // as above but for custom div container
				trustcardDirection: isMobile ? "" : "topRight", // as above
				disableResponsive: "false",
				disableTrustbadge: "false"
			};
			if (!isMobile) {
				_tsConfig.customCheckoutElementId = "content-trusted-shops-checkout-element"; // add element only on desktop version
			}
			window._tsConfig = _tsConfig;
			// prevent multiple script insertions
			if (!document.querySelector(`script[src="//widgets.trustedshops.com/js/${_tsid}.js"]`)) {
				const _ts = document.createElement("script");
				_ts.type = "text/javascript";
				_ts.charset = "utf-8";
				_ts.async = true;
				_ts.src = `//widgets.trustedshops.com/js/${_tsid}.js`;
				document.head.appendChild(_ts); // Inserting script at the end of <head> for better performance
			}
		}
	}
};

export default useTrustBadge;
