<template>
	<ClientOnly>
		<div
			class="m_account_wrap"
			:class="{
				m_account_wrap_show: m_header_account_show
			}"
			:style="{
				top: `${m_header_height}px`
			}">
			<span class="iconfont iconfont_close" @click.stop="hideMAccount">&#xf041;</span>
			<AccountList />
		</div>
	</ClientOnly>
</template>
<script setup lang="ts">
import AccountList from "./AccountList.vue";
import fixScroll from "@/utils/fixScroll";
const headerStore = useHeaderStore();

const { m_header_height, m_header_account_show } = storeToRefs(headerStore);

function hideMAccount() {
	headerStore.$patch(state => {
		state.m_header_account_show = false;
		fixScroll.unfixed();
	});
}
onMounted(() => {});
</script>
<style lang="scss" scoped>
.m_account_wrap {
	position: fixed;
	right: 0;
	top: 0;
	width: 350px;
	bottom: 0;
	background: #fff;
	z-index: 100;
	overflow-y: auto;
	transform: translate3d(100%, 0, 0);
	transition: all 0.3s;
	padding: 20px 16px 0;
	box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.03);
	@include font14;

	&.m_account_wrap_show {
		transform: translate3d(0, 0, 0);
	}

	.iconfont_close {
		position: absolute;
		top: 24px;
		right: 16px;
		font-size: 16px;
		width: 16px;
		height: 16px;
		cursor: pointer;
		color: #19191a;
		text-align: center;
		line-height: 16px;
		z-index: 11;
	}
}
</style>
