<template>
	<!-- <header> -->
	<header :class="{ fix_top: isFixTop }">
		<PFsHeader />
		<MFsHeader />
	</header>
</template>

<script setup lang="ts">
import PFsHeader from "./components/PFsHeader.vue";
import MFsHeader from "./components/MFsHeader.vue";

const headerStore = useHeaderStore();
const route = useRoute();

const isFixTop = ref(false);

const initHeader = () => {
	const url = window.location.href;
	const regex = /\/products\/|\/specials\/|\/solutions\/|\/case-study\/|\/products_support.html|\/blog.html/;

	if (!regex.test(url)) {
		isFixTop.value = true;
	} else {
		const filenames = [
			"2000.html",
			"2001.html",
			"2002.html",
			"2003.html",
			"2004.html",
			"2005.html",
			"2006.html",
			"2007.html"
		];
		isFixTop.value = filenames.some(filename => url.includes(filename));
	}
};

onMounted(async () => {
	await nextTick();
	await initHeader();
	headerStore.getHoliday();
	headerStore.getSearchHot();
});
</script>
<style scoped lang="scss">
@media (min-width: 1024px) {
	.fix_top {
		position: sticky;
		top: 0;
		z-index: 110;
	}
}
</style>
