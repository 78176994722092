<template>
	<div class="m_fs_header_wrap">
		<HeaderHoliday />
		<MHeaderTop />
		<MHeaderCtn />
	</div>
</template>
<script setup lang="ts">
import HeaderHoliday from "./HeaderHoliday.vue";
import MHeaderTop from "./MHeaderTop.vue";
import MHeaderCtn from "./MHeaderCtn.vue";
const deviceStore = useDeviceStore();

const cartStore = useCartStore();

const { count } = storeToRefs(cartStore);
const { device } = storeToRefs(deviceStore);

onMounted(() => {
	if (device.value !== "pc") {
		setTimeout(() => {
			cartStore.getCart();
		}, 0);
	}
});
</script>

<style lang="scss" scoped>
.m_fs_header_wrap {
	width: 100%;
	display: none;
	position: relative;
	z-index: 100;
	@include pc() {
		display: none;
	}
	@include pad() {
		display: block;
	}
	@include mobile() {
		display: block;
	}
}
</style>
