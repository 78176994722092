<template>
	<nav class="footer_nav_box">
		<div></div>
		<div
			v-for="item in isCompanyAccount ? footerBussinessClassify : footerClassify"
			:key="item.title"
			ref="footerNavRef"
			class="footer_nav"
			:class="{ deClass: userInfo?.isCompanyOrganizationUser && website == 'de' }">
			<h3 class="title detitle">{{ item.title }}</h3>
			<div v-if="item.children && item.children.length" class="list">
				<p v-for="(c, i) in item.children" :key="i" class="list-item">
					<a
						v-if="!(isCompanyAccount && showPoInvoice && c.specialItem)"
						tabindex="0"
						:target="c.tag === 2 ? '_blank' : '_self'"
						:href="localeLink(c.url)"
						@click="gaPoint(localeLink(c.url))">
						<span class="txt fs-hover-underline" v-html="c.title"></span>
					</a>
				</p>
			</div>
		</div>

		<div class="mobile_subscribe">
			<FooterSubscribe />
		</div>
		<div class="mobile_collapse">
			<FsCollapse v-model="openedValue" accordion>
				<FsCollapseItem
					v-for="(item, index) in isCompanyAccount ? footerBussinessClassify : footerClassify"
					:key="index"
					:name="item.title"
					:title="item.title">
					<div v-if="item.children && item.children.length">
						<p v-for="(c, i) in item.children" :key="i">
							<a
								v-if="!(isCompanyAccount && showPoInvoice && c.specialItem)"
								tabindex="0"
								:target="c.tag === 2 ? '_blank' : '_self'"
								:href="localeLink(c.url)"
								@click="gaPoint(localeLink(c.url))">
								<span v-html="c.title"></span>
							</a>
						</p>
					</div>
				</FsCollapseItem>
			</FsCollapse>
		</div>
	</nav>
</template>

<script setup lang="ts">
import { FsCollapse, FsCollapseItem } from "fs-design";
import FooterSubscribe from "./FooterSubscribe.vue";

const websiteStore = useWebsiteStore();
const userStore = useUserStore();
const headerStore = useHeaderStore();
const localeLink = useLocaleLink();
const openedValue = ref(["a"]);
const { website } = storeToRefs(websiteStore);
const { isCompanyAccount, showPoInvoice, userInfo } = storeToRefs(userStore);
const { footerBussinessClassify, footerClassify } = storeToRefs(headerStore);

const footerNavRef = ref<HTMLDivElement[]>([]);
const initFooterListWidth = function () {
	const footerList = isCompanyAccount.value ? footerBussinessClassify.value : footerClassify.value;
	footerList.forEach((item, index) => {
		footerNavRef.value[index].style.width = footerNavRef.value[index].offsetWidth > 128 ? "180px" : "128px";
	});
};

const gaStore = useGaStore();
const { pageGroup } = storeToRefs(gaStore);
const gaPoint = function (url: string) {
	console.log("urlurlurl", url);
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: pageGroup.value,
			eventAction: "bottom_navigation",
			eventLabel: url,
			nonInteraction: false
		});
	}
};
onMounted(() => {
	initFooterListWidth();
});
</script>

<style lang="scss" scoped>
.footer_nav_box {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	padding-top: 82px;
	@include mobile {
		width: 100%;
		background-color: $bgColor1;
		padding-top: 0;
		flex-direction: column;
		.fs-collapse {
			display: block;
			width: 100%;
			:deep(.fs-collapse-item__header) {
				padding: 11px 0;
				@include font14;
				font-weight: normal;
				background-color: $bgColor1;
			}
			:deep(.fs-collapse-item__content) {
				background-color: $bgColor1;
				padding-bottom: 0;
			}
		}
		a {
			color: $textColor2;
			display: block;
			padding: 10px calc(3% + 15px - 16px);
			@include font13;
			cursor: pointer;
			text-decoration: none;
		}
	}
	@include pc {
		.fs-collapse {
			display: none;
		}
	}
	@include pad {
		.fs-collapse {
			display: none;
		}
	}
	@media (max-width: 768px) {
		.fs-collapse {
			display: block;
			padding: 0 16px;
		}
	}
}
.footer_nav {
	@include pc {
		display: block;
	}
	@include pad {
		display: block;
	}
	@include mobile {
		display: none;
	}
	.title {
		@include font14;
		color: $textColor1;
		margin-bottom: 24px;
		font-weight: 600;
	}

	.list {
		.list-item {
			margin-bottom: 12px;
			@include font12;
			line-height: 20px;
			> a {
				color: $textColor2;
				text-decoration: none;
			}
			.fs-hover-underline {
				padding-bottom: 3.5px;
				background-size: 100% 0;
				background-position: left bottom;
				&:hover {
					color: $textColor1;
					text-decoration: underline;
				}
			}

			.iconfont_com {
				cursor: pointer;
				display: inline-block;
				width: 13px;
				height: 13px;
				margin-left: 4px;
				// margin-top: 4px;
				font-size: 13px;
				color: $textColor2;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.mobile_subscribe {
	display: none;
	@include mobile {
		display: block;
	}
}
</style>
