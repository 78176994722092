<template>
	<div v-if="props.data && props.data.length" class="certification_ctn">
		<template v-for="(item, index) in data">
			<a
				v-if="!item.id"
				:key="index"
				tabindex="0"
				:href="item.toUrl"
				:target="item.toUrl === 'javascript:;' ? '_self' : '_blank'"
				:aria-label="item.label"
				:style="item.toUrl == 'javascript:;' ? 'cursor:auto;' : 'cursor:pointer'">
				<img :src="item.imgUrl" :alt="item.label" :aria-label="item.label" />
			</a>
			<div v-if="item.id" :key="index" :class="item.id" @click.stop="digiClick">
				<img src="https://resource.fs.com/mall/generalImg/202406011016042pcg59.png" alt="" />
			</div>
		</template>
	</div>
</template>

<script setup>
const props = defineProps({
	data: {
		type: Array,
		required: true,
		default() {
			return [];
		}
	}
});

const websiteStore = useWebsiteStore();

const digiClick = () => {
	const dom = document.querySelector("#DigiCertClickID_OsYoXDe1 img");
	if (dom) {
		dom.click();
	} else {
		window.open(
			`https://seal.digicert.com/seals/popup/?tag=OsYoXDe1&url=www.fs.com&lang=${websiteStore.communityWebsite || "en"}&cbr=${new Date().getTime()}`,
			"seal",
			"height=" + 900 + ",width=" + 900 + ",scrollbars=1"
		);
	}
};
</script>

<style scoped lang="scss">
.certification_ctn {
	display: flex;
	height: 100%;
	gap: 16px;
	align-items: center;
	@include mobile {
		justify-content: center;
	}
	> a {
		display: inline-block;
		height: 28px;
		// margin-right: 16px;
		&:last-of-type {
			margin-right: 0;
		}
		img {
			max-height: 28px;
			width: auto;
			display: block;
		}
	}
}

.DigiCertClickID_OsYoXDe1 {
	display: inline-block;
	cursor: pointer;
	// margin-right: 16px;
	img {
		margin-top: 2px !important;
		display: inline-block !important;
		height: 28px !important;
		width: auto !important;
		@include mobile() {
			display: block !important;
		}
	}
}
// :deep(.DigiCertClickID_OsYoXDe1) {
// 	display: inline-block !important;
// 	margin-right: 16px !important;
// 	img {
// 		margin-top: 4px !important;
// 		display: inline-block !important;
// 		height: 28px !important;
// 		width: auto !important;
// 	}
// }
</style>
