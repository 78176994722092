<template>
	<div v-if="isShowHoliday" class="header-holiday">
		<div class="main">
			<img src="https://img-en.fs.com/includes/templates/fiberstore/images/prompt_icon.svg" class="notice-img" />
			<p class="pc-label label">{{ holidayContent }}</p>
			<div class="m-label label">
				<NoticeMarquee>{{ holidayContent }}</NoticeMarquee>
			</div>
			<span class="iconfont" @click.stop="closeHoliday">&#xf041;</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import NoticeMarquee from "./NoticeMarquee.vue";

const headerStore = useHeaderStore();
const { isShowHoliday, holidayContent } = storeToRefs(headerStore);

const route = useRoute();
console.log("rrrrrrrrrrrrrr");
console.log(route.name);
const closeHoliday = async () => {
	headerStore.$patch(state => {
		state.isShowHoliday = false;
	});
	await nextTick();
	useHeaderHeight();
};

onMounted(async () => {
	await nextTick();
	useHeaderHeight();
});
</script>

<style lang="scss" scoped>
.header-holiday {
	background-color: #444;
	height: 46px;

	.main {
		width: 84vw;
		max-width: 1420px;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: center;
	}
	.notice-img {
		width: 18px;
		height: 18px;
	}
	.label {
		flex: 1;
		overflow: hidden;
		@include font13;
		color: #fff;
		line-height: 46px;
		margin: 0 8px;
	}
	.m-label {
		display: none;
	}
	.iconfont {
		font-size: 13px;

		color: #fff;
		cursor: pointer;
	}
	@media (max-width: 1200px) {
		.main {
			width: 94vw;
		}
	}
	@include padMobile {
		line-height: auto;
		height: auto;
		background: #444;
		.main {
			width: 100%;
			padding: 8px 16px;
		}
		.m-label {
			display: block;
		}
		.pc-label {
			display: none;
		}
		.label {
			@include font13;
		}
	}
}
</style>
