<template>
	<ClientOnly>
		<div
			class="m_header_cart_wrap"
			:class="{
				m_header_cart_wrap_show: m_header_cart_show
			}"
			:style="{
				top: `${m_header_height}px`
			}">
			<div class="cart_header">
				<div class="cart_title">
					{{ localeLang("header.cart.productsNum", { count: count }) }}
				</div>
				<span class="iconfont iconfont_close" @click.stop="hideMHeaderCart">&#xe6a0;</span>
			</div>
			<template v-if="cartList && cartList.length">
				<div class="cart_list_main">
					<div v-for="(item, index) in cartList" :key="index" class="cart_list_item">
						<img :src="item.products_info.image" />
						<div class="right">
							<div class="name" v-html="item.products_info.productName"></div>
							<div class="model">
								<span>{{ item.products_info.productsModel }}</span>
								<span>#{{ parseInt(item.products_id) }}</span>
							</div>
							<div class="price">
								<div
									v-show="
										cartData.isCorporateMembership &&
										['cn', 'hk', 'tw', 'mo'].includes(website) &&
										item.products_info.originPriceExchangeRateFormat != item.products_info.finalPriceExchangeRateFormat
									"
									class="original-price"
									v-html="
										website === 'au'
											? item.products_info.originPriceExchangeRateTaxFormat
											: item.products_info.originPriceExchangeRateFormat
									"></div>
								<div
									class="price"
									v-html="
										website != 'en'
											? item.products_info.taxPriceExchangeRateFormat
											: item.products_info.finalPriceExchangeRateFormat
									"></div>
							</div>
							<div class="edit">
								<FsQtyBox
									v-model="item.qty"
									class="qty-box"
									:max="item.fs_qty_max"
									:attr="index"
									@change="qtyChange"></FsQtyBox>
								<span class="iconfont iconfont-delete" @click.stop="showDeletePopup(index)">&#xf019;</span>
							</div>
						</div>
					</div>
				</div>

				<div class="cart_list_btn">
					<div class="sum">
						<p>{{ localeLang("header.cart.Subtotal") }}</p>
						<p
							v-if="website != 'en'"
							v-html="cartData.checkedPriceInfo && cartData.checkedPriceInfo.total.totalFormat"></p>
						<p
							v-else
							v-html="cartData.checkedPriceInfo && cartData.checkedPriceInfo.subTotalWithChecked.subTotalFormat"></p>
					</div>
					<FsButton v-if="!isRussia" type="red" @click="checkoutClick">
						{{ cartData.loginType === "punchout" ? "Punchout" : localeLang("header.cart.Secure_Checkout") }}
					</FsButton>
					<FsButton type="black" plain @click="viewCart">
						{{ localeLang("header.cart.View_Cart") }}
					</FsButton>
				</div>
			</template>
			<template v-else>
				<div class="no_cart_box">
					<div class="title">{{ localeLang("header.cart.empty") }}</div>
					<div v-if="!isLogin" class="descr isnNum">
						<span
							v-html="
								`${localeLang('header.cart.descr1').replace(
									'XXXX',
									localeLink(`/login.html?redirect=${route.fullPath}`)
								)}`
							"></span>
						<span
							v-html="
								`${localeLang('header.cart.descr2').replace(
									'YYYY',
									localeLink(`/regist.html?redirect=${route.fullPath}`)
								)}`
							"></span>
					</div>
					<div v-else-if="isLogin" class="descr isnNumisLogin">
						<span
							v-html="
								`${localeLang('header.cart.descr3')
									.replace('XXXX', localeLink('/saved-carts.html'))
									.replace('YYYY', localeLink('/'))}`
							"></span>
					</div>
				</div>
			</template>

			<FsDialog
				v-model="deleteCartPopup.visible"
				:title="localeLang('header.cart.Confirmation')"
				width="350px"
				:headerStyle="{
					padding: `12px 16px;`
				}">
				<p class="delete_info">{{ localeLang("header.cart.Delete_this_product") }}</p>
				<div class="delete_btn_box">
					<FsButton type="black" plain @click="hideDeletePopup">{{ localeLang("header.cart.Cancel") }}</FsButton>
					<FsButton type="red" :loading="deleteCartPopup.loading" @click="handleDeleteCart">{{
						localeLang("header.cart.Delete")
					}}</FsButton>
				</div>
			</FsDialog>
		</div>
	</ClientOnly>
</template>
<script setup lang="ts">
import { FsQtyBox, FsButton, FsDialog } from "fs-design";
const websiteStore = useWebsiteStore();
const headerStore = useHeaderStore();
const userStore = useUserStore();
const localeLang = useLocaleLang();
const route = useRoute();
const cartStore = useCartStore();

const { website, isRussia } = storeToRefs(websiteStore);

const { m_header_height, m_header_cart_show } = storeToRefs(headerStore);

const { isLogin } = storeToRefs(userStore);
const { count, cartList, cartData } = storeToRefs(cartStore);

const localeLink = useLocaleLink();

const deleteCartPopup = ref({
	visible: false,
	index: 0,
	loading: false
});

function hideMHeaderCart() {
	headerStore.hideMHeaderCart();
}
async function qtyChange(qty, index) {
	const arr = [];
	const o = {
		products_id: cartList.value[index].products_id,
		qty,
		isChecked: 1
	};
	arr.push(o);
	const { data } = await useRequest.post("/api/cart/update", {
		data: {
			products: arr
		}
	});
	if (data.value) {
		cartStore.setCartData(data.value.data);
	}
}
function showDeletePopup(index) {
	deleteCartPopup.value.visible = true;
	deleteCartPopup.value.index = index;
}

function hideDeletePopup() {
	deleteCartPopup.value.visible = false;
	deleteCartPopup.value.index = 0;
}

async function handleDeleteCart() {
	const i = deleteCartPopup.value.index;
	const arr = [];
	const p = {};

	const o = {
		products_id: cartList.value[i].products_id,
		qty: cartList.value[i].qty,
		isChecked: cartList.value[i].isChecked
	};
	arr.push(o);
	p.products = arr;
	p.isTempStore = 1;

	deleteCartPopup.value.loading = true;
	console.log(p);

	const { data, error } = await useRequest.post("/api/cart/delete", {
		data: p
	});
	if (data && data.value) {
		console.log(data.value);
		cartStore.setCartData(data.value.data);
		cartStore.setSubtotal(data.value.data.checkedPriceInfo.subTotalWithChecked.subTotalFormat);
		hideDeletePopup();
	}
}
function checkoutClick() {
	if (isLogin.value) {
		location.href = localeLink("/confirm-order");
	} else {
		location.href = localeLink(`/login.html?redirect=${route.fullPath}`);
	}
}

function viewCart() {
	location.href = localeLink("/shopping_cart.html");
}

onMounted(() => {});
</script>
<style lang="scss" scoped>
.m_header_cart_wrap {
	position: fixed;
	right: 0;
	top: 0;
	width: 350px;
	bottom: 0;
	background: #fff;
	z-index: 100;
	overflow-y: auto;
	transform: translate3d(100%, 0, 0);
	transition: all 0.3s;
	box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.03);
	@include font14;
	display: flex;
	flex-direction: column;

	&.m_header_cart_wrap_show {
		transform: translate3d(0, 0, 0);
	}
}

.cart_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 0px;
	margin: 0 0 0 16px;
	flex-shrink: 0;
	.cart_title {
		display: flex;
		align-items: center;
		padding: 20px 0px;
		@include font16;
		color: $textColor1;
		font-weight: 600;
	}
	.iconfont_close {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 52px;
		height: 48px;
		padding: 14px 16px;
		font-size: 20px;
		font-weight: 400;
		color: $textColor1;
		transition: all 0.3s;
		cursor: pointer;
	}
}
.cart_list_main {
	flex: 1 1 auto;
	overflow-y: auto;
	.cart_list_item {
		padding: 20px 16px;
		// border-bottom: 1px solid #e5e5e5;
		display: flex;
		position: relative;
		&:not(:last-child) {
			// border-bottom: none;
			&:after {
				content: "";
				height: 1px;
				position: absolute;
				background-color: #e5e5e5;
				bottom: 0;
				left: 16px;
				right: 16px;
			}
		}
		> img {
			width: 60px;
			height: 60px;
			margin-right: 20px;
		}
		.right {
			flex: 1;
			.name {
				@include font14;
				color: $textColor1;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			.model {
				margin-top: 8px;
				span {
					@include font13;
					color: $textColor3;
					&:last-child {
						margin-left: 12px;
					}
				}
			}
			.price {
				margin-top: 12px;
				@include font16;
				font-weight: 600;
				color: $textColor1;
			}
			.edit {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 12px;
				// ::v-deep .qty-box {
				//     height: 32px;
				//     width: 96px;
				//     > span {
				//         width: 32px;
				//     }
				// }
				.iconfont-delete {
					font-size: 16px;
					line-height: 1;
					font-weight: 400;
					color: $textColor1;
					transition: all 0.3s;
					cursor: pointer;
					&:hover {
						color: $textColor1;
					}
				}
			}
		}
	}
}
.cart_list_btn {
	display: flex;
	flex-direction: column;
	padding: 20px 16px;
	background: #fff;
	width: 100%;
	flex-shrink: 0;
	border-top: 1px solid #e5e5e5;
	.sum {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		p {
			@include font16;
			font-weight: 600;
			color: $textColor1;
		}
	}
	:deep(.fs-button) {
		display: block;
		margin-bottom: 12px;
		margin-left: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.delete_info {
	@include font14;
	margin-bottom: 40px;
}
.delete_btn_box {
	display: flex;
	justify-content: flex-end;
	:deep(.fs-button) {
		margin-left: 12px;
		min-width: 120px;
	}
}
.no_cart_box {
	padding: 0px 16px;
	.title {
		color: $textColor1;
		@include font20;
		font-weight: 600;
		margin-bottom: 12px;
	}
	.descr {
		@include font14;
		display: flex;
		flex-direction: column;
	}
	:deep(.isNum) {
		color: $textColor2;
		a {
			color: #0060bf;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	:deep(.isnNum) {
		display: block;
		> span {
			display: block;
			&:first-child {
				margin-bottom: 2px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		color: $textColor1;
		a {
			color: #0060bf;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	:deep(.isnNumisLogin) {
		a {
			color: #0060bf;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
</style>
