export function useAccountMenu() {
	const userStore = useUserStore();
	const websiteStore = useWebsiteStore();

	const { isCn, website } = storeToRefs(websiteStore);

	const { isLogin, isCompanyAccount, userInfo } = storeToRefs(userStore);

	const localeLang = useLocaleLang();
	const accountType = isCompanyAccount.value ? "business_account" : "person_account";

	const menuData = ref();
	const data = {
		overview: {
			name: "overview",
			path: "/my-account",
			eventLabel: localeLang("header.account." + accountType + ".Account_Center"),
			label: localeLang("header.account." + accountType + ".Account_Center"),
			icon: "&#xe637;"
		},
		information: {
			name: "information",
			label: localeLang("header.account." + accountType + ".Orders"),
			path: "/order-history",
			eventLabel: localeLang("header.account." + accountType + ".Orders"),
			icon: "&#xe634;"
		},
		invoices: {
			name: "invoices",
			label: localeLang("header.account." + accountType + ".Invoice"),
			path: "/invoices.html",
			eventLabel: localeLang("header.account." + accountType + ".Invoice"),
			icon: "&#xe629;"
		},
		savedCarts: {
			name: "savedCarts",
			label: localeLang("header.account." + accountType + ".Saved_Carts"),
			path: "/saved-carts.html",
			eventLabel: localeLang("header.account." + accountType + ".Saved_Carts"),
			icon: "&#xe636;"
		},
		quotes: {
			name: "quotes",
			path: "/quote_history.html",
			label: localeLang("header.account." + accountType + ".Quotes"),
			eventLabel: localeLang("header.account." + accountType + ".Quotes"),
			icon: "&#xe635;"
		},
		notifications: {
			name: "notifications",
			path: "/message-center",
			label: localeLang("header.notifications.title"),
			eventLabel: localeLang("header.notifications.title"),
			icon: "&#xe62e;"
		}
	};

	const invoices = isCompanyAccount.value && !(userInfo.value && userInfo.value.showPoInvoice) ? null : data.invoices;
	const quotes = isCn.value ? null : data.quotes;
	const notifications = data.notifications;
	const result = [data.overview, data.information, invoices, data.savedCarts, quotes, notifications];
	// 过滤为空的值
	menuData.value = result.filter(item => item);
	console.log("11");
	console.log(menuData.value);
	return menuData;
}
