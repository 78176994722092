<template>
	<nav class="header_ctn_wrap">
		<div class="header_ctn">
			<HeaderMenuList />
			<!-- <HeaderSearch /> -->
			<div class="header_ctn_right">
				<!-- <HeaderNotifications /> -->
				<HeaderSearchIcon v-if="device === 'pc'" />
				<HeaderCart />
				<HeaderAccount />
			</div>
		</div>
	</nav>
</template>
<script setup lang="ts">
import HeaderMenuList from "./HeaderMenuList.vue";
import HeaderNotifications from "./HeaderNotifications.vue";
import HeaderSearchIcon from "./HeaderSearchIcon.vue";
import HeaderAccount from "./HeaderAccount.vue";
import HeaderCart from "./HeaderCart.vue";

const deviceStore = useDeviceStore();
const { device } = storeToRefs(deviceStore);
</script>
<style lang="scss" scoped>
.header_ctn_wrap {
	width: 100%;
	background-color: #ffffff;
	position: relative;
	// box-shadow: inset 0 0 0 1px $bgColor5;
}
.header_ctn {
	@include newPcHeaderWidth;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.header_ctn_right {
	// display: flex;
	// align-items: center;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 12px;
	height: 68px;
	position: relative;
	:deep(.icon_info) {
		@media (max-width: 1200px) {
			display: none;
		}
	}
}
</style>
