<template>
	<div class="cart_list">
		<div v-if="count && !cartRequest && cartFetching" class="cart_loading_box">
			<div v-loading:30="cartFetching" class="cart_loading"></div>
			<p class="cart_loading_info">{{ localeLang("header.cart.cartLoading") }}</p>
		</div>
		<div v-if="count && cartRequest" class="cart-product-box">
			<div class="product-head">{{ localeLang("header.cart.productsNum", { count: count }) }}</div>
			<div class="product-list">
				<div v-for="(item, index) in cartList" :key="index" class="product-item">
					<img class="product-img" :src="item.products_info.image" />
					<div class="product-content">
						<div
							class="product-title"
							:title="item.products_info.productName"
							v-html="item.products_info.productName"></div>
						<p class="product-num">
							{{ `${localeLang("header.cart.qty")}${item.qty}` }}
						</p>
					</div>
					<span
						class="product-price"
						v-html="
							website != 'en'
								? item.products_info.taxPriceExchangeRateFormat
								: item.products_info.finalPriceExchangeRateFormat
						"></span>
				</div>
			</div>

			<div class="checkout-box">
				<div class="checkout-box-text">
					<span class="checkout-box-left">{{ localeLang("header.cart.Subtotal") }}</span>
					<span
						class="checkout-box-right"
						v-html="
							website != 'en'
								? cartData.checkedPriceInfo.total.totalFormat
								: cartData.totalPriceInfo.subTotal.subTotalFormat
						"></span>
				</div>
				<div class="checkout_box_btn">
					<FsButton class="cart_btn1 toCheck" tabindex="0" type="red" round @click="btnClick('checkout')">{{
						localeLang("header.cart.Secure_Checkout")
					}}</FsButton>

					<FsButton type="gray" tabindex="0" round @click="btnClick('cart')">{{
						localeLang("header.cart.viewCart")
					}}</FsButton>
				</div>
			</div>
		</div>
		<div v-if="!count" class="cart-empty">
			<div class="cart-empty-title">{{ localeLang("header.cart.headerEmpty") }}</div>
			<div class="cart-empty-cont">
				<div class="cart-empty-text">
					<span class="cart-empty-left">{{ localeLang("header.cart.Subtotal") }}</span>
					<span
						class="cart-empty-right"
						v-html="cartData.totalPriceInfo && cartData.totalPriceInfo.subTotal.subTotalFormat"></span>
				</div>
				<div class="checkout_box_btn">
					<FsButton class="cart_btn1" type="red" tabindex="0" :disabled="true" round>{{
						localeLang("header.cart.Secure_Checkout")
					}}</FsButton>

					<FsButton type="gray" tabindex="0" round @click="btnClick('cart')">{{
						localeLang("header.cart.viewCart")
					}}</FsButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FsButton } from "fs-design";
const cartStore = useCartStore();
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const websiteStore = useWebsiteStore();
const { website } = storeToRefs(websiteStore);
const { count, cartList, cartData, cartRequest, cartFetching } = storeToRefs(cartStore);
console.log(cartData, "123123123");

function btnClick(str: string): void {
	let l = "";
	if (str === "checkout") {
		l = "/confirm-order";
	} else if (str === "cart") {
		l = "/shopping_cart.html";
	}

	location.href = localeLink(l);
}
</script>
<style lang="scss" scoped>
.cart_list {
	position: relative;
	z-index: 10;
}
.cart_loading_box {
	padding: 20px 0;
	.cart_loading {
		position: relative;
		height: 40px;
	}
	.cart_loading_info {
		@include font14;
		color: $textColor2;
		text-align: center;
	}
}

.cart-product-box {
	.product-head {
		margin: 0 20px;
		padding: 16px 0;
		border-bottom: 1px solid $borderColor1;
		@include font14;
		color: $textColor1;
	}

	.product-list {
		max-height: 234px;
		overflow: auto;

		.product-item {
			display: flex;
			overflow: hidden;
			position: relative;
			border-bottom: 1px solid $borderColor1;
			padding: 16px 0;
			justify-content: space-between;
			margin: 0 20px;

			.product-img {
				display: inline-block;
				width: 48px;
				height: 48px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
				flex-shrink: 0;
			}

			.product-content {
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
				margin: 0 8px;
				max-width: 116px;

				.product-title {
					font-size: 13px;
					margin-bottom: 4px;
					color: $textColor1;
					line-height: 20px;
					transition: all 0.3s;
					display: -webkit-box;
					overflow: hidden;
					white-space: normal;
					text-overflow: ellipsis;
					word-wrap: break-word;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					text-align: left;
				}

				.product-num {
					color: $textColor1;
					font-size: 13px;
					line-height: 20px;
				}
			}

			.product-price {
				text-align: right;
				white-space: nowrap;
				min-width: 80px;
				@include font13;
				color: $textColor1;
				flex-shrink: 0;
			}
		}
	}

	.checkout-box {
		padding: 22px 20px 20px 20px;

		.checkout-box-text {
			display: flex;
			margin-bottom: 24px;
			justify-content: space-between;

			span {
				font-size: 16px;
				line-height: 24px;
				color: $textColor1;
				font-weight: 600;
			}
		}
	}
}
.checkout_box_btn {
	display: flex;
	flex-direction: column;
	:deep(.fs-button) {
		display: block;
		width: 100%;
		margin-left: 0;
	}
	.fs-button {
		// border-radius: 4px;
		border-radius: 9999px;

		&::before {
			// border-radius: 4px;
			border-radius: 9999px;
		}
	}

	.cart_btn1 {
		margin-bottom: 12px;
		// border-radius: 4px;
		border-radius: 9999px;
	}
}

.cart-empty {
	padding: 0 20px;

	.cart-empty-title {
		padding: 16px 0;
		@include font12;
		color: $textColor1;
		border-bottom: 1px solid $borderColor1;
	}

	.cart-empty-cont {
		padding: 20px 0;

		.cart-empty-text {
			display: flex;
			margin-bottom: 20px;
			justify-content: space-between;

			span {
				@include font16;
				color: $textColor1;
				font-weight: 600;
			}
		}
	}
}
</style>
